import gql from 'graphql-tag'

const assignObject = gql`
  mutation(
  $assign_user_id: [Int!]
  $assign_team_id: [Int!]
  $job_id: Float
  $task_id: Float
  ) {
    assignObject(
      assign_user_id: $assign_user_id
      assign_team_id: $assign_team_id
      job_id: $job_id
      task_id: $task_id
    ) {
      status
      msg
      data
    }
  }
`

export default assignObject
