import gql from 'graphql-tag'

const getUserTransactionList = gql`
  query getUserTransactionList(
    $pagination: pagination!
    $filter: filterUserTransactionList!
  ) {
    getUserTransactionList(
      pagination: $pagination
      filter: $filter
    ) {
      count
      transactions {
        id
        midtrans_order_id
        created_at
        updated_at
        status {
          id
          name
        }
        payment_type {
          id
          name
        }
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
        gross_amount
        token
      }
    }
  }
`

export default getUserTransactionList
