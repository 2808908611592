import gql from 'graphql-tag'

const hierarchyTreeFolder = gql`
query hierarchyTreeFolder(
  $workspace_id: Float!
){
  hierarchyTreeFolder(
    workspace_id: $workspace_id
  ){
    ...recursiveData
  }
}
fragment userDetail on UserHierarchyFolder {
  id
  email
  name
  photo
  disabled
  permission
  node_id
}

fragment HierarchyDetail on HierarchiesFolder {
  id
  name
  parent_id
  description
  node_id
  disabled
}

fragment recursiveData on HierarchiesFolderChild{
  ... on HierarchiesFolder {
    ...HierarchyDetail
    children {
      ... on HierarchiesFolder{
        ...HierarchyDetail
        children {
          ... on HierarchiesFolder{
            ...HierarchyDetail
            children {
              ... on HierarchiesFolder{
                ...HierarchyDetail
                children {
                  ... on HierarchiesFolder{
                    ...HierarchyDetail
                    children {
                      ... on HierarchiesFolder{
                        ...HierarchyDetail
                        children {
                          ... on HierarchiesFolder{
                            ...HierarchyDetail
                            children {
                              ... on HierarchiesFolder{
                                ...HierarchyDetail
                                children {
                                  ... on HierarchiesFolder{
                                    ...HierarchyDetail
                                    children {
                                    ... on HierarchiesFolder{
                                        ...HierarchyDetail
                                        children {
                                          ... on HierarchiesFolder{
                                            ...HierarchyDetail

                                          }
                                          ... on UserHierarchyFolder{...userDetail }
                                        }
                                      }
                                      ... on UserHierarchyFolder{...userDetail }
                                    }
                                  }
                                  ... on UserHierarchyFolder{...userDetail }
                                }
                              }
                              ... on UserHierarchyFolder{...userDetail }
                            }
                          }
                          ... on UserHierarchyFolder{...userDetail }
                        }
                      }
                      ... on UserHierarchyFolder{...userDetail }
                    }
                  }
                  ... on UserHierarchyFolder{...userDetail }
                }
              }
              ... on UserHierarchyFolder{...userDetail }
            }
          }
          ... on UserHierarchyFolder{...userDetail }
        }
      }
      ... on UserHierarchyFolder { ...userDetail }
    }
  }
  ... on UserHierarchyFolder { ...userDetail }
}
`
export default hierarchyTreeFolder
