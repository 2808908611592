import gql from 'graphql-tag'

const sendForgotPasswordCode = gql`
  mutation sendForgotPasswordCode(
    $email: String!
  ){
    sendForgotPasswordCode(
      email: $email
    ){
      msg
      status
      data
    }
  }
`
export default sendForgotPasswordCode
