<template>
  <v-card-text class="d-flex flex-column h-full">
    <v-data-iterator
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
      :items="plans"
    >
      <template v-slot:default="props">
        <v-row
          dense
          no-gutters
          align="end"
        >
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            lg="6"
          >
            <v-card
              outlined
              tile
              class="ma-1 pa-1 rounded-lg"
            >
              <div class="pa-3">
                <div class="d-flex justify-space-between">
                  <h3 class="mb-3 text-h5 font-weight-bold">
                    {{ item.name }}
                  </h3>
                  <v-tooltip
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-if="profileData.renew_subscription_type === item.id"
                        :color="profileData.is_auto_subscribe ? 'primary': 'secondary'"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ profileData.is_auto_subscribe ? 'Subskripsi Aktif' : 'Subskripsi tidak aktif' }}
                      </v-chip>
                    </template>
                    <span>
                      {{ profileData.is_auto_subscribe ? 'Saat Plan anda jatuh tempo, plan akan otomatis diubah / diperpanjang ke plan ini.' : 'Saat Plan anda jatuh tempo, plan tidak akan diperpanjang.' }}
                    </span>
                  </v-tooltip>
                </div>
                <template v-if="item.price_month > 0">
                  <h3 class="font-weight-thin mb-3">
                    {{ formatCurrency(item.price_month) }} / Bulan
                  </h3>
                  <h3 class="font-weight-thin mb-5">
                    {{ formatCurrency(item.price_year) }} / Tahun
                    <v-chip
                      color="primary"
                      small
                      class="ms-2"
                    >
                      Gratis 1 Bulan
                    </v-chip>
                  </h3>
                </template>
                <template v-else>
                  <h3 class="font-weight-thin mb-5">
                    Gratis
                  </h3>
                  <div class="mb-14" />
                </template>
                <div class="text-center mb-3">
                  <v-btn
                    v-if="profileData.subscription.limit_workspace > item.limit_workspace"
                    class="w-100"
                    outlined
                    :disabled="!profileData.is_auto_subscribe"
                    @click="downgradePlan(item)"
                  >
                    Turunkan Plan
                  </v-btn>
                  <v-btn
                    v-else-if="profileData.subscription.name === item.name"
                    class="w-100"
                    disabled
                  >
                    Plan saat ini
                  </v-btn>
                  <v-btn
                    v-else
                    color="primary"
                    class="w-100"
                    @click="upgradePlan(item)"
                  >
                    Tingkatkan Plan
                  </v-btn>
                </div>
              </div>

              <v-divider />

              <v-list
                dense
                class="py-0"
              >
                <v-list-item>
                  <v-list-item-content>
                    Jumlah maksimal member workspace: {{ item.limit_member ? item.limit_member : 'Unlimited' }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    Jumlah maksimal Workspace: {{ item.limit_workspace > 10000 ? 'Unlimited' : item.limit_workspace }}
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="my-2" />
                <v-list-item>
                  <v-list-item-content>
                    Kuota pemakaian Automasi (per bulan): {{ item.max_automation_usage ? item.max_automation_usage : 'Unlimited' }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <plan-interval-form ref="planIntervalForm" />
    <plan-upgrade-form ref="planUpgradeForm" />
    <plan-downgrade-form ref="planDowngradeForm" />
  </v-card-text>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'
// import { useStorage } from '@vueuse/core'
// import { getVuetify } from '@/@core/utils'
import { subscriptionTypeList, userUsage } from '@/graphql/queries'
import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'
import { formatCurrency } from '@core/utils/filter'
import PlanIntervalForm from './PlanIntervalForm.vue'
import PlanUpgradeForm from './PlanUpgradeForm.vue'
import PlanDowngradeForm from './PlanDowngradeForm.vue'

export default {
  components: {
    PlanIntervalForm,
    PlanUpgradeForm,
    PlanDowngradeForm,
  },
  setup() {
    const plans = ref([])
    const usage = ref({
      total_member: null,
      total_workspace: null,
    })
    const profileData = ref({
      first_name: '',
      last_name: '',
      email: '',
      subscription: null,
      renew_subscription_type: null,
    })

    const planIntervalForm = ref(null)
    const planUpgradeForm = ref(null)
    const planDowngradeForm = ref(null)

    const fetchSubscriptionTypeList = () => {
      apolloClient.query({
        query: subscriptionTypeList,
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        plans.value = data.SubscriptionTypeList
      }).catch(err => {
        errorHandling(err)
      })
    }

    const fetchUserUsage = () => {
      apolloClient.query({
        query: userUsage,
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        // eslint-disable-next-line camelcase
        const { total_member, total_workspace } = data.userUsage

        usage.value = {
          total_member,
          total_workspace,
        }
      }).catch(err => {
        errorHandling(err)
      })
    }
    console.log(store.getters.getUserData)
    const getUserData = () => {
      /* eslint-disable camelcase */
      const {
        first_name, last_name, email, subscription, renew_subscription_type, is_auto_subscribe,
      } = store.getters.getUserData

      profileData.value = {
        first_name,
        last_name,
        email,
        subscription,
        renew_subscription_type,
        is_auto_subscribe,
      }
    }

    const upgradePlan = plan => {
      if (store.state.auth.userData.subscription.id === 1) {
        planIntervalForm.value.show(plan)
      } else {
        planUpgradeForm.value.show(plan)
      }
    }

    const downgradePlan = plan => {
      planDowngradeForm.value.show(plan)
    }

    onMounted(() => {
      fetchSubscriptionTypeList()
      fetchUserUsage()
      getUserData()
    })

    return {
      plans,
      profileData,
      usage,
      formatCurrency,
      planIntervalForm,
      planUpgradeForm,
      upgradePlan,
      planDowngradeForm,
      downgradePlan,
      getUserData,
    }
  },
}
</script>
