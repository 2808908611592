import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import ability from '@/plugins/acl/ability'
import errorHandling from '@/utils/errorHandling'

Vue.use(VueRouter)

/**
 * @typedef { import('vue-router').RouteRecord
 * & import('./definition').IMetaRoute
 * & import('vue-router').RouteConfig } IRoutes
 * @type {IRoutes[]}
 */
const routes = [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/initial/Login.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        next()
      }
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/initial/Register.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        next()
      }
    },
  },
  {
    path: '/need-verify',
    name: 'auth-need-verify',
    component: () => import('@/views/initial/NeedVerify.vue'),
    meta: {
      layout: 'blank',
      user: true,
    },
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        next()
      }
    },
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('@/views/initial/VerifyAccount.vue'),
    meta: {
      layout: 'blank',
      user: true,
    },
    props: route => ({ query: route.query }),
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        console.log('verify')
        next()
      }
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/initial/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      user: true,
    },
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        next()
      }
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/initial/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      user: true,
    },
    props: route => ({ query: route.query }),
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.auth
      if (isAuthenticated) {
        next(from)
      } else {
        next()
      }
    },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/error/Unauthorized.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/initial/Welcome.vue'),
    meta: {
      layout: 'blank',
      user: true,
    },
  },
  {
    path: '/404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error/InternalError.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/:workspace',
    name: 'workspace-root',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      action: 'read',
      resource: 'All',
      user: true,
    },
  },
  {
    path: '/:workspace/customers',
    name: 'customers',
    component: () => import('@/views/customer/Customer.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Pelanggan',
      user: true,
    },
  },
  {
    path: '/:workspace/customers/detail/:id',
    name: 'customer-detail',
    component: () => import('@/views/customer/CustomerDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Pelanggan',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/products',
    name: 'products',
    component: () => import('@/views/product/Product.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Produk',
      user: true,
    },
  },
  {
    path: '/:workspace/products/detail/:id',
    name: 'product-detail',
    component: () => import('@/views/product/ProductDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Produk',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/chats',
    name: 'chats',
    component: () => import('@/views/chat/Chat.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Chat',
      user: true,
    },
  },
  {
    path: '/:workspace/chats/:id',
    name: 'chat-detail',
    component: () => import('@/views/chat/Chat.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Chat',
      navActiveLink: 'chats',
      user: true,
    },
  },
  {
    path: '/:workspace/prospects',
    name: 'prospects',
    component: () => import('@/views/prospect/Prospect.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Prospek',
      user: true,
    },
  },
  {
    path: '/:workspace/prospects/detail/:id',
    name: 'prospect-detail',
    component: () => import('@/views/prospect/ProspectDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Prospek',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/folders/detail/:id',
    name: 'folder-detail',
    component: () => import('@/views/folder/FolderDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Folder',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/jobtype/detail/:id',
    name: 'jobtype-detail',
    component: () => import('@/views/folder/FolderDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Job Type',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/jobs',
    name: 'jobs',
    component: () => import('@/views/job/Job.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Job',
      user: true,
    },
  },
  {
    path: '/:workspace/jobs/detail/:id',
    name: 'job-detail',
    component: () => import('@/views/job/JobDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Job',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/reports',
    name: 'workspace-workflow-reports',
    component: () => import('@/views/workflow/reports/WorkspaceWorkflowReport.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Laporan',
      user: true,
    },
  },
  {
    path: '/:workspace/activities',
    name: 'activities',
    component: () => import('@/views/activity/Activity.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Aktivitas',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/activities/detail/:id',
    name: 'activity-detail',
    component: () => import('@/views/activity/Activity.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Aktivitas',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/task',
    name: 'task',
    component: () => import('@/views/activity/task/Task.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Task',
      user: true,
    },
  },
  {
    path: '/:workspace/users',
    name: 'users',
    component: () => import('@/views/user/User.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Pengguna',
      user: true,
    },
  },
  {
    path: '/:workspace/users/detail/:id',
    name: 'user-detail',
    component: () => import('@/views/user/UserDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Pengguna',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/teams',
    name: 'teams',
    component: () => import('@/views/team/Team.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Tim',
      user: true,
    },
  },
  {
    path: '/:workspace/teams/:id',
    name: 'team-detail',
    component: () => import('@/views/team/TeamDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Tim',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/teams/:id/channel/:channelid',
    name: 'channel',
    component: () => import('@/views/team/TeamDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Detail Tim',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/integrations',
    name: 'integrations',
    component: () => import('@/views/integration/Integrations.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Integrasi',
      user: true,
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/:workspace/marketings',
    name: 'marketings',
    component: () => import('@/views/ComingSoon.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Marketing',
      user: true,
    },
  },
  {
    path: '/:workspace/workflows',
    name: 'workflows',
    component: () => import('@/views/workflow/Workflow.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Workflow',
      user: true,
    },
  },
  {
    path: '/:workspace/workflows/detail/:id',
    name: 'workflow-detail',
    // component: () => import('@/views/workflow/WorkflowDetail.vue'),
    component: () => import('@/views/workflow/WorkflowDetail.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Workflow Detail',
      user: true,
    },
    props: true,
  },
  {
    path: '/:workspace/hierarchy',
    name: 'hierarchy',
    component: () => import('@/views/hierarchy/Hierarchy.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Hierarki',
      user: true,
    },
  },
  {
    path: '/:workspace/hierarchy/:id',
    name: 'hierarchy-detail',
    component: () => import('@/views/hierarchy/Hierarchy.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Hierarki',
      navActiveLink: 'hierarchy',
      user: true,
    },
  },
  {
    path: '/:workspace/guide',
    name: 'guide',
    component: () => import('@/views/workflow/Guide.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Cara Penggunaan Workflow',
      user: true,
    },
  },
  // {
  //   path: '/:workspace/callback/trello',
  //   name: 'Authorize Trello',
  //   component: () => import('@/views/integration/trello/component/TrelloLoading.vue'),
  //   meta: {
  //     action: 'read',
  //     resource: 'All',
  //     pageTitle: 'Authorize Trello',
  //     layout: 'blank',
  //   },
  //   props: route => ({ query: route.query }),
  // },

  {
    path: '/invite/:id',
    name: 'invite-url',
    meta: {
      layout: 'blank',
      public: true,
    },
    component: () => import('@/views/initial/Invitation.vue'),
    props: true,
  },
  // khusus admin
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    meta: {
      pageTitle: 'Dashboard',
      layout: 'admin',
      admin: true,
    },
    component: () => import('@/views/admin/DashboardAdmin.vue'),
    props: true,
  },
  {
    path: '/admin/user',
    name: 'admin-user',
    meta: {
      pageTitle: 'Manajemen User',
      layout: 'admin',
      admin: true,
    },
    component: () => import('@/views/admin/UserAdmin.vue'),
    props: true,
  },
  {
    path: '/admin/workflow',
    name: 'admin-workflow',
    meta: {
      pageTitle: 'Workflow',
      layout: 'admin',
      admin: true,
    },
    component: () => import('@/views/admin/WorkflowAdmin.vue'),
    props: true,
  },
  {
    path: '/admin/tokopedia',
    name: 'admin-tokopedia',
    meta: {
      pageTitle: 'Integrasi Tokopedia',
      layout: 'admin',
      admin: true,
    },
    component: () => import('@/views/admin/TokopediaAdmin.vue'),
    props: true,
  },
  {
    path: '/admin/user/:id',
    name: 'admin-user-detail',
    meta: {
      pageTitle: 'Detail User',
      layout: 'admin',
      admin: true,
    },
    component: () => import('@/views/admin/UserDetailAdmin.vue'),
    props: true,
  },
  {
    path: '/admin/workflow/:id',
    name: 'admin-workflow-detail',
    meta: {
      pageTitle: 'Detail Workflow',
      layout: 'admin',
      admin: true,
    },
    component: () => import('@/views/admin/WorkflowDetailAdmin.vue'),
    props: true,
  },
  {
    path: '/admin/transaction',
    name: 'admin-transaction',
    meta: {
      pageTitle: 'Daftar Transaksi',
      layout: 'admin',
      admin: true,
    },
    component: () => import('@/views/admin/TransactionAdmin.vue'),
    props: true,
  },
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      errorHandling('Halaman Tidak Ditemukan')
      next('/')
    },
  },

  // for test purpose
  {
    path: '/test/components',
    name: 'components',
    component: () => import('@/views/test/Components.vue'),
    meta: {
      action: 'read',
      resource: 'All',
      pageTitle: 'Components Test',
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'breadcrumbs_link_2',
        },
      ],
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  console.log(to)
  console.log(store.state.auth)
  // set page title
  window.document.title = to.meta && to.meta.pageTitle
    ? `${to.meta.pageTitle} | OriensCRM - Next-gen CRM Solution`
    : 'OriensCRM - Next-gen CRM Solution'

  const hasToken = localStorage.getItem('token')
  console.log(hasToken)

  // check if user is not authenticated but got token
  // using store.state.auth.isAuthenticated so data is always directly from state
  if (!store.state.auth.isAuthenticated && hasToken) {
    await store.dispatch('checkExpiredToken')
    await store.dispatch('getProfileInfo')
  }

  // get workspace list from state
  const workspaces = store.state.workspace.workspaceList

  if (to.path === '/register') return next()
  if (to.path === '/need-verify') return next()
  if (to.path === '/verify') return next()
  if (to.path === '/forgot-password') return next()
  if (to.path === '/reset-password') return next()

  // if user is not authenticated and got no token
  // + workaround if on login page, then use the last next return
  if (!store.state.auth.isAuthenticated && !hasToken && to.path !== '/login') {
    localStorage.setItem('savedUrl', to.path)

    return next('/login')
  }

  // if accessing root path then start checking if the user has workspace
  // and if the user has selected workspace before
  if (to.path === '/') {
    console.log(store.state.auth.userData.is_admin)
    if (store.state.auth.userData.is_admin) return next('/admin/dashboard')
    if (!workspaces || !workspaces.length) return next('/welcome')

    const lastSelectedWorkspace = JSON.parse(localStorage.getItem('selectedWorkspace'))

    if (!lastSelectedWorkspace) {
      store.dispatch('setSelectedWorkspace', workspaces[0])

      return next(`/${workspaces[0].workspace.identifier_id}`)
    }

    return next(`/${lastSelectedWorkspace.workspace.identifier_id}`)
  }

  if ((to.meta.user && store.state.auth.userData.is_admin) || (to.meta.admin && !store.state.auth.userData.is_admin)) {
    console.log(to)
    console.log(store.state.auth.userData.is_admin)

    return next('/')
  }

  // reserved routes so it doesnt handled as workspace route
  const reservedRoutes = [
    '404', 'error', 'login', 'unauthorized', 'welcome', 'invite', 'test',
    'register', 'forgot-password', 'reset-password',
  ]
  // hanya jika user bukan admin
  console.log(store.state.auth)
  if (!store.state.auth.userData?.is_admin) {
    const rootPath = to.path.split('/')[1]

    if (!reservedRoutes.some(el => el === rootPath)) {
      if (!workspaces || !workspaces.length) return next('/welcome')

      // check if workspace is exist
      const foundWorkspace = workspaces.find(el => el.workspace.identifier_id === rootPath)

      if (!foundWorkspace) {
        Vue.notify({
          title: 'Terjadi Kesalahan',
          text: 'Workspace tidak tersedia',
          type: 'warn',
        })

        // error handling if no workspace is available
        if (rootPath === 'workspace') {
          return window.location.replace('/')
        }

        // no need to set selected workspace state
        // because it's gonna revisiting this middleware again
        return next(`/${workspaces[0].workspace.identifier_id}`)
      }

      store.dispatch('setSelectedWorkspace', foundWorkspace)
    }
  }
  // get workspace slug as rootPath

  // check if the page require permission
  // console.log(store.state.auth.userData?.is_admin)
  if (store.state.auth.isAuthenticated && to.meta && to.meta.resource && to.meta.action && !store.state.auth.userData?.is_admin) {
    // console.log('tes')
    await store.dispatch('permissionsParser')
    const canNavigate = to.matched.some(route => ability.can(route.meta.action, route.meta.resource))

    if (!canNavigate) return next('/unauthorized')
  }

  return next()
})

export default router
