import gql from 'graphql-tag'

const confirmDeleteStatus = gql`
  mutation confirmDeleteStatus (
    $new_id: [Float!]
    $default_id: Float
    $delete_id: [Float!]!
    $workspace_id: Float!
  ) {
    confirmDeleteStatus (
      new_id: $new_id
      default_id: $default_id
      delete_id: $delete_id
      workspace_id: $workspace_id
    ) {
    status
    msg
    data
  }
}
`

export default confirmDeleteStatus
