import gql from 'graphql-tag'

const sharedJobTypes = gql`
  query sharedJobTypes(
    $workspace_id: Float!
    $filter: ParamJobType!
  ) {
    sharedJobTypes(
      workspace_id: $workspace_id
      filter: $filter
    ) {
      id
      name
      is_public
    }
  }
`

export default sharedJobTypes
