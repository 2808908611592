import gql from 'graphql-tag'

const updateProspectStatus = gql`
mutation updateProspectStatus($status_id: Float!, $id: Float!,$workspace_id:Float!) {
  updateProspectStatus(status_id: $status_id, id: $id, workspace_id:$workspace_id) {
    status
    msg
    data
  }
}
`
export default updateProspectStatus
