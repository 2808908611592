import gql from 'graphql-tag'

const verifyAccount = gql`
  mutation verifyAccount(
    $verification_code: String!
    $password: String!
    $confirm_password: String!
  ) {
    verifyAccount(
      verification_code: $verification_code
      password: $password
      confirm_password: $confirm_password
    ) {
      msg
      status
      data
    }
  }
`

export default verifyAccount
