import gql from 'graphql-tag'

const objectPropertiesV2 = gql`
  query objectPropertiesV2{
    objectPropertiesV2{
      id
      name
      identifier
      object{
        id
      }
    }
  }
`

export default objectPropertiesV2
