import gql from 'graphql-tag'

const tokopediaGetProductBySKU = gql`
  query tokopediaGetProductBySKU(
    $workspace_id: Float!
    $sku: String!
    $connection_name: String!
  ) {
    tokopediaGetProductBySKU(
      workspace_id: $workspace_id
      sku: $sku
      connection_name: $connection_name
    ) {
      status
      msg
      data
    }
  }
`

export default tokopediaGetProductBySKU
