import gql from 'graphql-tag'

const createWorkflow = gql`
  mutation createWorkflow(
    $trigger: CreateAutomationParamsTriggerV2!
    $filters: [CreateAutomationParamsFilterV2!]!
    $executors: [CreateAutomationParamsExecutorV2!]!
  ) {
    createWorkflow(
      trigger: $trigger
      filters: $filters
      executors: $executors
    ) {
      status
      msg
    }
  }
`

export default createWorkflow
