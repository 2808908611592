import gql from 'graphql-tag'

const getCalendarEventList = gql`
  query getEventList(
    $refresh_token: String!
    $calendar_id: String!
    $args: ParamSearchEvent!
  ) {
    getEventList(
      refresh_token: $refresh_token
      calendar_id: $calendar_id
      args: $args
    ) {
      accessRole
      description
      etag
      items {
        colorId
        created
        description
        start {
          dateTime
          timeZone
        }
        end {
          dateTime
          timeZone
        }
        etag
        htmlLink
        iCalUID
        id
        recurrence
        status
        summary
        updated
        visibility
      }
      nextPageToken
      summary
    }
  }
`

export default getCalendarEventList
