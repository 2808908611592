import gql from 'graphql-tag'

const updateTaskDone = gql`
mutation updateTaskDone($id: Float!, $workspace_id: Float!) {
  updateTaskDone(id: $id, workspace_id: $workspace_id) {
    status
    msg
    data
  }
}

`
export default updateTaskDone
