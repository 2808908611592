import gql from 'graphql-tag'

const deleteProspect = gql`
mutation deleteProspect($id: Float!, $workspace_id:Float!) {
  deleteProspect(id: $id, workspace_id: $workspace_id) {
    status
    msg
    data
  }
}
`

export default deleteProspect
