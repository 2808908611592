import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workspace d-flex align-center justify-center"},[_c('XyzTransition',{attrs:{"appear":"","xyz":"fade left-3 delay-3"}},[(_vm.userData)?_c(VCard,{staticClass:"pa-8 d-flex flex-column",class:{ 'mx-2' : !_vm.fromDashboard },attrs:{"min-height":"50vh","max-width":_vm.fromDashboard ? '800' : null,"min-width":_vm.fromDashboard ? null : '70vw'}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('XyzTransition',{attrs:{"appear":"","xyz":"fade left-3 delay-3"}},[_c(VImg,{staticClass:"app-logo mt-2",attrs:{"src":require('@/assets/images/svg/oriens-text.svg'),"max-width":"130px","alt":"logo","contain":"","eager":""}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('XyzTransition',{attrs:{"appear":"","xyz":"fade left-3 delay-5"}},[(!_vm.fromDashboard)?_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v("Halo, "+_vm._s(_vm.userData.first_name)+"!")]),_c('h4',[_vm._v("Selamat datang di Oriens CRM 👋")]),_c('span',{staticClass:"d-block mt-2 text-subtitle-2"},[_vm._v("Yuk buat workspace pertama mu 🚀")])]):_c('div',[_c('h4',[_vm._v("Buat Workspace Baru")])])]),_c('XyzTransition',{attrs:{"appear":"","xyz":"fade left-3 delay-7"}},[_c('div',[_c(VForm,{ref:"workspaceForm",attrs:{"lazy-validation":""}},[_c(VTextField,{staticClass:"mt-8",attrs:{"rules":[_vm.required],"label":"Nama workspace (wajib diisi)","placeholder":"Input nama workspace","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{staticClass:"mt-4",attrs:{"rules":[
                    _vm.required,
                    _vm.alphaDashValidator,
                    _vm.maxLength(_vm.id, 20) ],"label":"Workspace ID (wajib diisi)","placeholder":"Input workspace ID","outlined":"","dense":"","hide-details":"auto","error":_vm.isIDError && !_vm.isIDApproved,"success":_vm.isIDApproved && !_vm.isIDError,"error-messages":_vm.isIDError && !_vm.isIDApproved ? 'ID workspace tidak tersedia' : null},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VMenu,{attrs:{"open-on-hover":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"text--disabled"},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiHelpCircleOutline)+" ")])]}}],null,false,2726860390)},[_c(VCard,{staticClass:"pa-3 d-flex flex-column",attrs:{"max-width":"300"}},[_c('span',{staticClass:"text-caption font-medium mb-2"},[_vm._v("Workspace ID")]),_c('span',{staticClass:"text-caption"},[_vm._v("Workspace ID digunakan untuk mengatur alamat URL unik dari workspace mu.")])])],1)]},proxy:true}],null,false,413368845),model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1),_c('span',{staticClass:"d-block text-caption mt-4"},[_vm._v("Icon Workspace")]),_c(VCard,{staticClass:"mt-2 d-flex align-center justify-center",attrs:{"width":"80","height":"80","outlined":""},on:{"click":function($event){return _vm.$refs.workspaceIconPicker.click()}}},[(_vm.iconWorkspace)?_c(VImg,{staticClass:"rounded",attrs:{"src":_vm.iconWorkspacePreview,"height":"80","width":"80"}}):_c(VIcon,{staticClass:"text--disabled",attrs:{"size":"32"}},[_vm._v(" "+_vm._s(_vm.icons.mdiImage)+" ")])],1),_c('span',{staticClass:"text-caption text--disabled mt-1"},[_vm._v(" Ukuran maksimum 2mb. "),(!_vm.isValidIconSize)?_c('span',{staticClass:"error--text"},[_vm._v("Ukuran melebihi batas!")]):_vm._e()]),_c('input',{ref:"workspaceIconPicker",staticClass:"d-none",attrs:{"type":"file","accept":".png, .jpg, .jpeg"},on:{"change":function($event){return _vm.onFileChange($event.target.files)}}})],1)])],1)],1),_c(VRow,[(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('Lottie',{staticClass:"welcome-lottie",attrs:{"options":{
              animationData: require('@/assets/lottie/breath.json'),
            }}})],1):_vm._e(),_c(VCol,{staticClass:"d-flex align-end justify-end",attrs:{"cols":"12","md":"8"}},[_c('XyzTransition',{staticClass:"w-full",attrs:{"appear":"","xyz":"fade bottom-5 delay-10"}},[_c('div',{staticClass:"d-flex flex-column"},[(!_vm.fromDashboard)?_c('span',{staticClass:"text-caption d-block mt-4"},[_vm._v(" Terlogin sebagai "+_vm._s(_vm.userData.first_name)+" "+_vm._s(_vm.userData.last_name)+". "),_c('span',{staticClass:"primary--text cursor-pointer",on:{"click":function($event){return _vm.$store.dispatch('logout')}}},[_vm._v("Logout?")])]):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-md-center flex-md-row"},[_c('span',{staticClass:"text-caption primary--text cursor-pointer",on:{"click":function($event){_vm.isModelHelpOpen = true}}},[_vm._v("Apa itu workspace?")]),_c(VBtn,{staticClass:"mt-4 ml-md-auto",attrs:{"color":"primary","disabled":!_vm.name && !_vm.id,"loading":_vm.loadingSubmit},on:{"click":_vm.submit}},[_vm._v(" Buat Workspace "),_c(VIcon,{staticClass:"ml-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiArrowRight)+" ")])],1)],1)])])],1)],1)],1):_vm._e()],1),_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.isModelHelpOpen),callback:function ($$v) {_vm.isModelHelpOpen=$$v},expression:"isModelHelpOpen"}},[_c(VCard,{staticClass:"pa-4"},[_c('h3',[_vm._v("Apa itu Workspace?")]),_c('span',{staticClass:"d-block mt-4"},[_vm._v(" Workspace adalah Sebuah Tempat dimana anda bisa mengundang teman anda untuk berbagi token akun yang anda miliki, sehingga semua anggota workspace bisa menggunakan token tersebut untuk membuat sebuah workflow automasi. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }