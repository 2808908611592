import gql from 'graphql-tag'

const queryDeleteCustomAttribute = gql`
  query queryDeleteCustomAttribute(
    $delete_id: [Float!]!
    $object_type_id: Float!
  ) {
    queryDeleteCustomAttribute(
      delete_id: $delete_id
      object_type_id: $object_type_id
    ) {
      count
      id
      name
    }
  }
`

export default queryDeleteCustomAttribute
