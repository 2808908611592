import gql from 'graphql-tag'

const dashboardJobs = gql`
  query dashboardJobs(
    $pagination: pagination!,
  ) {
    dashboardJobs(
      pagination: $pagination,
    ) {
      new {
        count
        jobs {
          id
          name
          priority {
            id
            name
          }
          description
          expected_close_date
          created_by{
            id
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo{
            id
            user{
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
          tasks {
            id
            subject
          }
        }
      }
      today {
        count
        jobs {
          id
          name
          priority {
            id
            name
          }
          description
          expected_close_date
          created_by{
            id
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo{
            id
            user{
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
          tasks {
            id
            subject
          }
        }
      }
      tomorrow {
        count
        jobs {
          id
          name
          priority {
            id
            name
          }
          description
          expected_close_date
          created_by{
            id
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo{
            id
            user{
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
          tasks {
            id
            subject
          }
        }
      }
      late {
        count
        jobs {
          id
          name
          priority {
            id
            name
          }
          description
          expected_close_date
          created_by{
            id
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo{
            id
            user{
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
          tasks {
            id
            subject
          }
        }
      }
      unscheduled {
        count
        jobs {
          id
          name
          priority {
            id
            name
          }
          description
          expected_close_date
          created_by{
            id
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo{
            id
            user{
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
          tasks {
            id
            subject
          }
        }
      }
    }
  }
`

export default dashboardJobs
