<template>
  <v-autocomplete
    v-model="inputData"
    :items="colors"
    dense
    placeholder="Pilih warna"
    :label="label"
    item-text="label"
    item-value="hex"
    hide-details
    :outlined="outlined"
    :rules="rules"
    :attach="attach"
  >
    <template #item="{ item }">
      <div class="d-flex">
        <div
          class="pa-3 mr-2 rounded"
          :style="`background-color: ${item.hex}`"
        />
        <span>
          {{ item.label }}
        </span>
      </div>
    </template>
    <template #selection="{ item }">
      <div class="d-flex align-center">
        <div
          class="pa-2 mr-2 rounded-sm"
          :style="`background-color: ${item.hex}`"
        />
        <span>
          {{ item.label }}
        </span>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { computed } from '@vue/composition-api'
import { colors } from '@/utils/colorSelection'

export default {
  props: {
    attach: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Warna',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    return {
      inputData,
      colors,
    }
  },
}
</script>

<style>

</style>
