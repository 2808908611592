import gql from 'graphql-tag'

const updateProspect = gql`
mutation updateProspect(
    $name: String!,
    $description: String,
    $prospect_product: [prospectProduct!],
    $order_id: Float
    $expired_at: DateTime
    $customer: Float!
    $visibility: Float!
    $visibility_5_users: [Int!]
    $prospect_status: Float!
    $id: Float!,
    $workspace_id:Float!
    ) {
        updateProspect(
            name: $name,
            description: $description,
            prospect_product: $prospect_product,
            order_id: $order_id,
            expired_at: $expired_at
            customer: $customer,
            visibility: $visibility,
            visibility_5_users: $visibility_5_users,
            prospect_status: $prospect_status
            id: $id,
            workspace_id:$workspace_id
        ) {
    status
    msg
    data
  }
}
`
export default updateProspect
