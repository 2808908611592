import gql from 'graphql-tag'

const workflowsV2 = gql`
  query workflowList(
    $workspace_id: Float!,
    $pagination: pagination!
  ) {
    workflowList(
      workspace_id: $workspace_id,
      pagination: $pagination
    ) {
      triggers {
        id
        name
        is_disabled
        created_at
        created_by {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
      }
      count
    }
  }
`

export default workflowsV2
