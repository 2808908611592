import gql from 'graphql-tag'

const updateAvailabilityUser = gql`
  mutation updateAvailabilityUser (
    $availability: Boolean!
  ) {
    UpdateAvailabilityUser(
      availability: $availability
    ) {
      status
      msg
      data
    }
  }
`

export default updateAvailabilityUser
