<template>
  <v-dialog
    v-model="isShown"
    max-width="300"
    :persistent="persistent"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ body }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          class="text-capitalize"
          color="grey darken-1"
          text
          @click="cancel()"
        >
          {{ cancelText }}
        </v-btn>

        <v-btn
          class="text-capitalize"
          color="primary"
          text
          @click="accept()"
        >
          {{ acceptText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

/**
 * Komponen global dialog biar bisa disummon di mana aja
 * referensi: https://www.debug.my.id/2019/07/membuat-promise-based-dialog.html
 */
export default {
  data() {
    return {
      isShown: false,
      title: '',
      body: '',
      cancelText: 'Batalkan',
      acceptText: 'Yakin',
      persistent: false,
    }
  },
  mounted() {
    Vue.$dialog = this.open
  },
  methods: {
    open({
      title, body, cancelText, acceptText, persistent,
    }) {
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject

        this.isShown = true
        this.title = title || this.title
        this.body = body || this.body
        this.cancelText = cancelText || this.cancelText
        this.acceptText = acceptText || this.acceptText
        this.persistent = persistent || false
      })
    },
    cancel() {
      this.isShown = false
      this.resolve(false)
    },
    accept() {
      this.isShown = false
      this.resolve(true)
    },
  },
}
</script>

<style>

</style>
