import gql from 'graphql-tag'

const getUserWorkflowList = gql`
  query getUserWorkflowList(
    $user_id: Float
    $pagination: pagination!
    $filter: filterWorkflowList
  ) {
    getUserWorkflowList(
      user_id: $user_id
      pagination: $pagination
      filter: $filter
    ) {
      count
      workflows {
        id
        name
        created_at
        is_disabled
        workspace {
          id
          name
        }
      }
    }
  }
`

export default getUserWorkflowList
