import gql from 'graphql-tag'

const editJobTypePermission = gql`
  mutation updateJobTypePermission(
    $workspace_id: Float!,
    $jobtype_id: Float!,
    $is_public: Boolean!,
    $user_permissions: [UserFolderPermission!]!,
  ) {
    updateJobTypePermission(
      workspace_id: $workspace_id,
      jobtype_id: $jobtype_id,
      is_public: $is_public,
      user_permissions: $user_permissions,
    ) {
      status
      msg
      data
    }
  }
`

export default editJobTypePermission
