<template>
  <v-autocomplete
    v-model="inputData"
    :items="items"
    :label="label"
    outlined
    item-value="id"
    item-text="name"
    dense
    :attach="attach"
    :return-object="returnObject"
    hide-details="auto"
    :multiple="multiple"
    :rules="inputRules"
    :filter="filter"
    :clearable="clearable"
  >
    <template #no-data>
      <div
        class="px-4 py-2 text-subtitle-2 text--disabled text-center"
      >
        <p class="mb-0">
          Pengguna tidak ditemukan.
        </p>
      </div>
    </template>
    <template #item="data">
      <v-list-item-avatar>
        <v-avatar color="primary">
          <v-img
            v-if="data.item.photo"
            :src="data.item.photo"
          />
          <span v-else>{{ avatarText(data.item.name) }}</span>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ data.item.email }}</v-list-item-subtitle>
        <v-list-item-subtitle>{{ data.item.phone_number }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template
      v-if="pill"
      #selection="data"
    >
      <v-chip
        small
        class="mt-1"
        close
        @click:close="remove(data.item.id)"
      >
        {{ data.item.name }}
      </v-chip>
    </template>
    <template #append-outer>
      <slot name="match" />
    </template>
  </v-autocomplete>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { minArrayLength, maxArrayLength } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import { teams } from '@/graphql/queries'

export default {
  props: {
    value: {
      type: [Array, Object, Number, String],
      default: null,
    },
    label: {
      type: String,
      default: 'Pilih pengguna',
    },
    attach: {
      type: Boolean,
      default: true,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    max: {
      type: [Number, String],
      default: null,
    },
    min: {
      type: [Number, String],
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    showTeams: {
      type: Boolean,
      default: false,
    },
    teamOnly: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Function,
      default: undefined,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    pill: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        return emit('input', val)
      },
    })
    const users = computed(() => store.state.user.users.map(el => ({
      ...el,
      name: `${el.first_name} ${el.last_name}`,
    })))
    if (!users.length) {
      store.dispatch('user/getUsers')
    }

    const teamList = ref([])
    if (props.showTeams) {
      apolloClient.query({
        query: teams,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          pagination: {
            limit: 1000000,
            offset: 0,
          },
        },
      }).then(result => {
        teamList.value = result.data.teams
      })
    }

    const items = computed(() => {
      if (props.showTeams && props.teamOnly) return teamList.value

      if (props.showTeams) {
        return [
          { header: 'Pengguna' },
          ...users.value.map(el => ({
            ...el,
            id: `user-${el.id}`,
            team: false,
          })),
          { divider: true },
          { header: 'Tim' },
          ...teamList.value.map(el => ({
            ...el,
            id: `team-${el.id}`,
            team: true,
          })),
        ]
      }

      return users.value
    })

    const remove = id => {
      inputData.value = inputData.value.filter(data => data.id !== id)
    }

    const inputRules = computed(() => {
      if (props.multiple && (props.min || props.max)) {
        const multipleRules = []

        if (props.max) multipleRules.push(maxArrayLength(inputData.value, props.max))
        if (props.min) multipleRules.push(minArrayLength(inputData.value, props.min))

        return [...props.rules, ...multipleRules]
      }

      return props.rules
    })

    return {
      inputData,
      users,
      teamList,
      items,
      avatarText,
      inputRules,
      minArrayLength,
      maxArrayLength,
      remove,
    }
  },
}
</script>

<style>

</style>
