import gql from 'graphql-tag'

const getTransactions = gql`
  query transactions(
    $pagination: pagination!
  ) {
    transactions(
      pagination: $pagination
    ) {
      count
      transactions {
        id
        midtrans_order_id
        created_at
        updated_at
        status {
          id
          name
        }
        payment_type {
          id
          name
        }
        user {
          id
          phone
          email
          name
          first_name
          last_name
        }
        gross_amount
        token
      }
    }
  }
`

export default getTransactions
