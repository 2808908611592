import gql from 'graphql-tag'

const getJobTypeDetail = gql`
  query jobTypeDetail(
    $workspace_id: Float!,
    $jobtype_id: Float!
  ){
    jobTypeDetail(
      workspace_id: $workspace_id,
      jobtype_id: $jobtype_id
    ) {
      id
      name
      color
      created_at
      archived_at
      owner {
        id
        name
      }
      folder_permissions {
        permission_type
        user {
          id
          name
          photo
        }
      }
      statuses {
        id
        name
        color
        urutan
        type
      }
      priorities {
        id
        name
        color
        urutan
      }
      custom_attributes {
        id
        name
        order
        options {
          id
          name
        }
        data_type {
          id
          name
          operator {
            id
            name
            value
          }
        }
        object_type {
          id
          name
        }
      }
    }
  }
`

export default getJobTypeDetail
