import gql from 'graphql-tag'

const getWorkflowAdminDashboardUsage = gql`
  query getWorkflowAdminDashboardUsage(
    $num_months: Float!
  ) {
    getWorkflowAdminDashboardUsage(
      num_months: $num_months
    ) {
      labels
      data
    }
  }
`

export default getWorkflowAdminDashboardUsage
