import gql from 'graphql-tag'

const updateJob = gql`
  mutation updateJob (
    $expected_close_date: DateTime
    $start_date: DateTime
    $description: String
    $name: String!
    $job_priority_id: Float
    $job_status_id: Float!
    $customer_id: Float
    $job_product: [jobProduct!]
    $id: Float!
    $workspace_id: Float!
    $custom_attribute: [customAttributeValues!]!
  ) {
    updateJob (
      expected_close_date: $expected_close_date
      start_date: $start_date
      description: $description
      name: $name
      job_priority_id: $job_priority_id
      job_status_id: $job_status_id
      customer_id: $customer_id
      job_product: $job_product
      id: $id
      workspace_id: $workspace_id
      custom_attribute: $custom_attribute
    ) {
      status
      msg
      data
    }
  }
`

export default updateJob
