import gql from 'graphql-tag'

const getAllUsers = gql`
  query getAllUsers(
    $search: String!
    $filter: filterAllUser
    $pagination: pagination!
    ) {
      getAllUsers(
        search: $search
        filter: $filter
        pagination: $pagination
      ) {
        count
        users {
          id
          phone
          email
          name
          photo
          user_status {
            id
            name
          }
          notify_task_assignment
          notify_comment
          notify_job_assignment
          availability
          last_seen_at
          created_at
          updated_at
        }
      }
  }
`

export default getAllUsers
