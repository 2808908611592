import gql from 'graphql-tag'

const uploadProduct = gql`
  query uploadProduct(
    $file: Upload!
    $workspace_id: Float!
  ) {
    uploadProduct(
      file: $file
      workspace_id: $workspace_id
    ) {
      existing_product_id
      name
      description
      price
      status
      stock
      code_sku
      review_description
    }
  }
`

export default uploadProduct
