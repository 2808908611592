import gql from 'graphql-tag'

const addWorkspaceToken = gql`
  mutation addWorkspaceToken(
    $platform_id: Float!
    $connection_name: String
    $shop_id: Float
    $shop_url: String
    $access_token: String
    $refresh_token: String
    $workspace_id: Float!
    $status: Float
  ){
    addWorkspaceToken(
    platform_id :$platform_id
    connection_name:$connection_name
    shop_id:$shop_id
    shop_url:$shop_url
    access_token:$access_token
    refresh_token:$refresh_token
    workspace_id:$workspace_id
    status: $status
    ){
      status
      msg
      data
    }
  }
`

export default addWorkspaceToken
