import gql from 'graphql-tag'

const getFolderDetail = gql`
  query folderDetail(
    $workspace_id: Float!,
    $folder_id: Float!,
  ) {
    folderDetail(
      workspace_id: $workspace_id,
      folder_id: $folder_id,
    ) {
      id
      name
      color
      is_public
      owner {
        id
        name
      }
      folder_permissions {
        permission_type
        user {
          id
          name
          photo
        }
      }
    }
  }
`

export default getFolderDetail
