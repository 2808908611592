import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    tasks: null,
    jobs: null,
    prospects: null,

    taskCount: [0, 0, 0, 0, 0],
    jobCount: [0, 0, 0, 0, 0],
    prospectCount: [0, 0, 0, 0, 0],

    loadingTasks: false,
    loadingJobs: false,
    loadingProspects: false,

    taskPagination: {
      limit: 5,
      offset: 0,
    },
    jobPagination: {
      limit: 5,
      offset: 0,
    },
    prospectPagination: {
      limit: 5,
      offset: 0,
    },

    currentTabAssignment: 0,
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {
  },
}
