const { default: gql } = require('graphql-tag')

const prospectStatus = gql`
query prospectStatus {
  prospectStatus{
    id
    name
  }
}

`
export default prospectStatus
