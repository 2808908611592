<template>
  <v-dialog
    v-model="isOpen"
    max-width="450px"
  >
    <v-form
      ref="formAddCard"
      lazy-validation
      @submit.prevent
    >
      <v-card v-if="isOpen">
        <v-card-title>
          Tambahkan Data Kartu Baru
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="formData.number"
                outlined
                label="Nomor Kartu"
                placeholder="1234 1234 1234 1234"
                flat
                :rules="[required, creditCard]"
                hide-details="auto"
                lazy-rules
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="formData.expiration_date"
                outlined
                label="Tanggal Kadaluarsa"
                placeholder="MM/YY"
                flat
                :rules="[required, cardExpirationDate]"
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="formData.cvv"
                outlined
                label="CVV"
                placeholder="***"
                flat
                :append-icon="passwordType ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                :rules="[required, cardCVV]"
                hide-details="auto"
                :type="passwordType ? 'text' : 'password'"
                @click:append="passwordType = !passwordType"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-4">
          <v-spacer />
          <v-btn
            color="primary"
            :loading="loadingAddCardUser"
            :disabled="loadingAddCardUser"
            @click="submit"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'
import {
  required, creditCard, cardExpirationDate, cardCVV,
} from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import useUser from '@/composables/useUser'
import errorHandling from '@/utils/errorHandling'

export default {
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const { MidtransNew3ds } = window
    // eslint-disable-next-line no-unused-vars
    const { addCardData, loadingAddCardUser } = useUser()
    const isOpen = ref(false)
    const formData = ref({
    })
    const formAddCard = ref(null)
    const passwordType = ref(true)
    const show = () => {
      isOpen.value = true
      formData.value = {
        number: '',
        expiration_date: '',
        cvv: '',
      }
      passwordType.value = true
      console.log(formData.value)
    }

    const password = ref('')

    const close = () => {
      isOpen.value = false
    }

    const submit = () => {
      if (formAddCard.value.validate()) {
        // eslint-disable-next-line camelcase
        const card_exp_month = formData.value.expiration_date.split('/')[0]
        // eslint-disable-next-line camelcase
        const card_exp_year = `20${formData.value.expiration_date.split('/')[1]}`
        // eslint-disable-next-line camelcase
        const card_number = formData.value.number.replace(/\s-/g, '')
        // eslint-disable-next-line camelcase
        const card_cvv = formData.value.cvv
        MidtransNew3ds.getCardToken({
          card_number,
          card_exp_month,
          card_exp_year,
          card_cvv,
        }, {
          onSuccess: response => {
            console.log(response)
            addCardData({ token_id: response.token_id, hash: response.hash, expiration_date: formData.value.expiration_date }).then(() => {
              close()
              emit('success')
            })
          },
          onFailure: response => {
            errorHandling(response.validation_messages)
          },
        })
        // addCardData(formData.value).then(() => {
        //   close()
        //   emit('success')
        // })
      }
    }

    return {
      isOpen,
      show,
      close,
      formData,
      // formatNumber,
      required,
      creditCard,
      cardExpirationDate,
      // formatExpiration,
      cardCVV,
      passwordType,
      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      password,
      submit,
      loadingAddCardUser,
      formAddCard,
    }
  },
}
</script>
