import { corporationDetail } from '@/graphql/queries'
import { apolloClient } from '@/vue-apollo'

export default {
  state: {
    corporation: {},
  },
  getters: {
    getCorporationData: state => state.corporation,
  },
  mutations: {
    setCorporation(state, data) {
      state.corporation = data
    },
  },
  actions: {
    getCorporationDetail(state) {
      apolloClient.query({
        query: corporationDetail,
      }).then(value => {
        state.commit('setCorporation', value.data.corporationDetail)
      })
    },
  },
}
