import gql from 'graphql-tag'

const resolveChatRoom = gql`
  mutation (
    $room_id: Float!
    $workspace_id: Float!
  ) {
    resolveChatRoom(
      room_id: $room_id
      workspace_id: $workspace_id
    ) {
      status
      msg
    }
  }
`

export default resolveChatRoom
