import gql from 'graphql-tag'

const commentSearch = gql`
  query commentSearch(
    $search: String!
    $job_id: Float
    $task_id: Float
  ) {
    commentSearch(
      search: $search
      job_id: $job_id
      task_id: $task_id
    ) {
      count
      comments {
        id
        comment
        file {
          id
          fileType
          file_name
          file_path
        }
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`

export default commentSearch
