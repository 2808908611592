import gql from 'graphql-tag'

const getTransactionDashboard = gql`
  query getTransactionDashboard($num_months: Float!) {
    getTransactionDashboard(num_months: $num_months) {
      labels
      data
    }
  }
`

export default getTransactionDashboard
