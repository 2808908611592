import { isEmpty } from './index'
import valid from 'card-validator'

export const required = value => {
  if (Array.isArray(value) && value.length === 0) {
    return 'Data ini wajib diisi.'
  }

  if (isEmpty(value)) return 'Data ini wajib diisi.'

  return true
}

export const validateDeadline = (start, end) => {
  if (start && end && new Date(Date.parse(start)) > new Date(Date.parse(end))) {
    return 'Waktu selesai harus lebih besar dari waktu mulai'
  }

  return true
}

export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || 'Email tidak valid'
}

export const phoneValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // regex for indonesia phone number
  // see more https://www.regextester.com/113246
  const re = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/

  return re.test(String(value)) || 'Bukan nomor HP yang valid'
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ||
    'Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars'
  )
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'Password tidak cocok'

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || 'Hanya dapat menerima angka'
}

export const minNumberValidator = (value, min) => {
  if (isEmpty(value)) {
    return true
  }

  if (value < min) {
    return `Angka minimal ${min}`
  }

  return /^-?[0-9]+$/.test(String(value)) || 'Hanya dapat menerima angka'
}

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || 'The Regex field format is invalid'
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters'
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || 'URL is invalid'
}

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length >= length || `Panjang data minimal harus ${length} karakter`
}

export const maxLength = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length <= length || `Panjang data maksimal ${length} karakter`
}

export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'Inputan tidak valid'
}

export const minArrayLength = (value, min) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length >= min || `Minimal ${min} item`
}

export const maxArrayLength = (value, max) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length <= max || `Maksimal ${max} item`
}

export const positiveInteger = value => {
  if (isEmpty(value)) {
    return true
  }

  return value > 0 || 'Harus lebih besar dari 0'
}

export const MinimalZero = value => {
  if (isEmpty(value)) {
    return true
  }

  return value >= 0 || 'Minimal 0'
}

export const moreThan = (value, target, message) => {
  if (isEmpty(value)) {
    return true
  }

  return +value >= +target || message
}

export const validateTokpedURL = value => {
  if (isEmpty(value)) {
    return true
  }

  const re = /https:\/\/www.tokopedia.com\/[a-zA-Z0-9-]+/

  return re.test(value) || 'URL tidak valid'
}

export const validTimeOffset = value => {
  if (isEmpty(value)) {
    return true
  }
  const regex = /[+-]\d{1,2}[smhd]/
  const arr = value.split(' ')
  let result = true
  arr.forEach(el => {
    if (!regex.test(el)) result = false
  })

  return result || 'Format tidak valid'
}

export const creditCard = value => {
  // const regex = /^\d{4}\d{4}\d{4}\d{4}$/
  const numberValidation = valid.number(value)
  const { card, isValid } = numberValidation
  console.log(card)
  if (isValid && card.type !== 'visa' && card.type !== 'mastercard' && card.type !== 'jcb' && card.type !== 'american-express') {
    console.log(card.type)

    return true
  }

  if (!/^\d{16}$/.test(value)) return 'Format tidak valid'

  return isValid || 'Format tidak valid'
}

export const cardExpirationDate = value => {
  // const regex = /^\d{2}\/\d{2}$/
  const dateValidation = valid.expirationDate(value)

  return dateValidation.isValid || 'Format tidak valid'
}

export const cardCVV = value => {
  // const regex = /^\d{3}$/
  const cvvValidation = valid.cvv(value)

  return cvvValidation.isValid || 'Format tidak valid'
}
