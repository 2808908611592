import gql from 'graphql-tag'

const queryDeleteTag = gql`
  query queryDeleteTag(
    $workspace_id: Float!
    $tag_id: Float!
  ) {
    queryDeleteTag(
      workspace_id: $workspace_id
      tag_id: $tag_id
    ) {
      job_count
      task_count
    }
  }
`

export default queryDeleteTag
