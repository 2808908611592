import gql from 'graphql-tag'

const updateJobPriority = gql`
  mutation(
    $workspace_id: Float!
    $job_priority: [paramUpdateJobPriority!]!
  ) {
    updateJobPriority(
      workspace_id: $workspace_id
      job_priority: $job_priority
    ) {
      status
      data
      msg
    }
  }
`

export default updateJobPriority
