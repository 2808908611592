import gql from 'graphql-tag'

const inviteToWorkspace = gql`
  mutation(
    $email_invite: [String!]!
    $workspace_id: Float!
    $role_id: Float!
  ) {
    inviteToWorkspace(
      email_invite: $email_invite
      workspace_id: $workspace_id
      role_id: $role_id
    ) {
      status
      msg
      data
    }
  }
`

export default inviteToWorkspace
