import gql from 'graphql-tag'

const updateStatusTokopediaToken = gql`
  mutation updateStatusTokopediaToken(
    $param: paramConfirmTokopedia!
  ) {
    updateStatusTokopediaToken(
      param: $param
    ) {
      status
      msg
    }
  }
`

export default updateStatusTokopediaToken
