import gql from 'graphql-tag'

const tags = gql`
query tags (
  $pagination: pagination!
  $search: String
  $workspace_id: Float!
) {
  tags(
    pagination:$pagination
    search:$search
    workspace_id: $workspace_id
  ){
    id
    name
    color
  }
}
`
export default tags
