import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":"","max-width":"1200"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,{attrs:{"height":"80vh"}},[_c(VNavigationDrawer,{staticClass:"left-sidebar",attrs:{"width":"260","touchless":"","mobile-breakpoint":"sm","temporary":_vm.$vuetify.breakpoint.smAndDown,"absolute":""},model:{value:(_vm.isLeftSidebarOpen),callback:function ($$v) {_vm.isLeftSidebarOpen=$$v},expression:"isLeftSidebarOpen"}},[_c('div',{staticClass:"d-flex flex-column h-full py-4 px-2"},[_c('div',{staticClass:"d-flex flex-column mx-2 mb-1"},[_c('span',{staticClass:"text-caption text--disabled"},[_vm._v("Workspace")]),_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(_vm.workspace.name))])]),_c(VDivider,{staticClass:"my-2"}),_c(VList,{attrs:{"rounded":"","dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.filteredWorkspaceMenu),function(menu,index){return _c(VListItem,{key:index,on:{"click":function($event){_vm.isLeftSidebarOpen && _vm.$vuetify.breakpoint.smAndDown ? (_vm.isLeftSidebarOpen = !_vm.isLeftSidebarOpen) : null}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(menu.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(menu.title))])],1)],1)}),1)],1),_c(VBtn,{staticClass:"mt-auto mx-2",attrs:{"outlined":"","small":"","text":"","color":"error"},on:{"click":_vm.quit}},[_vm._v(" Keluar Workspace ")])],1)]),_c('div',{staticClass:"main-area",style:({
        'margin-left': _vm.isLeftSidebarOpen && _vm.$vuetify.breakpoint.mdAndUp ? '260px' : null,
      })},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"d-flex mt-6 mb-4"},[_c(VIcon,{staticClass:"ml-4",on:{"click":function($event){_vm.isLeftSidebarOpen = !_vm.isLeftSidebarOpen}}},[_vm._v(" "+_vm._s(_vm.isLeftSidebarOpen ? _vm.icons.mdiChevronDoubleLeft : _vm.icons.mdiChevronDoubleRight)+" ")]),_c('h3',{staticClass:"mx-auto",class:{ 'text-subtitle-2 font-weight-bold' : _vm.$vuetify.breakpoint.width < 500 }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VIcon,{staticClass:" mr-6",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),(!_vm.loadingDetail)?_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_c(VTabItem,[_c('WorkspaceSetting',{attrs:{"workspace":_vm.workspace},on:{"onDelete":function($event){_vm.isOpen = false}}})],1),_c(VTabItem,[_c('WorkspaceSharedUser',{attrs:{"workspace":_vm.workspace},on:{"refetch":_vm.fetchWorkspaceDetail}})],1),_c(VTabItem,[_c('WorkspaceChatSettings',{attrs:{"workspace":_vm.workspace}})],1)],1):_vm._e()],1)])],1),_c(VOverlay,{attrs:{"opacity":"0.2","value":_vm.loadingDetail}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"32"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }