import gql from 'graphql-tag'

const toggleBanUser = gql`
  mutation toggleBanUser(
    $user_id: Float!
    $value: Float!
  ) {
    toggleBanUser(
      user_id: $user_id
      value: $value
    ) {
      status
      msg
    }
  }
`

export default toggleBanUser
