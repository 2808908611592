/* eslint-disable camelcase */
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import { hierarchies, hierarchyTree, hierarchyTreeFolder } from '@/graphql/queries'
import {
  addHierarchy, addUserHierarchy, deleteHierarchy, removeUserHierarchy, updateHierarchy,
} from '@/graphql/mutations'

const useHierarchy = () => {
  const recursiveData = ref([])
  const nonRecursiveData = ref([])
  const isLoadingRecursive = ref(false)
  const isLoadingNonRecursive = ref(false)
  const hierarchyTreeFolderData = ref([])
  const loadingHierarchyTreeFolder = ref(false)
  const isLoadingForm = ref(false)
  const workspace_id = store.getters.getCurrentWorkspaceId

  const fetchHierarchyTree = (success = () => {}) => {
    isLoadingRecursive.value = true
    apolloClient.query({
      query: hierarchyTree,
      fetchPolicy: 'no-cache',
      variables: {
        workspace_id,
      },
    }).then(({ data }) => {
      isLoadingRecursive.value = false
      recursiveData.value = data.hierarchyTree
      success(data.hierarchyTree)
    }).catch(err => {
      isLoadingRecursive.value = false
      errorHandling(err, 'Daftar Hierarki')
    })
  }

  const fetchHierarchyTreeFolder = (success = () => {}) => {
    loadingHierarchyTreeFolder.value = true
    apolloClient.query({
      query: hierarchyTreeFolder,
      fetchPolicy: 'no-cache',
      variables: {
        workspace_id,
      },
    }).then(({ data }) => {
      loadingHierarchyTreeFolder.value = false
      hierarchyTreeFolderData.value = data.hierarchyTreeFolder
      success(data.hierarchyTreeFolder)
    }).catch(err => {
      loadingHierarchyTreeFolder.value = false
      errorHandling(err, 'Daftar Hierarki')
    })
  }

  const fetchNonRecursiveData = (success = () => {}) => {
    isLoadingRecursive.value = true
    apolloClient.query({
      query: hierarchies,
      fetchPolicy: 'no-cache',
      variables: {
        workspace_id,
      },
    }).then(({ data }) => {
      isLoadingRecursive.value = false
      nonRecursiveData.value = data.hierarchies
      success()
    }).catch(err => {
      isLoadingRecursive.value = false
      errorHandling(err, 'Daftar Hierarki')
    })
  }
  const addHierarchyFunc = ({ formData }, success) => {
    isLoadingForm.value = true
    apolloClient.mutate({
      mutation: addHierarchy,
      variables: {
        ...formData,
        workspace_id,
      },
    }).then(() => {
      isLoadingForm.value = false
      Vue.notify({
        title: 'Sukses!',
        text: 'Berhasil menambah hierarki!',
      })
      success()
    }).catch(err => {
      isLoadingForm.value = false
      errorHandling(err, 'Tambah Hierarki')
    })
  }
  const updateHierarchyFunc = ({ formData }, success) => {
    isLoadingForm.value = true
    apolloClient.mutate({
      mutation: updateHierarchy,
      variables: {
        ...formData,
        workspace_id,
      },
    }).then(() => {
      isLoadingForm.value = false
      Vue.notify({
        title: 'Sukses!',
        text: 'Berhasil update hierarki!',
      })
      success()
    }).catch(err => {
      isLoadingForm.value = false
      errorHandling(err, 'Update Hierarki')
    })
  }
  const deleteHierarchyFunc = ({ formData }, success) => {
    Vue.$dialog({
      title: 'Hapus hierarki?',
      body: 'Konfirmasi jika anda ingin menghapus hierarki.',
    }).then(isConfirmed => {
      if (isConfirmed) {
        isLoadingForm.value = true
        apolloClient.mutate({
          mutation: deleteHierarchy,
          variables: {
            ...formData,
            workspace_id,
          },
        }).then(() => {
          isLoadingForm.value = false
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil hapus hierarki!',
          })
          success()
        }).catch(err => {
          isLoadingForm.value = false
          errorHandling(err, 'Hapus Hierarki')
        })
      }
    })
  }
  const addUserHierarchyFunc = (formData, success) => {
    isLoadingForm.value = true
    apolloClient.mutate({
      mutation: addUserHierarchy,
      variables: {
        ...formData,
        workspace_id,
      },
    }).then(() => {
      isLoadingForm.value = false
      Vue.notify({
        title: 'Sukses!',
        text: 'Berhasil menambah pengguna hierarki!',
      })
      success()
    }).catch(err => {
      isLoadingForm.value = false
      errorHandling(err, 'Tambah Hierarki')
    })
  }
  const removeUserHierarchyFunc = ({ formData }, success) => {
    Vue.$dialog({
      title: 'Hapus user?',
      body: 'Konfirmasi jika anda ingin menghapus user dari hierarki.',
    }).then(isConfirmed => {
      if (isConfirmed) {
        apolloClient.mutate({
          mutation: removeUserHierarchy,
          variables: {
            ...formData,
            workspace_id,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menghapus pengguna hierarki!',
          })
          success()
        }).catch(err => {
          errorHandling(err, 'Hapus Hierarki')
        })
      }
    })
  }

  return {
    recursiveData,
    nonRecursiveData,
    fetchHierarchyTree,
    fetchNonRecursiveData,
    isLoadingNonRecursive,
    isLoadingForm,
    isLoadingRecursive,
    updateHierarchyFunc,
    addHierarchyFunc,
    addUserHierarchyFunc,
    removeUserHierarchyFunc,
    deleteHierarchyFunc,

    fetchHierarchyTreeFolder,
    hierarchyTreeFolderData,
    loadingHierarchyTreeFolder,
  }
}
export default useHierarchy
