import gql from 'graphql-tag'

const prospects = gql`
query prospects(
  $pagination: pagination!
  $filter: paramQueryProspect
  $workspace_id: Float!
) {
  prospects(
    pagination: $pagination
    filter: $filter
    workspace_id: $workspace_id
  ){
    count
    prospects{
      id
      name
      description
      status {
        id
        name
      }
      customer{
        id
        name
        gender
        description
        address
        job_title
        birth_date
        phone_number
        whatsapp_number
        email
        instagram
        facebook
        type{
          id
          name
          color
        }
        city{
          id
          name
        }
        created_at
        updated_at
      }
      assignedTo{
        id
        user {
          id
          name
          email
          name
          first_name
          last_name
          photo
          notify_task_assignment
          notify_comment
          notify_job_assignment
          user_status{
            id
            name
          }
          availability
        }
      }
      created_by{
        id
        name
      }
      visibility{
        id
        name
      }
      value
      lexorank
      created_at
      updated_at
      is_subscribed
    }
  }
}
`
export default prospects
