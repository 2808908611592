import gql from 'graphql-tag'

const tasks = gql`
    query tasks (
      $pagination: pagination!
      $filter:paramQueryTasks
      $search: String
      $workspace_id: Float!
      $timestamp: DateTime
    ) {
    tasks(
      pagination: $pagination
      filter:$filter
      search:$search
      workspace_id: $workspace_id
      timestamp: $timestamp
    ){
    count
    tasks{
      id
      subject
      description
      start_date
      end_date
      completed_at
      created_by{
        id
        name
      }
      referenceTag {
        id
        tag {
          id
          name
          color
        }
      }
      created_at
      updated_at
      deleted_at
      lexorank
      assignedTo{
        id
        user{
          id
          name
          photo
        }
      }
      customer {
        id
        name
        email
        phone_number
      }
      job {
        id
        name
      }
    }
  }
}
`
export default tasks
