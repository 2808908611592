import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    filter: {
      sort: {
        id: 1,
        value: 'name',
        sortType: 'ASC',
      },
      my_jobType: {
        id: 1,
        text: 'All',
        value: null,
      },
      show_archived: {
        id: 2,
        text: 'Hide',
        value: false,
      },
      search: '',
    },
    filterSharedJobType: {
      sort: {
        id: 1,
        value: 'name',
        sortType: 'ASC',
      },
      my_jobType: {
        id: 1,
        text: 'All',
        value: null,
      },
      show_archived: {
        id: 2,
        text: 'Hide',
        value: false,
      },
      search: '',
    },
    loadingJobType: false,
    sharedJobTypeList: [],
    loadingSharedJobType: false,
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {
    resetJobTypeFilter(state) {
      state.state.filter = {
        sort: {
          id: 1,
          value: 'name',
          sortType: 'ASC',
        },
        my_folder: {
          id: 1,
          text: 'All',
          value: null,
        },
        show_archived: {
          id: 2,
          text: 'Hide',
          value: false,
        },
        search: '',
      }
    },
    resetSharedJobTypeFilter(state) {
      state.state.filterSharedJobType = {
        sort: {
          id: 1,
          value: 'name',
          sortType: 'ASC',
        },
        my_folder: {
          id: 1,
          text: 'All',
          value: null,
        },
        show_archived: {
          id: 2,
          text: 'Hide',
          value: false,
        },
        search: '',
      }
    },
  },
}
