import gql from 'graphql-tag'

const prospectsKanban = gql`
  query prospectsKanban(
    $pagination: pagination!
    $filter: paramQueryProspect
    $workspace_id: Float!
  ) {
    prospectsKanban(
      pagination: $pagination
      filter: $filter
      workspace_id: $workspace_id
    ) {
      won {
        id
        name
        description
        status {
          id
          name
        }
        customer{
          id
          name
          gender
          description
          address
          job_title
          birth_date
          phone_number
          whatsapp_number
          email
          instagram
          facebook
          type{
            id
            name
            color
          }
          city{
            id
            name
          }
          created_at
          updated_at
        }
        assignedTo{
          id
          user {
            id
            name
            email
            name
            first_name
            last_name
            photo
            notify_task_assignment
            notify_comment
            notify_job_assignment
            user_status{
              id
              name
            }
            availability
          }
        }
        created_by{
          id
          name
        }
        visibility{
          id
          name
        }
        value
        lexorank
        created_at
        updated_at
        is_subscribed
      }
      lost {
        id
        name
        description
        status {
          id
          name
        }
        customer{
          id
          name
          gender
          description
          address
          job_title
          birth_date
          phone_number
          whatsapp_number
          email
          instagram
          facebook
          type{
            id
            name
            color
          }
          city{
            id
            name
          }
          created_at
          updated_at
        }
        assignedTo{
          id
          user {
            id
            name
            email
            name
            first_name
            last_name
            photo
            notify_task_assignment
            notify_comment
            notify_job_assignment
            user_status{
              id
              name
            }
            availability
          }
        }
        created_by{
          id
          name
        }
        visibility{
          id
          name
        }
        value
        lexorank
        created_at
        updated_at
        is_subscribed
      }
      cold {
        id
        name
        description
        status {
          id
          name
        }
        customer{
          id
          name
          gender
          description
          address
          job_title
          birth_date
          phone_number
          whatsapp_number
          email
          instagram
          facebook
          type{
            id
            name
            color
          }
          city{
            id
            name
          }
          created_at
          updated_at
        }
        assignedTo{
          id
          user {
            id
            name
            email
            name
            first_name
            last_name
            photo
            notify_task_assignment
            notify_comment
            notify_job_assignment
            user_status{
              id
              name
            }
            availability
          }
        }
        created_by{
          id
          name
        }
        visibility{
          id
          name
        }
        value
        lexorank
        created_at
        updated_at
        is_subscribed
      }
      hot {
        id
        name
        description
        status {
          id
          name
        }
        customer{
          id
          name
          gender
          description
          address
          job_title
          birth_date
          phone_number
          whatsapp_number
          email
          instagram
          facebook
          type{
            id
            name
            color
          }
          city{
            id
            name
          }
          created_at
          updated_at
        }
        assignedTo{
          id
          user {
            id
            name
            email
            name
            first_name
            last_name
            photo
            notify_task_assignment
            notify_comment
            notify_job_assignment
            user_status{
              id
              name
            }
            availability
          }
        }
        created_by{
          id
          name
        }
        visibility{
          id
          name
        }
        value
        lexorank
        created_at
        updated_at
        is_subscribed
      }
    }
  }
`

export default prospectsKanban
