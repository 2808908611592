import gql from 'graphql-tag'

const getUserByForgotPasswordCode = gql`
  query getUserByForgotPasswordCode($forgot_password_code: String!) {
    getUserByForgotPasswordCode(forgot_password_code: $forgot_password_code) {
      id
      email
      name
      phone
    }
  }
`

export default getUserByForgotPasswordCode
