import gql from 'graphql-tag'

const jobStatus = gql`
  query jobStatus (
    $job_type: [Float!]!
    $order: String
    $workspace_id: Float!
  ) {
    jobStatus (
      job_type: $job_type
      order: $order
      workspace_id: $workspace_id
    ) {
      id
      name
      color
      urutan
      job_type {
        name
      }
      job_order {
        next_job_status_id
      }
      type
    }
  }
`

export default jobStatus
