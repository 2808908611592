<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
  >
    <v-card>
      <div class="d-flex justify-space-between text-h5 mb-4 pa-4">
        <div>Pilih Durasi Plan</div>
        <v-icon
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text>
        <v-row
          v-if="item"
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              outlined
              height="120px"
              class="d-flex justify-center align-center flex-column text-h6"
              :class="{
                'plan-duration-card-active': selectedInterval === 1,
              }"
              @click="selectedInterval = 1"
            >
              <div class="font-weight-bold mb-3">
                Bulanan
              </div>
              <div>{{ formatCurrency(item.price_month) }}</div>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              outlined
              height="120px"
              class="d-flex justify-center align-center flex-column text-h6"
              :class="{
                'plan-duration-card-active': selectedInterval === 12,
              }"
              @click="selectedInterval = 12"
            >
              <div class="font-weight-bold mb-3">
                Tahunan
              </div>
              <div>{{ formatCurrency(item.price_year) }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-2">
        <v-spacer />
        <v-btn
          color="primary"
          large
          @click="addSubsTransaction(item, selectedInterval)"
        >
          Tingkatkan Plan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'
import { formatCurrency } from '@core/utils/filter'
import { mdiClose } from '@mdi/js'
import { createSubsTransaction } from '@/graphql/mutations'
import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'

export default {
  setup() {
    const { snap } = window
    const isOpen = ref(false)
    const item = ref(null)
    const show = param => {
      isOpen.value = true
      item.value = param
    }
    const close = () => {
      isOpen.value = false
    }

    const selectedInterval = ref(1)

    const addSubsTransaction = (plan, interval) => {
      console.log(`ORIENS-${store.getters.getUserData.id}-${Date.now()}-${plan.id}-${interval}}`)
      console.log(plan)
      apolloClient.mutate({
        mutation: createSubsTransaction,
        fetchPolicy: 'no-cache',
        variables: {
          param: {
            order_id: `ORIENS-${store.getters.getUserData.id}-${Date.now()}-${plan.id}-${interval}`,
            item: {
              id: plan.id,
              name: `${plan.name} - ${interval} Bulan`,
              price: interval === 1 ? plan.price_month : plan.price_year,
              quantity: 1,
            },
          },
        },
      }).then(async ({ data }) => {
        console.log(data.createSubsTransaction.data)
        await snap.pay(data.createSubsTransaction.data)
      }).catch(err => {
        errorHandling(err)
      })
    }

    return {
      isOpen,
      show,
      close,
      item,
      formatCurrency,
      selectedInterval,
      addSubsTransaction,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
<style scoped>
.plan-duration-card-active {
  border: 2px solid var(--v-primary-base) !important;
  color: var(--v-primary-base) !important;
}
</style>
