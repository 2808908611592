import gql from 'graphql-tag'

const invitationPage = gql`
  query invitationPage(
    $invite_code: String!
  ) {
    invitationPage(
      invite_code: $invite_code
    ) {
      id
      name
      identifier_id
      invite_code
      photo
    }
  }
`

export default invitationPage
