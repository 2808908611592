import gql from 'graphql-tag'

const jobPriority = gql`
  query jobPriority (
    $job_type: [Float!]!
    $order: String
    $workspace_id: Float!
  ) {
    jobPriority (
      job_type: $job_type
      order: $order
      workspace_id: $workspace_id
    ) {
      id
      name
      color
      urutan
    }
  }
`

export default jobPriority
