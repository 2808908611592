import gql from 'graphql-tag'

const corporationDetail = gql`
query corporation {
 corporationDetail {
  id
  name
  address
  email
  photo
  phone_number
  sales{
    id
    phone
    email
    first_name
    last_name
    photo
    role {
      id
      name
    }
    user_status {
        id
        name
      }
      last_seen_at
      created_at
      updated_at
      deleted_at
    }
    limit_customers
    limit_user
  }
}
`
export default corporationDetail
