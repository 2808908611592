import gql from 'graphql-tag'

const deleteJobType = gql`
  mutation(
    $jobtype_id: Float!
    $workspace_id: Float!
  ) {
    deleteJobType(
      jobtype_id: $jobtype_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default deleteJobType
