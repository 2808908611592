import gql from 'graphql-tag'

const jobDetail = gql`
  query jobDetail(
    $job_id: Float!
    $workspace_id: Float!
  ) {
    jobDetail(
      job_id: $job_id
      workspace_id: $workspace_id
    ) {
      id
      name
      description
      status {
        id
        name
        color
        job_type {
          id
          name
        }
      }
      priority {
        id
        name
        color
      }
      referenceTag {
        id
        tag {
          id
          name
          color
        }
      }
      products{
        product{
          id
          name
          description
          code_sku
          price
          status
          stock
          photo{
            id
            photo
            order_photo
          }
          created_at
          updated_at
        }
        quantity
      }
      start_date
      expected_close_date
      customer {
        id
        name
        gender
        description
        address
        job_title
        birth_date
        phone_number
        whatsapp_number
        email
        instagram
        facebook
        type {
          id
          name
          color
        }
      }
      created_by {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
      created_at
      updated_at
      deleted_at
      assignedTo {
        id
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
      }
      tasks{
        id
        subject
        description
        start_date
        end_date
        completed_at
        created_by{
          id
          name
        }
        referenceTag {
          id
          tag {
            id
            name
            color
          }
        }
        created_at
        updated_at
        deleted_at
        assignedTo{
          id
          user{
            name
            photo
          }
        }
      }
      custom_attribute_values
    }
  }
`

export default jobDetail
