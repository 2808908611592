import gql from 'graphql-tag'

const accurateAuthorizationLink = gql`
  query accurateAuthorizationLink(
    $workspace_id: Float!
  ) {
    accurateAuthorizationLink(
      workspace_id: $workspace_id
    )
  }
`

export default accurateAuthorizationLink
