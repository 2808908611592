import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    teams: [],
    loadingTeam: false,
    loadingChannels: false,
    activeChannel: null,
    threadListOffset: 0,
    currentTeam: null,
    channelList: [],
    archivedChannel: [],
    archivedThreadsList: [],
    pinnedThreadsList: [],
    closeMenuSearch: true,
    customSettingsQuill: {
      modules: {
        imageResize: {},
        toolbar: [
          [{ font: [] }, { header: [false, 1, 2, 3, 4, 5, 6] }],
          ['bold', 'italic', 'underline'],
          [{ color: [] }, { background: [] }],
          [{ script: 'super' }, { script: 'sub' }],
          ['blockquote', 'code-block'],
          [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' },
          ],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['image', 'link'],
        ],
      },
      theme: 'snow',
    },
    teamFilter: {
      search: '',
      sort: {
        label: 'Nama Tim A-Z',
        field: 'name',
        type: 'ASC',
      },
      pagination: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    },
    channelFilter: {
      isArchived: false,
      channelOffset: 0,
      sort: {
        label: 'Nama Channel A-Z',
        field: 'name',
        type: 'ASC',
      },
    },
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {},
}
