import gql from 'graphql-tag'

const getMyTokopediaAccountData = gql`
  query getTokopediaAccountData(
    $workspace_id: Float!
  ) {
    getTokopediaAccountData(
      workspace_id: $workspace_id
    ) {
      id
      connection_name
      shop_url
    }
  }
`

export default getMyTokopediaAccountData
