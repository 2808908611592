import gql from 'graphql-tag'

const updatePost = gql`
  mutation (
    $post_id: Float!
    $html_comment: String!
  ) {
    updatePost (
      post_id: $post_id
      html_comment: $html_comment
    ) {
      status
      msg
      data
    }
  }
`

export default updatePost
