import gql from 'graphql-tag'

const getWorkflowDashboardStatusAdmin = gql`
  query getWorkflowDashboardStatusAdmin(
    $filter: paramWorkflowDashboardStatus
  ) {
    getWorkflowDashboardStatusAdmin(
      filter: $filter
    ) {
      labels
      data
    }
  }
`

export default getWorkflowDashboardStatusAdmin
