import gql from 'graphql-tag'

const automationObjectsV2 = gql`
  query automationObjectsV2 {
    automationObjectsV2 {
      id
      name
      platform {
        id
        name
      }
    }
  }
`
export default automationObjectsV2
