import gql from 'graphql-tag'

const updateWorkspaceProfile = gql`
  mutation updateWorkspaceProfile(
    $workspace_id: Float!
    $param: paramUpdateWorkspaceProfile!
  ) {
    updateWorkspaceProfile(
      workspace_id: $workspace_id
      param: $param
    ) {
      status
      msg
      data
    }
  }
`

export default updateWorkspaceProfile
