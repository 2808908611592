import gql from 'graphql-tag'

const deleteHierarchy = gql`
  mutation deleteHierarchy(
    $id: Float!
    $workspace_id: Float!
){
    deleteHierarchy(
      id:$id
      workspace_id:$workspace_id
    ){
      status
      msg
      data
    }
}
`
export default deleteHierarchy
