import gql from 'graphql-tag'

const deleteCustomAttribute = gql`
  mutation deleteCustomAttribute(
    $id: Float!
  ) {
    deleteCustomAttribute(
      id: $id
    ) {
      msg
      status
      data
    }
  }
`

export default deleteCustomAttribute
