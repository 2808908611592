import { getFolderDetail, getFolders, sharedJobTypes } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { ref } from '@vue/composition-api'
import store from '@/store'
import {
  addFolder, archiveFolder, deleteFolder, editFolderAttribute, editFolderPermission, unarchiveFolder,
} from '@/graphql/mutations'
import Vue from 'vue'
import { createFieldMapper } from 'vuex-use-fields'

const useFieldFolder = createFieldMapper({ getter: 'folder/getField', setter: 'folder/setField' })
const useFieldJobType = createFieldMapper({ getter: 'jobType/getField', setter: 'jobType/setField' })

const useFolder = () => {
  const state = {
    ...useFieldFolder([
      'loadingFolder', 'folderList', 'filter',
    ]),
    ...useFieldJobType([
      'loadingSharedJobType', 'sharedJobTypeList', 'filterSharedJobType',
    ]),
  }
  const loadingFolder = ref(false)
  const loadingDetailFolder = ref(false)
  const loadingAddFolder = ref(false)
  const loadingEditFolder = ref(false)
  const loadingArchiveFolder = ref(false)
  const loadingDeleteFolder = ref(false)
  const loadingSharedJobType = ref(false)

  const folderList = ref([])
  const sharedJobTypeList = ref([])
  const folderDetail = ref({})

  const fetchFolder = async filter => {
    loadingFolder.value = true
    state.loadingFolder.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getFolders,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          filter,
        },
      }).then(({ data }) => {
        loadingFolder.value = false
        state.loadingFolder.value = false
        resolve(data.folders)
        folderList.value = data.folders
        state.folderList.value = data.folders
      }).catch(err => {
        loadingFolder.value = false
        state.loadingFolder.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchFolderDetail = async id => {
    loadingDetailFolder.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getFolderDetail,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          folder_id: +id,
        },
      }).then(({ data }) => {
        folderDetail.value = data.folderDetail
        resolve(data.folderDetail)
        loadingDetailFolder.value = false
      }).catch(err => {
        loadingDetailFolder.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const createFolder = async ({
    name,
    color,
    isPublic,
    userPermissions,
  }) => {
    loadingAddFolder.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: addFolder,
        variables: {
          name,
          color,
          is_public: isPublic,
          user_permissions: userPermissions,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(({ data }) => {
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil menambahkan folder!',
        })
        loadingAddFolder.value = false
        resolve(data.addFolder)
      }).catch(err => {
        loadingAddFolder.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const updateFolderAttribute = async ({
    id,
    name,
    color,
  }) => {
    loadingEditFolder.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: editFolderAttribute,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          folder_id: id,
          name,
          color,
        },
      }).then(({ data }) => {
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil mengubah folder!',
        })
        loadingEditFolder.value = false
        resolve(data.updateFolderAttribute)
      }).catch(err => {
        loadingEditFolder.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const updateFolderPermission = async ({
    id,
    isPublic,
    userPermissions,
  }) => {
    loadingEditFolder.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: editFolderPermission,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          folder_id: id,
          is_public: isPublic,
          user_permissions: userPermissions,
        },
      }).then(({ data }) => {
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil mengubah folder!',
        })
        loadingEditFolder.value = false
        resolve(data.updateFolderPermission)
      }).catch(err => {
        loadingEditFolder.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const updateArchiveFolder = async id => {
    loadingArchiveFolder.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: archiveFolder,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          folder_id: id,
        },
      }).then(({ data }) => {
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil mengarsipkan folder!',
        })
        loadingArchiveFolder.value = false
        resolve(data.archiveFolder)
      }).catch(err => {
        loadingArchiveFolder.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const updateUnarchiveFolder = async id => {
    loadingArchiveFolder.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: unarchiveFolder,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          folder_id: id,
        },
      }).then(({ data }) => {
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil memulihkan folder!',
        })
        loadingArchiveFolder.value = false
        resolve(data.unarchiveFolder)
      }).catch(err => {
        loadingArchiveFolder.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const updateDeleteFolder = async id => {
    loadingDeleteFolder.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: deleteFolder,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          folder_id: id,
        },
      }).then(({ data }) => {
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil menghapus folder!',
        })
        loadingDeleteFolder.value = false
        resolve(data.deleteFolder)
      }).catch(err => {
        loadingDeleteFolder.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchSharedJobTypes = async filter => {
    loadingSharedJobType.value = true
    state.loadingSharedJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: sharedJobTypes,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          filter,
        },
      }).then(({ data }) => {
        loadingSharedJobType.value = false
        state.loadingSharedJobType.value = false
        resolve(data.sharedJobTypes)
        sharedJobTypeList.value = data.sharedJobTypes
        state.sharedJobTypeList.value = data.sharedJobTypes
      }).catch(err => {
        loadingSharedJobType.value = false
        state.loadingSharedJobType.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const folderPermissionGuard = (object, permissionType) => {
    if (store.getters.getUserData.id === object.owner.id) return true
    if (object.is_public) return true
    if (object.my_role && object.my_role <= permissionType) return true

    return false
  }

  const permissionList = ref([
    {
      id: 1,
      name: 'Admin',
    },
    {
      id: 2,
      name: 'Editor',
    },
    {
      id: 3,
      name: 'Comment Only',
    },
    {
      id: 4,
      name: 'View Only',
    },
  ])

  const resolvePermissionType = permissionType => {
    switch (permissionType) {
      case 'Admin':
        return permissionList.value[0].id
      case 'Editor':
        return permissionList.value[1].id
      case 'CommentOnly':
        return permissionList.value[2].id
      case 'ViewOnly':
        return permissionList.value[3].id
      default:
        return permissionList.value[0].id
    }
  }

  const setUserPermissionType = (data, permission) => {
    data.forEach(el => {
      if (!el.children && el.id === permission.user.id) {
        // eslint-disable-next-line no-param-reassign
        el.permission = resolvePermissionType(permission.permission_type)
      }
      if (el.children) {
        setUserPermissionType(el.children, permission)
      }
    })
  }

  return {
    loadingFolder,
    loadingDetailFolder,
    loadingAddFolder,
    loadingEditFolder,
    loadingArchiveFolder,
    loadingDeleteFolder,
    loadingSharedJobType,

    folderList,
    sharedJobTypeList,
    folderDetail,

    fetchFolder,
    fetchFolderDetail,
    createFolder,
    updateFolderAttribute,
    updateFolderPermission,
    updateArchiveFolder,
    updateDeleteFolder,
    folderPermissionGuard,
    fetchSharedJobTypes,

    updateUnarchiveFolder,

    permissionList,
    setUserPermissionType,
    resolvePermissionType,
  }
}

export default useFolder
