import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    prospectStatusData: [],
    prospectLoading: false,
    prospectList: [],
    filterCount: 0,
    sort: {
      id: 0,
      name: 'Rank Tertinggi',
      value: 'lexorank',
      type: 'ASC',
    },
    prospectCount: 0,
    filter: {
      sort: 'lexorank',
      order: 'ASC',
      search: '',
      created_by: null,
      created_at: null,
      updated_at: null,
      assigned_to: null,
      customer: null,
      status: null,
      is_subscribed: null,
    },
    pagination: {
      limit: 10,
      offset: 0,
    },
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  resetFilter(state) {
    state.state.filter = {
      ...state.state.filter,
      search: '',
      created_by: null,
      created_at: null,
      updated_at: null,
      assigned_to: null,
      customer: null,
      status: null,
      is_subscribed: null,
    }
  },
}
