import gql from 'graphql-tag'

const dashboardProspects = gql`
  query dashboardProspects($pagination: pagination!) {
    dashboardProspects(pagination: $pagination) {
      new {
        count
        prospects {
          id
          name
          description
          status {
            id
            name
          }
          customer {
            id
            name
            email
            phone_number
          }
          created_at
          updated_at
          deleted_at
          expired_at
          created_by {
            id
            name
          }
          workspace {
            id
            name
            identifier_id
          }
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          private_reference {
            id
            user {
              id
              name
              photo
            }
          }
          visibility {
            id
            name
            description
          }
        }
      }
      today {
        count
        prospects {
          id
          name
          description
          status {
            id
            name
          }
          customer {
            id
            name
            email
            phone_number
          }
          created_at
          updated_at
          deleted_at
          expired_at
          created_by {
            id
            name
          }
          workspace {
            id
            name
            identifier_id
          }
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          private_reference {
            id
            user {
              id
              name
              photo
            }
          }
          visibility {
            id
            name
            description
          }
        }
      }
      tomorrow {
        count
        prospects {
          id
          name
          description
          status {
            id
            name
          }
          customer {
            id
            name
            email
            phone_number
          }
          created_at
          updated_at
          deleted_at
          expired_at
          created_by {
            id
            name
          }
          workspace {
            id
            name
            identifier_id
          }
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          private_reference {
            id
            user {
              id
              name
              photo
            }
          }
          visibility {
            id
            name
            description
          }
        }
      }
      late {
        count
        prospects {
          id
          name
          description
          status {
            id
            name
          }
          customer {
            id
            name
            email
            phone_number
          }
          created_at
          updated_at
          deleted_at
          expired_at
          created_by {
            id
            name
          }
          workspace {
            id
            name
            identifier_id
          }
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          private_reference {
            id
            user {
              id
              name
              photo
            }
          }
          visibility {
            id
            name
            description
          }
        }
      }
      unscheduled {
        count
        prospects {
          id
          name
          description
          status {
            id
            name
          }
          customer {
            id
            name
            email
            phone_number
          }
          created_at
          updated_at
          deleted_at
          expired_at
          created_by {
            id
            name
          }
          workspace {
            id
            name
            identifier_id
          }
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          private_reference {
            id
            user {
              id
              name
              photo
            }
          }
          visibility {
            id
            name
            description
          }
        }
      }
    }
  }
`

export default dashboardProspects
