import gql from 'graphql-tag'

const archiveJob = gql`
mutation archiveJob(
  $is_archive: Boolean!
  $id: Float!
  $workspace_id: Float!
){
  archiveJob(
    is_archive: $is_archive
    id: $id
    workspace_id: $workspace_id
  ){
    status 
    msg
    data
  }
}
`
export default archiveJob
