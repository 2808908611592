<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      flat
      class="d-flex flex-column"
      multiple
    >
      <v-expansion-panel
        v-show="!state.loadingFolder.value"
        class="v-expansion-panel-folder"
      >
        <v-expansion-panel-header
          class="pa-0 ps-6"
          expand-icon=""
        >
          <div class="d-flex align-center">
            <v-icon>
              {{ icons.mdiFolder }}
            </v-icon>
            <span
              v-if="!menuIsVerticalNavMini || isHovered"
              class="ms-2"
            >
              Folders
            </span>
          </div>
          <template v-slot:actions>
            <v-icon v-if="!menuIsVerticalNavMini || isHovered">
              {{ icons.mdiMenuDown }}
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list-item-group
            v-for="(folder, index) in state.folderList.value"
            :key="folder.id"
          >
            <v-menu
              open-on-hover
              offset-x
              :disabled="!folderPermissionGuard(folder, 1)"
            >
              <template
                v-slot:activator="{ on, attrs }"
              >
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item
                    v-bind="folderLinkProps[index]"
                    class="vertical-nav-menu-link pe-0 ps-6"
                    active-class="bg-gradient-primary"
                  >
                    <v-list-item-icon class="me-2">
                      <v-badge
                        color="#AF4126"
                        content="0"
                        overlap
                      >
                        <v-icon
                          :color="folder.color"
                          size="20"
                        >
                          {{ icons.mdiFolder }}
                        </v-icon>
                      </v-badge>
                    </v-list-item-icon>

                    <v-list-item-title class="text-caption">
                      {{ folder.name }}
                    </v-list-item-title>
                    <v-list-item-action

                      class="my-0 me-2 justify-end"
                    >
                      <div class="d-flex">
                        <v-icon
                          v-if="!folder.is_public"
                          size="18"
                          class="ms-1"
                        >
                          {{ icons.mdiLockOutline }}
                        </v-icon>
                        <v-icon
                          v-if="folder.archived_at"
                          size="18"
                          class="ms-1"
                        >
                          {{ icons.mdiArchive }}
                        </v-icon>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </template>
              <div
                @mouseover="changeHover(true)"
                @mouseleave="changeHover(false)"
              >
                <v-list>
                  <v-list-item
                    @click="$refs.editFolderAttributeForm.show(folder)"
                  >
                    <v-list-item-title>Rename & Edit Color</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="$refs.editFolderPermissionForm.show(folder)"
                  >
                    <v-list-item-title>Sharing & Permission</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class="warning--text"
                    :disabled="!folderPermissionGuard(folder, 0)"
                    @click="!folder.archived_at ? confirmArchiveFolder(folder) : confirmUnarchiveFolder(folder)"
                  >
                    <v-list-item-title>{{ !folder.archived_at ? 'Archive' : 'Unarchive' }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class="error--text"
                    :disabled="!folderPermissionGuard(folder, 0)"
                    @click="confirmDeleteFolder(folder)"
                  >
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>
          </v-list-item-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <template
        v-if="state.loadingFolder.value"
      >
        <div
          class="text-center mt-5"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
      </template>
    </v-expansion-panels>

    <edit-folder-attribute-form
      ref="editFolderAttributeForm"
      @onChangeFolder="$emit('onChangeFolder')"
    />
    <edit-folder-permission-form
      ref="editFolderPermissionForm"
      @onChangeFolder="$emit('onChangeFolder')"
    />
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import {
  computed, inject, onMounted, ref,
} from '@vue/composition-api'
import {
  mdiPlus, mdiMagnify, mdiFolder, mdiArchive, mdiLockOutline, mdiFolderTable, mdiMenuDown,
} from '@mdi/js'
import useFolder from '@/composables/useFolder'
import EditFolderAttributeForm from '@/views/folder/EditFolderAttributeForm.vue'
import EditFolderPermissionForm from '@/views/folder/EditFolderPermissionForm.vue'
import Vue from 'vue'
import { createFieldMapper } from 'vuex-use-fields'
import useAppConfig from '@core/@app-config/useAppConfig'

const useFieldFolder = createFieldMapper({ getter: 'folder/getField', setter: 'folder/setField' })

export default {
  components: {
    EditFolderAttributeForm,
    EditFolderPermissionForm,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = {
      ...useFieldFolder([
        'loadingFolder', 'folderList', 'filter',
      ]),
    }

    const panel = ref([0])

    const { menuIsVerticalNavMini } = useAppConfig()
    const isHovered = inject('isHovered')
    const { t } = useUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()
    const changeHover = val => {
      emit('onChangeMenuHover', val)
    }
    const {
      fetchFolder,
      updateArchiveFolder, loadingArchiveFolder,
      updateDeleteFolder, loadingDeleteFolder,
      folderPermissionGuard,
      updateUnarchiveFolder,
    } = useFolder()

    const editFolderAttributeForm = ref(null)
    const editFolderPermissionForm = ref(null)

    const folderLinkProps = computed(() => {
      // console.log('update')
      const folderList = state.folderList.value

      return folderList.map(folder => ({
        to: {
          name: 'folder-detail',
          params: { id: folder.id },
        },
      }))
    })
    const getFolder = () => {
      console.log('verticalnav')
      fetchFolder({
        search: state.filter.value.search,
        sort: state.filter.value.sort.value,
        sortType: state.filter.value.sort.sortType,
        show_archived: state.filter.value.show_archived.value,
        my_folder: state.filter.value.my_folder.value,
      })
    }

    const menuItem = ref([
      {
        id: 1,
        title: 'Rename & Edit Color',
        click: () => {
          console.log('tes')
          editFolderAttributeForm.value.open()
        },
      },
      {
        id: 2,
        title: 'Sharing & Permission',
      },
      {
        id: 3,
        title: 'Archive',
      },
      {
        id: 4,
        title: 'Delete',
      },
    ])

    onMounted(() => {
      getFolder()
    })

    const confirmArchiveFolder = folder => {
      Vue.$dialog({
        title: 'Archive Folder',
        body: 'Yakin ingin mengarsipkan folder ini?',
      }).then(result => {
        if (result) {
          updateArchiveFolder(folder.id).then(() => {
            emit('onChangeFolder')
          })
        }
      })
    }

    const confirmUnarchiveFolder = folder => {
      Vue.$dialog({
        title: 'Unarchive Folder',
        body: 'Yakin ingin memulihkan folder ini?',
      }).then(result => {
        if (result) {
          updateUnarchiveFolder(folder.id).then(() => {
            emit('onChangeFolder')
          })
        }
      })
    }

    const confirmDeleteFolder = folder => {
      Vue.$dialog({
        title: 'Delete Folder',
        body: 'Yakin ingin menghapus folder ini?',
      }).then(result => {
        if (result) {
          updateDeleteFolder(folder.id).then(() => {
            emit('onChangeFolder')
          })
        }
      })
    }

    return {
      t,
      canViewVerticalNavMenuLink,

      icons: {
        mdiPlus,
        mdiMagnify,
        mdiFolder,
        mdiArchive,
        mdiLockOutline,
        mdiFolderTable,
        mdiMenuDown,
      },

      getFolder,
      folderLinkProps,
      menuItem,
      changeHover,

      editFolderAttributeForm,
      editFolderPermissionForm,

      confirmArchiveFolder,
      loadingArchiveFolder,

      confirmUnarchiveFolder,

      confirmDeleteFolder,
      loadingDeleteFolder,

      folderPermissionGuard,
      state,
      isHovered,
      menuIsVerticalNavMini,
      panel,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}

.v-expansion-panel-folder::before {
  box-shadow: none !important;
}

.v-application.theme--dark .v-expansion-panel.v-expansion-panel--active {
  box-shadow: none !important;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
  box-shadow: none !important;
}

.v-expansion-panel-folder {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>
