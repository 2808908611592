import gql from 'graphql-tag'

const teams = gql`
  query teams(
    $pagination: pagination!
    $filter: filterTeam
    $search: String
    $workspace_id: Float!
  ) {
    teams(
      pagination: $pagination, 
      filter: $filter, 
      search: $search, 
      workspace_id: $workspace_id
    ) {
      id
      name
      description
      users {
        id
        first_name
        last_name
        name
        email
        phone
        photo
      }
      user {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
      channel_permission
      is_public
      created_at
      updated_at
      deleted_at
      userCount
      status
    }
  }
`

export default teams
