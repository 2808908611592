import gql from 'graphql-tag'

const deleteComment = gql`
  mutation(
    $id: Float!
  ) {
    deleteComment(
      id: $id
    ) {
      status
      msg
      data
    }
  }
`

export default deleteComment
