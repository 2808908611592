import gql from 'graphql-tag'

const updateComment = gql`
  mutation(
    $comment: String!
    $id: Float!
  ) {
    updateComment(
      comment: $comment
      id: $id
    ) {
      status
      msg
      data
    }
  }
`

export default updateComment
