import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    loadingChats: false,
    statusRoomOptions: [],
    chatRoomList: [],
    chatRoomCount: 0,
    activeChat: null,
    documents: [],
    chatListOffset: 0,
  },
  getters: {
    getField,
    getStatusRoomOptions: state => state.statusRoomOptions,
  },
  mutations: {
    setField,
    setStatusRoomOptions(state, data) {
      state.statusRoomOptions = data
    },
  },
  actions: {
    sortChatRoomList(state) {
      state.chatRoomList.sort((a, b) => new Date(Date.parse(a.lastChat.created_at)) - new Date(Date.parse(b.lastChat.created_at)))
    },
  },
}
