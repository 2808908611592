import gql from 'graphql-tag'

const updateTaskTag = gql`
mutation updateTaskTag($task_id: Float!, $tagId: [Int!]!) {
  updateTaskTag(task_id: $task_id, tagId: $tagId) {
    status
    msg
    data
  }
}
`
export default updateTaskTag
