import gql from 'graphql-tag'

const getWorkflowDashboardStatus = gql`
  query getWorkflowDashboardStatus(
    $filter: paramWorkflowDashboardStatus
  ) {
    getWorkflowDashboardStatus(
      filter: $filter
    ) {
      platform {
        id
        name
      }
      account_count
      workflow_count
    }
  }
`

export default getWorkflowDashboardStatus
