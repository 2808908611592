<template>
  <div class="workspace d-flex align-center justify-center">
    <XyzTransition
      appear
      xyz="fade left-3 delay-3"
    >
      <v-card
        v-if="userData"
        class="pa-8 d-flex flex-column"
        :class="{ 'mx-2' : !fromDashboard }"
        min-height="50vh"
        :max-width="fromDashboard ? '800' : null"
        :min-width="fromDashboard ? null : '70vw'"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <XyzTransition
              appear
              xyz="fade left-3 delay-3"
            >
              <v-img
                :src="require('@/assets/images/svg/oriens-text.svg')"
                max-width="130px"
                alt="logo"
                contain
                eager
                class="app-logo mt-2"
              />
            </XyzTransition>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <XyzTransition
              appear
              xyz="fade left-3 delay-5"
            >
              <div v-if="!fromDashboard">
                <span class="text-caption">Halo, {{ userData.first_name }}!</span>
                <h4>Selamat datang di Oriens CRM 👋</h4>
                <span class="d-block mt-2 text-subtitle-2">Yuk buat workspace pertama mu 🚀</span>
              </div>
              <div v-else>
                <h4>Buat Workspace Baru</h4>
              </div>
            </XyzTransition>
            <XyzTransition
              appear
              xyz="fade left-3 delay-7"
            >
              <div>
                <v-form
                  ref="workspaceForm"
                  lazy-validation
                >
                  <v-text-field
                    v-model="name"
                    :rules="[required]"
                    label="Nama workspace (wajib diisi)"
                    placeholder="Input nama workspace"
                    outlined
                    dense
                    hide-details="auto"
                    class="mt-8"
                  />
                  <v-text-field
                    v-model="id"
                    :rules="[
                      required,
                      alphaDashValidator,
                      maxLength(id, 20),
                    ]"
                    label="Workspace ID (wajib diisi)"
                    placeholder="Input workspace ID"
                    outlined
                    dense
                    hide-details="auto"
                    class="mt-4"
                    :error="isIDError && !isIDApproved"
                    :success="isIDApproved && !isIDError"
                    :error-messages="isIDError && !isIDApproved ? 'ID workspace tidak tersedia' : null"
                  >
                    <template #append>
                      <v-menu
                        open-on-hover
                        offset-y
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="text--disabled"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiHelpCircleOutline }}
                          </v-icon>
                        </template>
                        <v-card
                          max-width="300"
                          class="pa-3 d-flex flex-column"
                        >
                          <span class="text-caption font-medium mb-2">Workspace ID</span>
                          <span class="text-caption">Workspace ID digunakan untuk mengatur alamat URL unik dari workspace mu.</span>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-form>
                <span class="d-block text-caption mt-4">Icon Workspace</span>
                <v-card
                  width="80"
                  height="80"
                  outlined
                  class="mt-2 d-flex align-center justify-center"
                  @click="$refs.workspaceIconPicker.click()"
                >
                  <v-img
                    v-if="iconWorkspace"
                    :src="iconWorkspacePreview"
                    height="80"
                    width="80"
                    class="rounded"
                  />
                  <v-icon
                    v-else
                    class="text--disabled"
                    size="32"
                  >
                    {{ icons.mdiImage }}
                  </v-icon>
                </v-card>
                <span class="text-caption text--disabled mt-1">
                  Ukuran maksimum 2mb. <span
                    v-if="!isValidIconSize"
                    class="error--text"
                  >Ukuran melebihi batas!</span>
                </span>
                <input
                  ref="workspaceIconPicker"
                  type="file"
                  class="d-none"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event.target.files)"
                >
              </div>
            </XyzTransition>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="12"
            md="4"
          >
            <Lottie
              :options="{
                animationData: require('@/assets/lottie/breath.json'),
              }"
              class="welcome-lottie"
            />
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="d-flex align-end justify-end"
          >
            <XyzTransition
              appear
              xyz="fade bottom-5 delay-10"
              class="w-full"
            >
              <div class="d-flex flex-column">
                <span
                  v-if="!fromDashboard"
                  class="text-caption d-block mt-4"
                >
                  Terlogin sebagai {{ userData.first_name }} {{ userData.last_name }}.
                  <span
                    class="primary--text cursor-pointer"
                    @click="$store.dispatch('logout')"
                  >Logout?</span>
                </span>
                <div class="d-flex flex-column align-md-center flex-md-row">
                  <span
                    class="text-caption primary--text cursor-pointer"
                    @click="isModelHelpOpen = true"
                  >Apa itu workspace?</span>
                  <v-btn
                    color="primary"
                    class="mt-4 ml-md-auto"
                    :disabled="!name && !id"
                    :loading="loadingSubmit"
                    @click="submit"
                  >
                    Buat Workspace
                    <v-icon
                      class="ml-2"
                      size="20"
                    >
                      {{ icons.mdiArrowRight }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </XyzTransition>
          </v-col>
        </v-row>
      </v-card>
    </XyzTransition>

    <v-dialog
      v-model="isModelHelpOpen"
      max-width="400"
    >
      <v-card
        class="pa-4"
      >
        <h3>Apa itu Workspace?</h3>
        <span class="d-block mt-4">
          Workspace adalah Sebuah Tempat dimana anda bisa mengundang teman anda untuk berbagi token akun yang anda miliki,
          sehingga semua anggota workspace bisa menggunakan token tersebut untuk membuat sebuah workflow automasi.
        </span>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { ref, watch, computed } from '@vue/composition-api'
import { mdiArrowRight, mdiHelpCircleOutline, mdiImage } from '@mdi/js'
import { required, alphaDashValidator, maxLength } from '@core/utils/validation'
import { stringToSlug } from '@core/utils/filter'
import Lottie from 'vue-lottie'
import { useDebounceFn } from '@vueuse/core'
import { apolloClient } from '@/vue-apollo'
import { createWorkspace, identifierIDChecker } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

export default {
  components: {
    Lottie,
  },
  props: {
    fromDashboard: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const name = ref('')
    const id = ref('')
    const isModelHelpOpen = ref(false)
    const isIDApproved = ref(false)
    const isIDError = ref(false)
    const iconWorkspace = ref(null)
    const iconWorkspacePreview = ref('')
    const loadingSubmit = ref(false)
    const workspaceForm = ref(null)
    const defaultVisibility = ref(6)

    const userData = computed(() => store.state.auth.userData)

    watch(name, () => {
      id.value = stringToSlug(name.value)
    })

    const onFileChange = files => {
      if (files.length) {
        iconWorkspacePreview.value = URL.createObjectURL(files[0])

        // eslint-disable-next-line prefer-destructuring
        iconWorkspace.value = files[0]
      }
    }

    const clearForm = () => {
      name.value = ''
      id.value = ''
      iconWorkspace.value = null

      workspaceForm.value.resetValidation()
    }

    const isValidIconSize = computed(() => {
      if (!iconWorkspace.value) return true

      return iconWorkspace.value.size / 1000 <= 2048
    })

    const checkId = useDebounceFn(() => {
      isIDApproved.value = false
      isIDError.value = false
      apolloClient.mutate({
        mutation: identifierIDChecker,
        variables: {
          identifier_id: id.value,
        },
      }).then(result => {
        if (result.data.identifierIDChecker.status === 'success') {
          isIDApproved.value = true
          isIDError.value = false
        } else {
          isIDApproved.value = false
          isIDError.value = true
        }
      })
    }, 1000)

    watch(id, () => checkId())

    const submit = () => {
      if (workspaceForm.value.validate() && isValidIconSize.value) {
        loadingSubmit.value = true
        apolloClient.mutate({
          mutation: createWorkspace,
          variables: {
            name: name.value,
            identifier_id: id.value,
            photo: iconWorkspace.value,
            defaultVisibilityId_job: defaultVisibility.value,
            defaultVisibilityId_tasks: defaultVisibility.value,
            defaultVisibilityId_customer: defaultVisibility.value,
          },
        }).then(async () => {
          await store.dispatch('getProfileInfo')
          loadingSubmit.value = false
          Vue.notify({
            title: 'Berhasil',
            text: 'Berhasil membuat workspace!',
          })
          emit('success', id.value)
          clearForm()
        }).catch(err => {
          loadingSubmit.value = false
          errorHandling(err)
        })
      } else {
        Vue.notify({
          type: 'warn',
          title: 'Tidak Valid',
          text: 'Harap cek kembali data anda',
        })
      }
    }

    return {
      name,
      id,
      isModelHelpOpen,
      required,
      alphaDashValidator,
      maxLength,
      isIDApproved,
      isIDError,
      onFileChange,
      iconWorkspace,
      iconWorkspacePreview,
      loadingSubmit,
      submit,
      workspaceForm,
      userData,
      isValidIconSize,
      defaultVisibility,

      icons: {
        mdiArrowRight,
        mdiHelpCircleOutline,
        mdiImage,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-menu__content:not(.list-style) .v-list.v-list--dense .v-list-item {
  min-height: 3rem !important;
}
</style>
