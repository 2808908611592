import gql from 'graphql-tag'

const assignAgent = gql`
  mutation (
    $agent_id: Float!
    $room_id: Float!
    $workspace_id: Float!
  ) {
    assignAgent(
      agent_id: $agent_id
      room_id: $room_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default assignAgent
