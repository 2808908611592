import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"720"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c('div',{staticClass:"d-flex justify-space-between text-h5 mb-4 pa-4"},[_c('div',[_vm._v("Downgrade Plan")]),_c(VIcon,{on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,[(_vm.item)?_c('div',{staticClass:"d-flex justify-center align-center flex-column text-h6"},[_c('div',[_vm._v("Plan anda saat ini adalah "),_c('strong',[_vm._v(_vm._s(_vm.userData.subscription.name))])]),_c('div',{staticClass:"mt-1"},[_vm._v(" Berlaku hingga "+_vm._s(_vm.formatDate(_vm.userData.subscription_ends_at))+" ")]),_c('div',{staticClass:"mt-3"},[_vm._v(" Anda akan menurunkan plan tersebut menjadi "),_c('strong',[_vm._v(_vm._s(_vm.item.name))])]),_c('div',{staticClass:"text-caption font-weight-bold"},[_vm._v(" *Anda akan tetap mendapatkan benefit plan sebelumnya sampai masa berlaku plan tersebut habis* ")])]):_vm._e()]),_c(VCardActions,{staticClass:"mt-2"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","large":"","loading":_vm.loadingDowngradeUser,"disabled":_vm.loadingDowngradeUser},on:{"click":function($event){return _vm.downgradePlan(_vm.item.id)}}},[_vm._v(" Turunkan Plan ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }