import gql from 'graphql-tag'

const hierarchyTree = gql`
query hierarchyTree(
  $workspace_id: Float!
){
  hierarchyTree(
    workspace_id: $workspace_id
  ){
    ...recursiveData
  }
}
fragment detail on Hierarchies {
  id
  name
  description
  users{
    id
    phone
    email
    name
    first_name
    last_name
    photo
  }
  parent_id
  created_at
  updated_at
  deleted_at
}
fragment recursiveData on Hierarchies{
  ...detail
  children{
    ...detail
    children{
      ...detail
      children{
        ...detail
        children{
          ...detail
          children{
            ...detail
            children{
              ...detail
              children{
                ...detail
                children{
                  ...detail
                  children{
                    ...detail
                    children{
                      ...detail
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

`
export default hierarchyTree
