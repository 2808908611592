import gql from 'graphql-tag'

const getAccurateFobs = gql`
  query getAccurateFobs(
    $token_id: Float!
    $db_id: Float!
    $pagination: pagination
    $search: String!
  ) {
    getAccurateFobs(
      token_id: $token_id
      db_id: $db_id
      pagination: $pagination
      search: $search
    ) {
      id
      name
      optLock
    }
  }
`

export default getAccurateFobs
