import gql from 'graphql-tag'

const chatRooms = gql`
  query(
    $workspace_id: Float!
    $platform_id: [Float!]
    $filter: FilterChatRooms
    $search: String
    $pagination: pagination!
  ) {
    chatRooms(
      workspace_id: $workspace_id
      platform_id: $platform_id
      filter: $filter
      pagination: $pagination
      search: $search
    ) {
      count
      chatList {
        id
        third_party_room_id
        status {
          id
          name
        }
        agent {
          id
          phone
          email
          first_name
          last_name
          photo
        }
        profile {
          id
          user_id
          name
          photo
          type
          customer {
            id
            name
            description
            email
            phone_number
            whatsapp_number
          }
        }
        workspaceToken {
          id
          connection_name
          platform {
            id
            name
            logo
          }
        }
        lastChat {
          id
          message_id
          content
          type
          sender {
            id
            name
          }
          reply_to
          file_id
          caption
          created_at
          updated_at
        }
        unreadMsg
      }
    }
  }
`

export default chatRooms
