import gql from 'graphql-tag'

const updateJobPriorityUrutan = gql`
  mutation(
    $params: [paramUpdateUrutan!]!
    $workspace_id: Float!
  ) {
    updateJobPriorityUrutan(
      params: $params
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default updateJobPriorityUrutan
