import gql from 'graphql-tag'

const operatorsV2 = gql`
  query operatorsV2{
    operatorsV2{
      id
      value
      description
      data_type {
        id
        name
      }
    }
  }
`

export default operatorsV2
