<template>
  <v-card-text class="d-flex flex-column h-full">
    <h4 class="mb-1">
      Utama
    </h4>
    <v-form
      ref="userForm"
      lazy-validation
      class="d-flex flex-column"
    >
      <v-row>
        <v-col
          cols="12"
          md="auto"
        >
          <span class="text-caption mb-1">Foto Profil</span>
          <v-card
            width="110"
            height="110"
            outlined
            class="mt-1 d-flex align-center justify-center"
            @click="$refs.profilePhotoPicker.click()"
          >
            <v-img
              v-if="profileData.photoPreview"
              :src="profileData.photoPreview"
              height="110"
              width="110"
              class="rounded"
            />
            <v-icon
              v-else
              class="text--disabled"
              size="32"
            >
              {{ icons.mdiImage }}
            </v-icon>
          </v-card>
          <input
            ref="profilePhotoPicker"
            type="file"
            class="d-none"
            accept=".jpg, .jpeg, .png"
            @change="onFileChange($event.target.files)"
          >
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          <v-row dense>
            <v-col
              cols="12"
              md="6"
              class="pr-md-2"
            >
              <span class="text-caption mb-1">Nama awal</span>
              <v-text-field
                v-model="profileData.first_name"
                outlined
                placeholder="Input nama awal"
                hide-details="auto"
                dense
                :rules="[required]"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pl-md-2"
            >
              <span class="text-caption mb-1">Nama akhir</span>
              <v-text-field
                v-model="profileData.last_name"
                outlined
                placeholder="Input nama akhir"
                hide-details="auto"
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pr-md-2"
            >
              <span class="text-caption mb-1">Nomor HP</span>
              <v-text-field
                v-model="profileData.phone"
                outlined
                type="tel"
                placeholder="Input nomor HP"
                hide-details="auto"
                dense
                :rules="[phoneValidator]"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <span class="text-caption error--text mb-1">{{ errorPhoto }}</span>
      <div class="mt-6 ml-md-auto">
        <v-btn
          color="primary"
          :loading="loadingUpdate"
          @click="updateProfile"
        >
          Update Profil
        </v-btn>
      </div>
    </v-form>
    <v-divider class="my-6" />
    <h4 class="mb-1">
      Ubah Password
    </h4>
    <change-password />
  </v-card-text>
</template>

<script>
import Vue from 'vue'
import { ref, onMounted } from '@vue/composition-api'
import { mdiImage } from '@mdi/js'
import { required, phoneValidator } from '@core/utils/validation'
import { apolloClient } from '@/vue-apollo'
import { updateProfileInfo } from '@/graphql/mutations'
import ChangePassword from './ChangePassword.vue'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

export default {
  components: {
    ChangePassword,
  },
  setup() {
    const userForm = ref()
    const loadingUpdate = ref(false)
    const profileData = ref({
      first_name: '',
      last_name: '',
      phone: '',
      photo: null,
      photoPreview: '',
    })
    const errorPhoto = ref('')

    const onFileChange = files => {
      errorPhoto.value = ''

      // check for error photo
      if (files.length) {
        if (!files[0].type.includes('image')) errorPhoto.value = 'File tidak valid'
        if (files[0].size / 1000 > 2048) errorPhoto.value = 'Ukuran melebihi batas. Maksimum 2mb.'

        profileData.value.photoPreview = URL.createObjectURL(files[0])

        // eslint-disable-next-line prefer-destructuring
        profileData.value.photo = files[0]
      }
    }

    const updateProfile = () => {
      if (userForm.value.validate()) {
        loadingUpdate.value = true

        /* eslint-disable camelcase */
        const {
          first_name, last_name, phone, photo, photoPreview,
        } = profileData.value

        const variables = {
          phone, first_name, last_name,
        }

        // check if photo has been changed
        if (photoPreview !== store.getters.getUserData.photo) {
          variables.photo = photo
        }

        apolloClient.mutate({
          mutation: updateProfileInfo,
          variables,
        }).then(() => {
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil mengubah profil!',
          })
          loadingUpdate.value = false

          store.dispatch('getProfileInfo')
        }).catch(err => {
          loadingUpdate.value = false
          errorHandling(err)
        })
      }
    }

    onMounted(() => {
      const {
        first_name, last_name, phone, photo,
      } = store.getters.getUserData

      profileData.value = {
        first_name,
        last_name,
        phone,
        photoPreview: photo,
      }
    })

    return {
      userForm,
      loadingUpdate,
      profileData,
      errorPhoto,

      onFileChange,
      updateProfile,

      icons: {
        mdiImage,
      },

      required,
      phoneValidator,
    }
  },
}
</script>

<style>

</style>
