import gql from 'graphql-tag'

const permissions = gql`
  query getPermissions(
    $workspace_id: Float!
  ) {
    getPermissions(
      workspace_id: $workspace_id
    ) {
      create
      read
      update
      del
    }
  }
`
export default permissions
