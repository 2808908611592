<template>
  <div
    class="mt-2 me-4"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{on, attrs}">
        <v-chip
          large
          label
          v-bind="attrs"
          v-on="on"
        >
          <div
            class="d-flex flex-column text-caption font-weight-bold"
          >
            <span>{{ subscriptionData.name }}</span>
            <span v-if="subscriptionEndsAt">Sampai {{ formatDate(subscriptionEndsAt) }}</span>
            <span v-else>Tanpa Batas</span>
          </div>
        </v-chip>
      </template>
      <span>Perpanjang Otomatis: {{ isAutoSubscribe ? 'Aktif' : 'Nonaktif' }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import store from '@/store'
import { onMounted, ref } from '@vue/composition-api'
import { formatDate } from '@/@core/utils/filter'

export default {
  setup() {
    const isAutoSubscribe = ref(null)
    const subscriptionData = ref({})
    const subscriptionEndsAt = ref(null)
    const fetchProfileData = () => {
      const {
        // eslint-disable-next-line camelcase
        is_auto_subscribe, subscription, subscription_ends_at,
      } = store.getters.getUserData

      // eslint-disable-next-line camelcase
      isAutoSubscribe.value = is_auto_subscribe
      subscriptionData.value = subscription
      // eslint-disable-next-line camelcase
      subscriptionEndsAt.value = subscription_ends_at
      console.log(subscriptionEndsAt.value)
    }

    onMounted(() => {
      fetchProfileData()
    })

    return {
      isAutoSubscribe,
      subscriptionData,
      subscriptionEndsAt,
      formatDate,
      fetchProfileData,
    }
  },
}
</script>
