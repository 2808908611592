import gql from 'graphql-tag'

const updateProduct = gql`
  mutation(
    $reference: [paramReferenceProduct!]
    $name: String!
    $description: String
    $status: Float!
    $price: Float!
    $stock: Float!
    $id: Float!
    $workspace_id: Float!
  ) {
    updateProduct(
      reference: $reference
      name: $name,
      description: $description,
      status: $status
      price: $price
      stock: $stock
      id: $id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default updateProduct
