import { getField, setField } from 'vuex-use-fields'
import { apolloClient } from '@/vue-apollo'
import { roleList, workspaceMembers } from '@/graphql/queries'

export default {
  namespaced: true,
  state: {
    users: [],
    roles: [],
    loadingUsers: false,
    userFilter: {
      filterCount: 0,
      count: 0,
      pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
      },
      search: null,
      sort: {
        id: 6,
        label: 'Terakhir Terlihat',
        field: 'last_seen_at',
        type: 'desc',
      },
      role: [],
      headerSort: [],
      userStatus: [],
      availability: null,
    },
  },
  getters: {
    getField,
    getUsers: state => state.users,
    getLoadingUsers: state => state.loadingUsers,
    getRoles: state => state.roles,
    filterCount(state) {
      let filterCount = 0

      const filter = state.customerFilter

      // if (filter.city.length) {
      //   filterCount += 1
      // }
      if (filter.raw_filter.length) {
        filterCount += filter.custom_filter.length + filter.customer_type.length
      }

      return filterCount
    },
  },
  mutations: {
    setField,
    setUsers(state, data) {
      state.users = data
    },
    setLoadingUsers(state, data) {
      state.loadingUsers = data
    },
    setRoles(state, data) {
      state.roles = data
    },
  },
  actions: {
    /**
     * @method
     * @param {import('./definition/auth').IState} state
     * @returns {void}
     */
    getUsers(state) {
      state.commit('setLoadingUsers', true)
      apolloClient
        .query({
          query: workspaceMembers,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: state.rootGetters.getCurrentWorkspaceId,
            pagination: {
              limit: 1000000,
              offset: 0,
            },
          },
        })
        .then(result => {
          const memberData = result.data.workspaceMembers.members.map(value => ({ ...value.user, workspace: value.workspace, role: value.role }))
          state.commit('setLoadingUsers', false)
          state.commit('setUsers', memberData)
        })
    },
    getRoles(state) {
      apolloClient.query({
        query: roleList,
        variables: {
          workspace_id: state.rootGetters.getCurrentWorkspaceId,
        },
      }).then(result => {
        state.commit('setRoles', result.data.roleList)
      })
    },
  },
}
