<template>
  <v-card
    class="pa-4 mb-4 d-flex flex-column"
    outlined
    @click="isOpen = !isOpen"
  >
    <div class="d-flex align-start">
      <h4 class="mb-2">
        {{ command.name }}
      </h4>
      <v-icon
        class="ml-auto"
      >
        {{ isOpen ? icons.mdiChevronUp : icons.mdiChevronDown }}
      </v-icon>
    </div>
    <div class="d-flex flex-wrap w-full">
      <v-chip
        color="primary"
        small
        class="mr-1"
      >
        {{ command.token.connection_name }}
      </v-chip>
      <v-chip
        outlined
        color="primary"
        small
      >
        {{ command.type.name }}
      </v-chip>
      <v-btn
        v-if="isOpen"
        outlined
        color="warning"
        x-small
        class="ml-auto"
        @click.stop="$emit('update')"
      >
        Ubah
      </v-btn>
      <v-btn
        v-if="isOpen"
        outlined
        color="error"
        x-small
        class="ml-2"
        @click.stop="$emit('delete')"
      >
        Hapus
      </v-btn>
    </div>
    <div
      v-if="isOpen"
      class="mt-4"
    >
      <v-row
        dense
        class="match-height"
      >
        <v-col
          cols="12"
        >
          <v-card
            outlined
            class="pa-3 d-flex flex-wrap align-center justify-space-between"
          >
            <span class="text-caption text--disabled">Command</span>
            <span class="text-subtitle-2 primary--text font-medium">{{ command.command }}</span>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            outlined
            class="pa-3 d-flex flex-column"
          >
            <span class="text-caption text--disabled mb-1">Deskripsi</span>
            <span class="text-subtitle-2">
              {{ command.description }}
            </span>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            outlined
            class="pa-3 d-flex flex-column"
          >
            <span class="text-caption text--disabled mb-1">Respon</span>
            <span class="text-subtitle-2">
              {{ command.respond }}
            </span>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'

export default {
  props: {
    command: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const isOpen = ref(false)

    return {
      isOpen,

      icons: {
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style>

</style>
