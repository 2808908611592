import gql from 'graphql-tag'

const deleteJobPriority = gql`
  mutation(
  $workspace_id: Float!
  $delete_id: [Float!]!
  ) {
    deleteJobPriority(
    workspace_id: $workspace_id
    delete_id: $delete_id
    ) {
      status
      msg
      data
    }
  }
`

export default deleteJobPriority
