import gql from 'graphql-tag'

const addTag = gql`
  mutation addTag(
    $color: String!
    $name: String!
    $workspace_id: Float!
  ) {
    addTag(
      color: $color
      name: $name
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default addTag
