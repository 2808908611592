import gql from 'graphql-tag'

const addThread = gql`
  mutation (
    $channelId: Float!
    $html_comment: String!
    $is_pinned: Boolean!
  ) {
    addThread (
      channelId: $channelId
      html_comment: $html_comment
      is_pinned: $is_pinned
    ) {
      status
      msg
      data
    }
  }
`

export default addThread
