import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import {
  addUserCard,
  changeAutoSubscribe,
  deleteUser, deleteUserCard, downgradePlanSubscription, resetPassword, toggleBanUser, verifyAccount,
} from '@/graphql/mutations'
import { ref } from '@vue/composition-api'
import {
  getAllUsers, getUserByForgotPasswordCode, getUserByVerificationCode, getUserDetail, getUserTransactionList, getUserWorkflowList, getUserWorkspaceList, userCards,
} from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'

const useUser = () => {
  const userDetail = ref({})
  const userList = ref([])
  const userCardList = ref([])
  const userCount = ref(0)

  const userWorkspaceList = ref([])
  const userWorkspaceCount = ref(0)

  const userWorkflowList = ref([])
  const userWorkflowCount = ref(0)

  const userTransactionList = ref([])
  const userTransactionCount = ref(0)

  const loadingListUser = ref(false)
  const loadingDetailUser = ref(false)
  const loadingVerifyUser = ref(false)
  const loadingResetPasswordUser = ref(false)
  const loadingChangeBanUser = ref(false)
  const loadingDowngradeUser = ref(false)
  const loadingCardUser = ref(false)
  const loadingDeleteCardUser = ref(false)
  const loadingAddCardUser = ref(false)
  const loadingChangeAutoSubscribe = ref(false)
  const loadingUserWorkspaceList = ref(false)
  const loadingUserWorkflowList = ref(false)
  const loadingUserTransactionList = ref(false)

  const userStatusColor = id => {
    if (id === 1) return 'error'
    if (id === 2) return 'primary'

    return 'secondary'
  }

  const delUser = id => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: 'Hapus pengguna?',
      body: 'Konfirmasi jika anda ingin menghapus pengguna.',
    }).then(confirm => {
      if (confirm) {
        apolloClient.mutate({
          mutation: deleteUser,
          variables: {
            id,
          },
        }).then(result => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menghapus pengguna!',
          })
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      } else {
        reject()
      }
    })
  })

  const fetchAllUsers = (pagination, search, filter) => {
    loadingListUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getAllUsers,
        variables: {
          pagination,
          search,
          filter,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loadingListUser.value = false
        userList.value = result.data.getAllUsers.users
        userCount.value = result.data.getAllUsers.count
        resolve(result.data.getUsers)
      }).catch(err => {
        loadingListUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const changeBanUser = (id, value) => {
    loadingChangeBanUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: toggleBanUser,
        variables: {
          user_id: id,
          value,
        },
      }).then(result => {
        loadingChangeBanUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah status pengguna!',
        })
        resolve(result)
      }).catch(err => {
        loadingChangeBanUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchUserByVerificationCode = verificationCode => {
    loadingDetailUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getUserByVerificationCode,
        variables: {
          verification_code: verificationCode,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingDetailUser.value = false
        userDetail.value = data.getUserByVerificationCode
        resolve(data.getUserByVerificationCode)
      }).catch(err => {
        loadingDetailUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchUserDetail = id => {
    loadingDetailUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getUserDetail,
        variables: {
          user_id: id,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingDetailUser.value = false
        userDetail.value = data.getUserDetail
        resolve(data.getUserDetail)
      }).catch(err => {
        loadingDetailUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const verifyUser = (verificationCode, password, confirmPassword) => {
    loadingVerifyUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: verifyAccount,
        variables: {
          verification_code: verificationCode,
          password,
          confirm_password: confirmPassword,
        },
      }).then(result => {
        loadingVerifyUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil memverifikasi akun!',
        })
        resolve(result)
      }).catch(err => {
        loadingVerifyUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const downgradeUserPlan = subscriptionId => {
    loadingDowngradeUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: downgradePlanSubscription,
        variables: {
          subscription_id: subscriptionId,
        },
      }).then(result => {
        loadingDowngradeUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil downgrade akun!',
        })
        resolve(result)
      }).catch(err => {
        loadingDowngradeUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const getUserCards = () => {
    loadingCardUser.value = true
    console.log('tes')

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: userCards,
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingCardUser.value = false
        userCardList.value = data.userCards
        console.log(userCardList.value)
        resolve(data.userCards)
      }).catch(err => {
        loadingCardUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const deleteCard = cardId => {
    loadingDeleteCardUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: deleteUserCard,
        variables: {
          card_id: cardId,
        },
      }).then(result => {
        loadingDeleteCardUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil menghapus data kartu!',
        })
        resolve(result)
      }).catch(err => {
        loadingDeleteCardUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const addCardData = cardData => {
    loadingAddCardUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: addUserCard,
        variables: {
          param: cardData,
        },
      }).then(result => {
        loadingAddCardUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil menambahkan data kartu!',
        })
        resolve(result)
      }).catch(err => {
        loadingAddCardUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const updateAutoSubscribe = value => {
    loadingChangeAutoSubscribe.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: changeAutoSubscribe,
        variables: {
          value,
        },
      }).then(result => {
        loadingChangeAutoSubscribe.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah status auto subscribe!',
        })
        resolve(result)
      }).catch(err => {
        loadingChangeAutoSubscribe.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchUserByForgotPasswordCode = forgotPasswordCode => {
    loadingDetailUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getUserByForgotPasswordCode,
        variables: {
          forgot_password_code: forgotPasswordCode,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingDetailUser.value = false
        userDetail.value = data.getUserByForgotPasswordCode
        resolve(data.getUserByForgotPasswordCode)
      }).catch(err => {
        loadingDetailUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const mutationResetPassword = (forgotPasswordCode, newPassword, confirmPassword) => {
    loadingResetPasswordUser.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: resetPassword,
        variables: {
          forgot_password_code: forgotPasswordCode,
          new_password: newPassword,
          confirm_password: confirmPassword,
        },
      }).then(result => {
        loadingResetPasswordUser.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mereset password!',
        })
        resolve(result)
      }).catch(err => {
        loadingResetPasswordUser.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchUserWorkspaceList = userId => {
    loadingUserWorkspaceList.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getUserWorkspaceList,
        variables: {
          user_id: userId,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingUserWorkspaceList.value = false
        userWorkspaceList.value = data.getUserWorkspaceList.workspaces
        userWorkspaceCount.value = data.getUserWorkspaceList.count
        resolve(data.getUserWorkspaceList)
      }).catch(err => {
        loadingUserWorkspaceList.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchUserWorkflowList = (pagination, userId, filter) => {
    loadingUserWorkflowList.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getUserWorkflowList,
        variables: {
          user_id: userId,
          pagination,
          filter,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingUserWorkflowList.value = false
        userWorkflowList.value = data.getUserWorkflowList.workflows
        userWorkflowCount.value = data.getUserWorkflowList.count
        resolve(data.getUserWorkflowList)
      }).catch(err => {
        loadingUserWorkflowList.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchUserTransactionList = (pagination, filter) => {
    loadingUserTransactionList.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getUserTransactionList,
        variables: {
          pagination,
          filter,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        loadingUserTransactionList.value = false
        userTransactionList.value = data.getUserTransactionList.transactions
        userTransactionCount.value = data.getUserTransactionList.count
        resolve(data.getUserTransactionList)
      }).catch(err => {
        loadingUserTransactionList.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  return {
    userStatusColor,
    delUser,

    fetchAllUsers,
    userList,
    userCount,
    loadingListUser,
    changeBanUser,
    loadingChangeBanUser,

    verifyUser,
    loadingVerifyUser,

    fetchUserByVerificationCode,
    loadingDetailUser,
    userDetail,

    downgradeUserPlan,
    loadingDowngradeUser,

    getUserCards,
    userCardList,
    loadingCardUser,

    deleteCard,
    loadingDeleteCardUser,

    addCardData,
    loadingAddCardUser,

    updateAutoSubscribe,
    loadingChangeAutoSubscribe,

    fetchUserDetail,

    fetchUserByForgotPasswordCode,

    mutationResetPassword,
    loadingResetPasswordUser,

    fetchUserWorkspaceList,
    userWorkspaceList,
    userWorkspaceCount,
    loadingUserWorkspaceList,

    fetchUserWorkflowList,
    userWorkflowList,
    userWorkflowCount,
    loadingUserWorkflowList,

    fetchUserTransactionList,
    userTransactionList,
    userTransactionCount,
    loadingUserTransactionList,
  }
}

export default useUser
