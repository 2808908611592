<template>
  <div>
    <component
      :is="resolveLayoutVariant"
      :class="`skin-variant--${appSkinVariant}`"
    >
      <transition
        v-if="$route"
        :name="appRouteTransition"
        mode="out-in"
        appear
      >
        <router-view
          :key="$route.fullPath"
        />
      </transition>
    </component>

    <notifications
      position="bottom right"
      :duration="5000"
    />
    <v-overlay
      :value="globalLoading"
      :opacity="0.1"
    >
      <div class="d-flex align-center h-full pa-16 ms-16 pe-0">
        <Lottie
          :options="{
            animationData: catloading,
          }"
          style="max-width: 800px; margin: 0"
        />
      </div>
    </v-overlay>
    <global-dialog />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import Lottie from 'vue-lottie'
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutAdmin from '@/layouts/variants/admin/LayoutAdmin.vue'
import store from '@/store'
import catloading from '@/assets/lottie/cat_loading.json'
import GlobalDialog from '@/components/misc/GlobalDialog.vue'

// Dynamic vh

export default {
  components: {
    Lottie,
    GlobalDialog,
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    LayoutAdmin,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()
    const globalLoading = computed(() => store.state.auth.globalLoading)

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      console.log('route', route.value.name)
      if (!route.value.name) return 'layout-blank'
      if (route.value && route.value.meta && route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value && route.value.meta && route.value.meta.layout === 'admin') return 'layout-admin'

      return `layout-content-${appContentLayoutNav.value}-nav`
    })

    useDynamicVh()

    return {
      globalLoading,
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      catloading,
    }
  },
}
</script>
