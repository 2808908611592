import gql from 'graphql-tag'

const proceedJobStatus = gql`
  mutation proceedJobStatus (
    $status_id: Float!
    $id: Float!
    $workspace_id: Float!
  ) {
    proceedJobStatus (
      status_id: $status_id
      workspace_id: $workspace_id
      id: $id
    ) {
      status
      msg
      data
    }
  }
`

export default proceedJobStatus
