import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c('div',{staticClass:"d-flex justify-space-between text-h5 mb-4 pa-4"},[_c('div',[_vm._v("Pilih Durasi Plan")]),_c(VIcon,{on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,[(_vm.item)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"d-flex justify-center align-center flex-column text-h6",class:{
              'plan-duration-card-active': _vm.selectedInterval === 1,
            },attrs:{"outlined":"","height":"120px"},on:{"click":function($event){_vm.selectedInterval = 1}}},[_c('div',{staticClass:"font-weight-bold mb-3"},[_vm._v(" Bulanan ")]),_c('div',[_vm._v(_vm._s(_vm.formatCurrency(_vm.item.price_month)))])])],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"d-flex justify-center align-center flex-column text-h6",class:{
              'plan-duration-card-active': _vm.selectedInterval === 12,
            },attrs:{"outlined":"","height":"120px"},on:{"click":function($event){_vm.selectedInterval = 12}}},[_c('div',{staticClass:"font-weight-bold mb-3"},[_vm._v(" Tahunan ")]),_c('div',[_vm._v(_vm._s(_vm.formatCurrency(_vm.item.price_year)))])])],1)],1):_vm._e()],1),_c(VCardActions,{staticClass:"mt-2"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.addSubsTransaction(_vm.item, _vm.selectedInterval)}}},[_vm._v(" Tingkatkan Plan ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }