import gql from 'graphql-tag'

const archiveCustomer = gql`
mutation archiveCustomer(
  $is_archive: Boolean!
  $id: Float!
  $workspace_id: Float!
){
  archiveCustomer(
    is_archive: $is_archive
    id: $id
    workspace_id: $workspace_id
  ){
    status 
    msg
    data
  }
}
`
export default archiveCustomer
