import gql from 'graphql-tag'

const confirmUpdateDeleteCustomAttribute = gql`
  mutation confirmUpdateDeleteCustomAttribute (
    $delete_id: [Float!]
    $object_type_id: Float!
    $job_type_id: Float
    $update: [CustomAttributeUpdate!]!
    $workspace_id: Float!
  ) {
    confirmUpdateDeleteCustomAttribute (
      delete_id: $delete_id
      object_type_id: $object_type_id
      job_type_id: $job_type_id
      update: $update
      workspace_id: $workspace_id
    ) {
    status
    msg
    data
  }
}
`

export default confirmUpdateDeleteCustomAttribute
