import gql from 'graphql-tag'

const threadSearch = gql`
  query (
    $channel_id: Float!
    $search: String!
  ) {
    threadSearch(
      channel_id: $channel_id
      search: $search
    ) {
      count
      threads {
        id
        html_comment
        strip_comment
        is_pinned
        channel {
          id
          name
          comment_permission
          channel_notification
          is_pinned
          archived_at
          last_activity_at
          deleted_at
        }
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
        posts {
          id
          html_comment
          strip_comment
          user {
            id
            name
            phone
            photo
            email
          }
          created_at
          updated_at
          deleted_at
        }
        archived_at
        last_activity_at
        created_at
        updated_at
        deleted_at
      }
      posts {
        id
        html_comment
        strip_comment
        user {
          id
          name
          phone
          photo
          email
        }
        thread {
          id
          html_comment
        }
      }
    }
  }
`

export default threadSearch
