import gql from 'graphql-tag'

const identifierIDChecker = gql`
  mutation(
    $identifier_id: String!
  ) {
    identifierIDChecker(
      identifier_id: $identifier_id
    ) {
      status
      msg
      data
    }
  }
`

export default identifierIDChecker
