import gql from 'graphql-tag'

const shopeeAuthorizationLink = gql`
  query shopeeAuthorizationLink(
    $workspace_id: Float!
  ) {
    shopeeAuthorizationLink (
      workspace_id: $workspace_id
    )
  }
`

export default shopeeAuthorizationLink
