import gql from 'graphql-tag'

const updateUserSetting = gql`
  mutation (
    $notify_task_assignment: Boolean,
    $notify_comment: Boolean,
    $notify_job_assignment: Boolean,
  ) {
    updateUserSetting (
      notify_task_assignment: $notify_task_assignment,
      notify_comment: $notify_comment,
      notify_job_assignment: $notify_job_assignment,
    ) {
      status
      msg
      data
    }
  }
`

export default updateUserSetting
