import gql from 'graphql-tag'

const updateHierarchy = gql`
mutation updateHierarchy(
$name: String!
$description: String
$userId: [Int!]!
$parent_id: Float
$id: Float!
$workspace_id: Float!
){
  updateHierarchy(
    name: $name
    description: $description
    userId: $userId
    parent_id: $parent_id
    id: $id
    workspace_id: $workspace_id
  ){
    status
    msg
    data
  }
}

`
export default updateHierarchy
