<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      flat
      class="d-flex flex-column"
      multiple
    >
      <v-expansion-panel
        v-show="!state.loadingSharedJobType.value"
        class="v-expansion-panel-folder"
      >
        <v-expansion-panel-header
          class="pa-0 ps-6"
          expand-icon=""
        >
          <div class="d-flex align-center">
            <v-icon>
              {{ icons.mdiFolderTable }}
            </v-icon>
            <span
              v-if="!menuIsVerticalNavMini || isHovered"
              class="ms-2"
            >
              Shared Job Types
            </span>
          </div>
          <template v-slot:actions>
            <v-icon v-if="!menuIsVerticalNavMini || isHovered">
              {{ icons.mdiMenuDown }}
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list-item-group
            v-for="(jobTypes, index) in state.sharedJobTypeList.value"
            :key="jobTypes.id"
          >
            <div>
              <v-list-item
                v-bind="sharedJobTypesLinkProps[index]"
                class="vertical-nav-menu-link pe-0 ps-6"
                active-class="bg-gradient-primary"
              >
                <v-list-item-icon class="me-2">
                  <v-badge
                    color="#AF4126"
                    content="0"
                    overlap
                  >
                    <v-icon
                      :color="jobTypes.color"
                      size="22"
                    >
                      {{ icons.mdiFolderTable }}
                    </v-icon>
                  </v-badge>
                </v-list-item-icon>

                <v-list-item-title class="text-caption">
                  {{ jobTypes.name }}
                </v-list-item-title>
                <v-list-item-action

                  class="my-0 me-2 justify-end"
                >
                  <div class="d-flex">
                    <v-icon
                      v-if="!jobTypes.is_public"
                      size="18"
                      class="ms-1"
                    >
                      {{ icons.mdiLockOutline }}
                    </v-icon>
                    <v-icon
                      v-if="jobTypes.archived_at"
                      size="18"
                      class="ms-1"
                    >
                      {{ icons.mdiArchive }}
                    </v-icon>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <template
        v-if="state.loadingSharedJobType.value"
      >
        <div
          class="text-center mt-5"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
      </template>
    </v-expansion-panels>
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { inject, onMounted, ref } from '@vue/composition-api'
import {
  mdiPlus, mdiMagnify, mdiFolder, mdiArchive, mdiLockOutline, mdiFolderTable, mdiMenuDown,
} from '@mdi/js'
import useFolder from '@/composables/useFolder'
import { createFieldMapper } from 'vuex-use-fields'
import useAppConfig from '@core/@app-config/useAppConfig'

const useFieldJobType = createFieldMapper({ getter: 'jobType/getField', setter: 'jobType/setField' })

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = {
      ...useFieldJobType([
        'sharedJobTypeList', 'loadingSharedJobType', 'filterSharedJobType',
      ]),
    }

    const panel = ref([0])

    const { menuIsVerticalNavMini } = useAppConfig()
    const isHovered = inject('isHovered')
    const { t } = useUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()
    const changeHover = val => {
      emit('onChangeMenuHover', val)
    }
    const {
      fetchSharedJobTypes,
    } = useFolder()

    const sharedJobTypesLinkProps = ref([])

    const getSharedJobTypes = () => {
      fetchSharedJobTypes({
        search: state.filterSharedJobType.value.search,
        sort: state.filterSharedJobType.value.sort.value,
        sortType: state.filterSharedJobType.value.sort.sortType,
        show_archived: state.filterSharedJobType.value.show_archived.value,
        my_jobType: state.filterSharedJobType.value.my_jobType.value,
      }).then(val => {
        sharedJobTypesLinkProps.value = val.map(jobType => ({
          to: {
            name: 'jobtype-detail',
            params: { id: jobType.id },
          },
        }))
      })
    }

    onMounted(() => {
      getSharedJobTypes()
    })

    return {
      t,
      canViewVerticalNavMenuLink,

      icons: {
        mdiPlus,
        mdiMagnify,
        mdiFolder,
        mdiArchive,
        mdiLockOutline,
        mdiFolderTable,
        mdiMenuDown,
      },

      getSharedJobTypes,
      sharedJobTypesLinkProps,
      changeHover,
      state,
      isHovered,
      menuIsVerticalNavMini,
      panel,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}

.v-expansion-panel-folder::before {
  box-shadow: none !important;
}

.v-application.theme--dark .v-expansion-panel.v-expansion-panel--active {
  box-shadow: none !important;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
  box-shadow: none !important;
}

.v-expansion-panel-folder {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>
