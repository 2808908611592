import gql from 'graphql-tag'

const actionTriggerPropertyV2 = gql`
  query actionTriggerPropertyV2(
    $action_id: Float!
  ) {
    actionTriggerPropertyV2(
      action_id: $action_id
    ) {
      id
      name
      identifier
      data_type{
        id
        name
        name_view
        category_name
      }
      trigger_category {
        id
        name
        name_view
      }
      options {
        id
        name
        value
      }
    }
  }
`

export default actionTriggerPropertyV2
