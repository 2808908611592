import gql from 'graphql-tag'

const cancelTransaction = gql`
  mutation cancelTransaction(
    $order_id: String!
  ) {
    cancelTransaction(
      order_id: $order_id
    ) {
      msg
      status
    }
  }
`

export default cancelTransaction
