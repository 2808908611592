import gql from 'graphql-tag'

const chatGlobalSearch = gql`
  query chatGlobalSearch(
    $search: String!
    $workspace_id: Float!
  ) {
    chatGlobalSearch(
      search: $search
      workspace_id: $workspace_id
    ) {
      rooms {
        id
      }
      chats {
        id
      }
    }
  }
`

export default chatGlobalSearch
