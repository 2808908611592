<template>
  <v-card-text class="d-flex flex-column h-full">
    <v-data-table
      :headers="headers"
      :items="transactionList"
      :items-per-page="10"
      item-key="id"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Jumlah data',
      }"
      :server-items-length=" transactionCount "
      mobile-breakpoint="0"
      disable-sort
      height="620px"
      fixed-header
      disable-filtering
      :loading="loadingTransactions"
      :options.sync="state.pagination.value"
      @update:page="loadTransactions"
      @update:items-per-page="loadTransactions"
    >
      <template v-slot:[`item.midtrans_order_id`]="props">
        <div class="d-flex py-2">
          {{ props.item.midtrans_order_id }}
        </div>
      </template>
      <template v-slot:[`item.status`]="props">
        <div class="d-flex py-2">
          <v-chip
            :color="props.item.status.color"
            outlined
            class="text-capitalize"
          >
            {{ props.item.status.name }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.payment_type`]="props">
        <div class="d-flex py-2">
          <v-chip
            v-if="props.item.payment_type"
            :color="props.item.payment_type.color"
            outlined
            class="text-uppercase"
          >
            {{ props.item.payment_type ? props.item.payment_type.name : '' }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.created_at`]="props">
        <div class="d-flex py-2">
          {{ formatDate(props.item.created_at, { year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }) }}
        </div>
      </template>
      <template v-slot:[`item.gross_amount`]="props">
        <div class="d-flex py-2">
          {{ formatCurrency(props.item.gross_amount) }}
        </div>
      </template>
      <!-- <template v-slot:[`item.action`]="props">
        <div class="d-flex py-2">
          <v-btn
            v-if="props.item.status.name === 'pending'"
            x-small
            color="primary"
            class="mr-2"
            @click="payTransaction(props.item.token)"
          >
            Bayar
          </v-btn>
          <v-btn
            v-if="props.item.status.name === 'pending'"
            x-small
            color="error"
            @click="confirmCancelTransaction(props.item.midtrans_order_id)"
          >
            Batalkan
          </v-btn>
        </div>
      </template> -->
    </v-data-table>
  </v-card-text>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import useProfile from '@/composables/useProfile'
import { formatDate, formatCurrency } from '@core/utils/filter'
import Vue from 'vue'
import { createFieldMapper } from 'vuex-use-fields'

const useFieldTransaction = createFieldMapper({ getter: 'transaction/getField', setter: 'transaction/setField' })

export default {
  setup() {
    const state = {
      ...useFieldTransaction(['pagination']),
    }
    const paginationData = computed(() => ({
      limit: state.pagination.value.itemsPerPage,
      offset: (state.pagination.value.page - 1) * state.pagination.value.itemsPerPage,
    }))

    const { fetchTransactions, loadingTransactions, cancelTransactionFunc } = useProfile()
    const transactionList = ref([])
    const transactionCount = ref(0)
    const { snap } = window
    const resolveStatusColor = status => {
      if (status === 'pending') return 'secondary'
      if (status === 'success') return 'success'
      if (status === 'failure') return 'error'
      if (status === 'challenge') return 'warning'

      return ''
    }

    const headers = [
      {
        text: 'ID',
        value: 'midtrans_order_id',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Metode Pembayaran',
        value: 'payment_type',
      },
      {
        text: 'Tanggal transaksi',
        value: 'created_at',
      },
      {
        text: 'Jumlah',
        value: 'gross_amount',
      },
      // {
      //   text: 'Aksi',
      //   value: 'action',
      // },
    ]

    const resolvePaymentColor = type => {
      if (type === 'credit_card') return 'primary'
      if (type === 'gopay') return 'primary'
      if (type === 'qris') return 'primary'
      if (type === 'shopeepay') return 'primary'
      if (type === 'bank_transfer') return 'primary'
      if (type === 'echannel') return 'primary'
      if (type === 'bca_klikpay') return 'primary'
      if (type === 'bca_klikbca') return 'primary'
      if (type === 'cimb_clicks') return 'primary'
      if (type === 'danamon_online') return 'primary'
      if (type === 'cstore') return 'primary'
      if (type === 'akulaku') return 'primary'
      if (type === 'bri_epay') return 'primary'

      return ''
    }

    const payTransaction = token => {
      snap.pay(token, {
        onSuccess: result => {
          console.log('success', result)
        },
        onPending: result => {
          console.log('pending', result)
        },
        onError: result => {
          console.log('error', result)
        },
      })
    }

    const loadTransactions = () => {
      fetchTransactions(paginationData.value).then(res => {
        console.log(res)
        transactionCount.value = res.count
        transactionList.value = res.transactions.map(el => ({
          ...el,
          status: {
            ...el.status,
            color: resolveStatusColor(el.status.name),
          },
          payment_type: {
            ...el.payment_type,
            color: resolvePaymentColor(el.payment_type?.name),
          },
        }))
      })
    }

    const confirmCancelTransaction = token => {
      Vue.$dialog({
        title: 'Batalkan Transaksi?',
        body: 'Yakin ingin membatalkan transaksi?',
      }).then(result => {
        console.log(result)
        if (result) {
          console.log('tesss')
          cancelTransactionFunc(token).then(res => {
            console.log(res)
            loadTransactions()
            console.log('tes')
          })
        }
      })
    }

    onMounted(() => {
      loadTransactions()
    })

    return {
      loadingTransactions,
      transactionList,
      transactionCount,
      formatDate,
      payTransaction,
      formatCurrency,
      confirmCancelTransaction,
      headers,
      state,
      loadTransactions,
    }
  },
}
</script>
<style lang="scss">
@import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

.v-data-table__wrapper {
  @include style-scroll-bar();
}
</style>
