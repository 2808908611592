import gql from 'graphql-tag'

const updateUser = gql`
  mutation(
    $id: Float!
    $status_id: Float!
    $phone: String
  ) {
    updateUser(
      id: $id
      status_id: $status_id
      phone: $phone
    ) {
      status
      msg
      data
    }
  }
`

export default updateUser
