/* eslint-disable no-nested-ternary */
import { ref } from '@vue/composition-api'
import { apolloClient } from '@/vue-apollo'
import {
  automationObjectsV2, getWorkflowDetail, objectTriggerActionsV2, objectPropertiesV2, operatorsV2, workflowsV2, objectExecutorActionsV2, getWorkflowLog, getWorkflowDashboardStatus, getWorkflowUserDashboardUsage, getWorkflowDetailAdmin, getWorkflowAdminDashboardUsage, getWorkflowDashboardStatusAdmin, getTransactionDashboard,
} from '@/graphql/queries'
import {
  createWorkflow as addWorkflow, toggleStatusWorkflow, deleteWorkflow as removeWorkflow, editWorkflow,
} from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import Vue from 'vue'
import store from '@/store'
import actionTriggerPropertyV2 from '@/graphql/query/actionTriggerPropertiesV2'

const useAutomationV2 = () => {
  const objectList = ref([])
  const actionList = ref([])
  const workflowList = ref([])
  const workflowCount = ref(0)
  const workflowLogList = ref([])
  const propertyList = ref([])
  const triggerPropertyList = ref([])
  const operatorList = ref([])
  const dashboardWorkflowList = ref([])
  const workflowDetail = ref({})
  const dashboardUserWorkflowUsage = ref({})
  const dashboardAdminWorkflowUsage = ref({})
  const dashboardAdminTransaction = ref({})
  const loadingObject = ref(false)
  const loadingAction = ref(false)
  const loadingProperty = ref(false)
  const loadingTriggerProperty = ref(false)
  const loadingOperator = ref(false)
  const loadingSubmitWorkflow = ref(false)
  const loadingWorkflowList = ref(false)
  const loadingDetailWorkflow = ref(false)
  const loadingStatusWorkflow = ref(false)
  const loadingLogWorkflow = ref(false)
  const loadingDashboardWorkflow = ref(false)
  const loadingDashboardTransaction = ref(false)
  const loadingDashboardWorkflowUsage = ref(false)

  const workflowLogCount = ref(0)

  const fetchAutomationObjectsV2 = async () => {
    loadingObject.value = true
    await apolloClient.query({
      query: automationObjectsV2,
      fetchPolicy: 'no-cache',
    }).then(result => {
      loadingObject.value = false
      objectList.value = result.data.automationObjectsV2
    }).catch(err => {
      loadingObject.value = false

      errorHandling(err)
    })
  }

  const fetchTriggerActionsV2 = async objectId => {
    loadingAction.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: objectTriggerActionsV2,
        fetchPolicy: 'no-cache',
        variables: {
          object_id: objectId,
        },
      }).then(result => {
        loadingAction.value = false
        actionList.value = result.data.objectTriggerActionsV2
        resolve(result.data.objectTriggerActionsV2)
      }).catch(err => {
        loadingAction.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchExecutorActionsV2 = async (objectId, triggerCategoryId) => new Promise((resolve, reject) => {
    apolloClient.query({
      query: objectExecutorActionsV2,
      fetchPolicy: 'no-cache',
      variables: {
        trigger_category_id: triggerCategoryId,
        object_id: objectId,
      },
    }).then(result => {
      resolve(result.data.objectExecutorActionsV2)
    }).catch(err => {
      reject(err)
      errorHandling(err)
    })
  })

  const fetchObjectPropertiesV2 = async () => {
    loadingProperty.value = true
    await apolloClient.query({
      query: objectPropertiesV2,
      fetchPolicy: 'no-cache',
    }).then(result => {
      loadingProperty.value = false
      propertyList.value = result.data.objectPropertiesV2
    }).catch(err => {
      loadingProperty.value = false
      errorHandling(err)
    })
  }

  const fetchTriggerPropertiesV2 = async actionId => {
    loadingTriggerProperty.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: actionTriggerPropertyV2,
        fetchPolicy: 'no-cache',
        variables: {
          action_id: actionId,
        },
      }).then(result => {
        loadingTriggerProperty.value = false
        triggerPropertyList.value = result.data.actionTriggerPropertyV2
        resolve(result.data.actionTriggerPropertyV2)
      }).catch(err => {
        loadingTriggerProperty.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const fetchWorkflowListV2 = async pagination => {
    loadingWorkflowList.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: workflowsV2,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          pagination,
        },
      }).then(result => {
        loadingWorkflowList.value = false
        workflowList.value = result.data.workflowList.triggers
        workflowCount.value = result.data.workflowList.count
        resolve(result.data.workflowList)
      }).catch(err => {
        loadingWorkflowList.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchWorkflowDetailV2 = async workflowId => {
    loadingDetailWorkflow.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getWorkflowDetail,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          workflow_id: workflowId,
        },
      }).then(({ data }) => {
        loadingDetailWorkflow.value = false
        workflowDetail.value = data.workflowDetail
        resolve(data.workflowDetail)
      }).catch(err => {
        loadingDetailWorkflow.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchWorkflowDetailAdmin = async workflowId => {
    loadingDetailWorkflow.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getWorkflowDetailAdmin,
        fetchPolicy: 'no-cache',
        variables: {
          workflow_id: workflowId,
        },
      }).then(({ data }) => {
        loadingDetailWorkflow.value = false
        console.log(data)
        workflowDetail.value = data.workflowDetailAdmin
        resolve(data.workflowDetailAdmin)
      }).catch(err => {
        loadingDetailWorkflow.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchWorkflowLog = async (logPagination, filter, isGlobal = false) => {
    loadingLogWorkflow.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getWorkflowLog,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: isGlobal ? null : store.getters.getCurrentWorkspaceId,
          pagination: logPagination,
          filter,
        },
      }).then(({ data }) => {
        loadingLogWorkflow.value = false
        workflowLogList.value = data.getWorkflowLog.logs
        workflowLogCount.value = data.getWorkflowLog.count
        resolve(data.getWorkflowLog)
      }).catch(err => {
        loadingLogWorkflow.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchOperatorsV2 = async () => {
    loadingOperator.value = true
    await apolloClient.query({
      query: operatorsV2,
      fetchPolicy: 'no-cache',
    }).then(result => {
      loadingOperator.value = false
      operatorList.value = result.data.operatorsV2
    }).catch(err => {
      loadingOperator.value = false
      errorHandling(err)
    })
  }

  const createWorkflow = async payload => {
    loadingSubmitWorkflow.value = true
    await apolloClient.query({
      query: addWorkflow,
      fetchPolicy: 'no-cache',
      variables: payload,
    }).then(() => {
      loadingSubmitWorkflow.value = false
      Vue.notify({
        title: 'Sukses!',
        text: 'Berhasil membuat workflow baru!',
      })
    }).catch(err => {
      loadingSubmitWorkflow.value = false
      errorHandling(err)
    })
  }

  const updateWorkflow = async payload => {
    loadingSubmitWorkflow.value = true
    await apolloClient.query({
      query: editWorkflow,
      fetchPolicy: 'no-cache',
      variables: payload,
    }).then(() => {
      loadingSubmitWorkflow.value = false
      Vue.notify({
        title: 'Sukses!',
        text: 'Berhasil mengubah workflow!',
      })
    }).catch(err => {
      loadingSubmitWorkflow.value = false
      errorHandling(err)
    })
  }

  const changeStatusWorkflow = async (triggerId, val) => {
    loadingStatusWorkflow.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: toggleStatusWorkflow,
        fetchPolicy: 'no-cache',
        variables: {
          trigger_id: triggerId,
          value: !(val || false),
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(({ data }) => {
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil mengubah status workflow!',
        })
        loadingStatusWorkflow.value = false
        resolve(data.toggleStatusWorkflow)
      }).catch(err => {
        loadingStatusWorkflow.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const deleteWorkflow = async id => {
    await apolloClient.mutate({
      mutation: removeWorkflow,
      variables: {
        trigger_id: id,
        workspace_id: store.getters.getCurrentWorkspaceId,
      },
    }).then(() => {
      Vue.notify({
        title: 'Sukses!',
        text: 'Berhasil menghapus workflow',
      })
    }).catch(err => {
      errorHandling(err)
    })
  }

  const fetchWorkflowDashboardStatus = async filter => {
    loadingDashboardWorkflow.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getWorkflowDashboardStatus,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            ...filter,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        },
      }).then(({ data }) => {
        loadingDashboardWorkflow.value = false
        dashboardWorkflowList.value = data.getWorkflowDashboardStatus
        resolve(data.getWorkflowDashboardStatus)
      }).catch(err => {
        loadingDashboardWorkflow.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchWorkflowDashboardStatusAdmin = async () => {
    loadingDashboardWorkflow.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getWorkflowDashboardStatusAdmin,
        fetchPolicy: 'no-cache',
        variables: {
        },
      }).then(({ data }) => {
        loadingDashboardWorkflow.value = false
        dashboardWorkflowList.value = data.getWorkflowDashboardStatusAdmin
        resolve(data.getWorkflowDashboardStatusAdmin)
      }).catch(err => {
        loadingDashboardWorkflow.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchWorkflowUserDashboardUsage = async numMonth => {
    loadingDashboardWorkflowUsage.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getWorkflowUserDashboardUsage,
        fetchPolicy: 'no-cache',
        variables: {
          num_months: numMonth,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(({ data }) => {
        loadingDashboardWorkflowUsage.value = false
        dashboardUserWorkflowUsage.value = data.getWorkflowUserDashboardUsage

        resolve(data.getWorkflowUserDashboardUsage)
      }).catch(err => {
        loadingDashboardWorkflowUsage.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchWorkflowAdminDashboardUsage = async numMonth => {
    loadingDashboardWorkflowUsage.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getWorkflowAdminDashboardUsage,
        fetchPolicy: 'no-cache',
        variables: {
          num_months: numMonth,
        },
      }).then(({ data }) => {
        loadingDashboardWorkflowUsage.value = false
        dashboardAdminWorkflowUsage.value = data.getWorkflowAdminDashboardUsage

        resolve(data.getWorkflowAdminDashboardUsage)
      }).catch(err => {
        loadingDashboardWorkflowUsage.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchTransactionDashboard = async numMonth => {
    loadingDashboardTransaction.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getTransactionDashboard,
        fetchPolicy: 'no-cache',
        variables: {
          num_months: numMonth,
        },
      }).then(({ data }) => {
        loadingDashboardTransaction.value = false
        dashboardAdminTransaction.value = data.getTransactionDashboard
        resolve(data.getTransactionDashboard)
      }).catch(err => {
        loadingDashboardTransaction.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  return {
    objectList,
    actionList,
    propertyList,
    operatorList,

    fetchWorkflowDetailAdmin,
    fetchWorkflowDetailV2,
    loadingDetailWorkflow,
    workflowDetail,

    fetchAutomationObjectsV2,
    fetchTriggerActionsV2,
    fetchObjectPropertiesV2,
    fetchOperatorsV2,

    fetchWorkflowListV2,
    loadingWorkflowList,
    workflowCount,
    workflowList,

    changeStatusWorkflow,
    deleteWorkflow,

    createWorkflow,
    updateWorkflow,

    loadingSubmitWorkflow,
    loadingStatusWorkflow,

    loadingAction,
    fetchExecutorActionsV2,

    fetchTriggerPropertiesV2,
    triggerPropertyList,
    loadingTriggerProperty,

    fetchWorkflowLog,
    loadingLogWorkflow,
    workflowLogList,
    workflowLogCount,

    fetchWorkflowDashboardStatusAdmin,
    fetchWorkflowDashboardStatus,
    loadingDashboardWorkflow,
    dashboardWorkflowList,

    fetchWorkflowAdminDashboardUsage,
    dashboardAdminWorkflowUsage,
    fetchWorkflowUserDashboardUsage,
    loadingDashboardWorkflowUsage,
    dashboardUserWorkflowUsage,

    fetchTransactionDashboard,
    loadingDashboardTransaction,
    dashboardAdminTransaction,
  }
}

export default useAutomationV2
