import gql from 'graphql-tag'

const addDefaultSavedView = gql`
  mutation (
    $saved_view_id: Float!
    $workspace_id: Float!
  ) {
    addDefaultSavedView(
      saved_view_id: $saved_view_id
      workspace_id: $workspace_id
    ) {
      status
      msg
    }
  }
`

export default addDefaultSavedView
