import { ref } from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import { apolloClient } from '@/vue-apollo'
import { usersTeams } from '@/graphql/queries'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

const useTeam = () => {
  const workspaceId = store.getters.getCurrentWorkspaceId

  const loadingSearchUsersTeams = ref(false)
  const searchUsersTeamsResults = ref({
    users: [],
    teams: [],
  })

  const searchUsersTeams = useDebounceFn(async (
    search = null,
    visibilityId = 6,
    jobTypeId,
    success = () => {},
  ) => {
    /* eslint-disable no-unneeded-ternary */
    loadingSearchUsersTeams.value = true
    await apolloClient.query({
      query: usersTeams,
      variables: {
        search,
        visibility_id: visibilityId ? visibilityId : 6,
        job_type_id: jobTypeId ? jobTypeId : null,
        workspace_id: workspaceId,
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      searchUsersTeamsResults.value = result.data.usersTeams
      loadingSearchUsersTeams.value = false
      success()
    }).catch(err => {
      errorHandling(err)
      loadingSearchUsersTeams.value = false
    })
  }, 500)
  /* eslint-enable no-unneeded-ternary */

  return {
    loadingSearchUsersTeams,
    searchUsersTeamsResults,

    searchUsersTeams,
  }
}

export default useTeam
