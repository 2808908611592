import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800","persistent":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VForm,{ref:"editFolderForm",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c(VCard,[_c('div',{staticClass:"d-flex justify-space-between pa-4"},[_c('span',{staticClass:"text-h6 font-medium primary--text"},[_vm._v("Ubah Atribut Folder")]),_c(VIcon,{on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,{staticClass:"pt-2"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"rules":[_vm.required],"label":"Nama folder","placeholder":"Masukkan nama folder","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('color-picker',{staticClass:"mb-2",attrs:{"label":"Warna","outlined":"","rules":[_vm.required]},model:{value:(_vm.formData.color),callback:function ($$v) {_vm.$set(_vm.formData, "color", $$v)},expression:"formData.color"}})],1)],1)],1),_c(VCardActions,[_c('div',{staticClass:"mt-4 ml-md-auto"},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.loadingEditFolder},on:{"click":_vm.submitForm}},[_vm._v(" Simpan ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }