import gql from 'graphql-tag'

const editFolderAttribute = gql`
  mutation updateFolderAttribute(
    $workspace_id: Float!,
    $folder_id: Float!,
    $name: String!,
    $color: String!,
  ) {
    updateFolderAttribute(
      workspace_id: $workspace_id,
      folder_id: $folder_id,
      name: $name,
      color: $color,
    ) {
      status
      msg
      data
    }
  }
`

export default editFolderAttribute
