import gql from 'graphql-tag'

const getWorkflowUserDashboardUsage = gql`
  query getWorkflowUserDashboardUsage(
    $num_months: Float!
    $workspace_id: Float!
  ) {
    getWorkflowUserDashboardUsage(
      num_months: $num_months
      workspace_id: $workspace_id
    ) {
      labels
      data
    }
  }
`

export default getWorkflowUserDashboardUsage
