import gql from 'graphql-tag'

const addUserHierarchy = gql`
mutation addUserHierarchy(
  $workspace_id: Float!
  $hierarchy_id: Float!
  $user_id: [Int!]!
){
  addUserHierarchy(
    workspace_id: $workspace_id
    hierarchy_id: $hierarchy_id
    user_id: $user_id
  ){
    msg
    status
    data
  }
}
`
export default addUserHierarchy
