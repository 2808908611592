import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"300","persistent":_vm.persistent},model:{value:(_vm.isShown),callback:function ($$v) {_vm.isShown=$$v},expression:"isShown"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.body)+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"text-capitalize",attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]),_c(VBtn,{staticClass:"text-capitalize",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.accept()}}},[_vm._v(" "+_vm._s(_vm.acceptText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }