import gql from 'graphql-tag'

const updateCustomAttribute = gql`
  mutation updateCustomAttribute(
    $id: Float!
    $name: String!
    $options: [customAttributeOptions!]
    $delete_options: [Int!]
  ) {
    updateCustomAttribute(
      id: $id
      name: $name
      options: $options
      delete_options: $delete_options
    ) {
      msg
      status
      data
    }
  }
`

export default updateCustomAttribute
