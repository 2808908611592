import gql from 'graphql-tag'

const uploadCustomer = gql`
  query(
    $file: Upload!
    $workspace_id: Float!
  ) {
    uploadCustomer(
      file: $file
      workspace_id: $workspace_id
    ) {
      existing_customer_id
      name
      gender
      description
      address
      job_title
      birth_date
      phone_number
      whatsapp_number
      email
      review_description
    }
  }
`

export default uploadCustomer
