import {
  mdiViewDashboard,
  mdiAccount,
  // mdiAccountGroup,
  // mdiAccountChild,
  // mdiCalendarText,
  // mdiHeart,
  // mdiPackage,
  // mdiForum,
  // mdiTagHeart,
  // mdiTicketAccount,
  mdiFileDocument,
  mdiBookEducation,
  mdiApps,
  mdiClipboardFlow,
  mdiStore,
  mdiCashMultiple,
} from '@mdi/js'

const adminNavItems = [
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Dashboard',
    icon: mdiViewDashboard,
    to: 'admin-dashboard',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Pengguna',
    icon: mdiAccount,
    to: 'admin-user',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Daftar Workflow',
    icon: mdiClipboardFlow,
    to: 'admin-workflow',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Integrasi Tokopedia',
    icon: mdiStore,
    to: 'admin-tokopedia',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Riwayat Transaksi',
    icon: mdiCashMultiple,
    to: 'admin-transaction',
  },
]

const userNavItems = [
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Dashboard',
    icon: mdiViewDashboard,
    to: 'workspace-root',
  },
  // {
  //   action: 'read',
  //   resource: 'Customer',
  //   title: 'Pelanggan',
  //   icon: mdiHeart,
  //   to: 'customers',
  // },
  // {
  //   action: 'read',
  //   resource: 'Product',
  //   title: 'Produk',
  //   icon: mdiPackage,
  //   to: 'products',
  // },
  // {
  //   action: 'read',
  //   resource: 'Navbar',
  //   title: 'Chat',
  //   icon: mdiForum,
  //   to: 'chats',
  // },
  // {
  //   action: 'read',
  //   resource: 'Prospect',
  //   title: 'Prospek',
  //   icon: mdiTagHeart,
  //   to: 'prospects',
  // },
  // {
  //   action: 'read',
  //   resource: 'Navbar',
  //   title: 'Job',
  //   icon: mdiTicketAccount,
  //   to: 'jobs',
  // },
  // {
  //   action: 'read',
  //   resource: 'Navbar',
  //   title: 'Laporan',
  //   icon: mdiFileDocument,
  //   to: 'reports',
  // },
  // {
  //   widget: 'folder-subheader',
  //   title: 'Folder',
  //   action: 'read',
  //   resource: 'Navbar',
  // },
  // {
  //   widget: 'folder',
  //   action: 'read',
  //   resource: 'Navbar',
  // },
  // {
  //   widget: 'shared-job-type-subheader',
  //   title: 'Shared Job Type',
  //   action: 'read',
  //   resource: 'Navbar',
  // },
  // {
  //   widget: 'shared-job-type',
  //   action: 'read',
  //   resource: 'Navbar',
  // },
  {
    subheader: 'Automasi',
    action: 'read',
    resource: 'Navbar',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Integrasi',
    icon: mdiApps,
    to: 'integrations',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Workflow',
    icon: mdiClipboardFlow,
    to: 'workflows',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Laporan Penggunaan',
    icon: mdiFileDocument,
    to: 'workspace-workflow-reports',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Panduan',
    icon: mdiBookEducation,
    to: 'guide',
  },
  // {
  //   subheader: 'Personal',
  //   action: 'read',
  //   resource: 'Navbar',
  // },
  // {
  //   action: 'read',
  //   resource: 'Navbar',
  //   title: 'Aktivitas',
  //   icon: mdiCalendarText,
  //   to: 'activities',
  // },
  // {
  //   subheader: 'Administrator',
  //   action: 'read',
  //   resource: 'Navbar',
  // },
  // {
  //   action: 'read',
  //   resource: 'User',
  //   title: 'Pengguna',
  //   icon: mdiAccount,
  //   to: 'users',
  // },
  // {
  //   action: 'read',
  //   resource: 'Navbar',
  //   title: 'Hierarki',
  //   icon: mdiAccountChild,
  //   to: 'hierarchy',
  // },
  // {
  //   action: 'read',
  //   resource: 'Team',
  //   title: 'Tim',
  //   icon: mdiAccountGroup,
  //   to: 'teams',
  // },
]

export {
  adminNavItems,
  userNavItems,
}
