import gql from 'graphql-tag'

const dashboardTasks = gql`
  query dashboardTasks(
    $pagination: pagination!,
  ) {
    dashboardTasks(
      pagination: $pagination,
    ) {
      new {
        count
        tasks {
          id
          subject
          description
          start_date
          end_date
          completed_at
          created_by{
            id
            name
          }
          referenceTag {
            id
            tag {
              id
              name
              color
            }
          }
          created_at
          updated_at
          deleted_at
          assignedTo{
            id
            user{
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
          job {
            id
            name
          }
        }
      }
      today {
        count
        tasks {
          id
          subject
          description
          start_date
          end_date
          completed_at
          created_by{
            id
            name
          }
          referenceTag {
            id
            tag {
              id
              name
              color
            }
          }
          created_at
          updated_at
          deleted_at
          assignedTo{
            id
            user{
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
          job {
            id
            name
          }
        }
      }
      tomorrow {
        count
        tasks {
          id
          subject
          description
          start_date
          end_date
          completed_at
          created_by{
            id
            name
          }
          referenceTag {
            id
            tag {
              id
              name
              color
            }
          }
          created_at
          updated_at
          deleted_at
          assignedTo{
            id
            user{
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
          job {
            id
            name
          }
        }
      }
      late {
        count
        tasks {
          id
          subject
          description
          start_date
          end_date
          completed_at
          created_by{
            id
            name
          }
          referenceTag {
            id
            tag {
              id
              name
              color
            }
          }
          created_at
          updated_at
          deleted_at
          assignedTo{
            id
            user{
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
          job {
            id
            name
          }
        }
      }
      unscheduled {
        count
        tasks {
          id
          subject
          description
          start_date
          end_date
          completed_at
          created_by{
            id
            name
          }
          referenceTag {
            id
            tag {
              id
              name
              color
            }
          }
          created_at
          updated_at
          deleted_at
          assignedTo{
            id
            user{
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
          job {
            id
            name
          }
        }
      }
    }
  }
`

export default dashboardTasks
