import gql from 'graphql-tag'

const teamRequestInvite = gql`
  query {
    teamRequestInvite {
      team {
        id
        name
      }
      user {
        id
        name
        first_name
        last_name
        email
        phone
        photo
      }
      created_at
    }
  }
`

export default teamRequestInvite
