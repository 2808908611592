import gql from 'graphql-tag'

const addCustomer = gql`
  mutation addCustomer (
    $name: String!
    $gender: String!
    $description: String
    $address: String
    $job_title: String
    $birth_date: DateTime!
    $phone_number: String!
    $whatsapp_number: String
    $email: String
    $instagram: String
    $facebook: String
    $workspace_id: Float!
    $city_id: Float!
    $custom_attribute: [customAttributeValues!]!
  ) {
    addCustomer(
      name: $name
      gender: $gender
      description: $description
      address: $address
      job_title: $job_title
      birth_date: $birth_date
      phone_number: $phone_number
      whatsapp_number: $whatsapp_number
      email: $email
      instagram: $instagram
      facebook: $facebook
      workspace_id: $workspace_id
      city_id: $city_id
      custom_attribute: $custom_attribute
    ) {
      status
      msg
      data
    }
  }
`

export default addCustomer
