const { default: gql } = require('graphql-tag')

const reconnectThirdParty = gql`
  mutation reconnectThirdParty(
    $workspace_id: Float!
    $connection_name: String!
  ){
      reconnectThirdParty(
        workspace_id: $workspace_id
        connection_name: $connection_name
      ){
        status
        msg
        data
      }
  }
`
export default reconnectThirdParty
