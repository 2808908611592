import gql from 'graphql-tag'

const addUser = gql`
  mutation addUser(
    $phone: String!,
    $email: String,
    $first_name: String!,
    $last_name: String!,
    $password: String!,
    $role: Float!,
    $corp_id: Float!,
    $photo: Upload
  ) {
    addUser(phone: $phone,
      email: $email,
      first_name: $first_name,
      last_name: $last_name,
      password: $password,
      role: $role,
      corp_id: $corp_id,
      photo: $photo
    ) {
      status
      msg
      data
    }
  }
`

export default addUser
