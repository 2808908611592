import gql from 'graphql-tag'

const createCustomAttribute = gql`
  mutation createCustomAttribute(
    $name: String!
    $data_type_id: Float!
    $object_type_id: Float!
    $workspace_id: Float!
    $options: [String!]
    $job_type_id: Float
  ) {
    createCustomAttribute(
      name: $name
      data_type_id: $data_type_id
      object_type_id: $object_type_id
      workspace_id: $workspace_id
      options: $options
      job_type_id: $job_type_id
    ) {
      status
      msg
      data
    }
  }
`
export default createCustomAttribute
