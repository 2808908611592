import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    filter: {
      sort: {
        label: 'Nama Task A-Z',
        field: 'subject',
        type: 'ASC',
      },
      status: 2,
      isAllUser: true,
      search: '',

      // popup filter
      assignedTo: [],
      createdBy: [],
      endAt: { startDate: null, endDate: null },
      completedAt: { startDate: null, endDate: null },
      updatedAt: { startDate: null, endDate: null },
      tag: [],
      Job: [],
      prospect: [],
      customer: [],
    },
  },
  getters: {
    getField,
    filterCount(state) {
      let filterCount = 0

      const { filter } = state
      const arrayKey = ['search', 'assignedTo', 'createdby']
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filter) {
        if (arrayKey.includes(key)) {
          // eslint-disable-next-line no-unused-expressions
          filter[key].length ? filterCount += 1 : null
        }
      }

      return filterCount
    },
  },
  mutations: {
    setField,
  },
}
