import gql from 'graphql-tag'

const deleteTask = gql`
mutation deleteTask($id: Float!, $workspace_id: Float!) {
  deleteTask(id: $id, workspace_id: $workspace_id) {
    status
    msg
    data
  }
}

`
export default deleteTask
