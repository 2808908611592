import { cancelTransaction } from '@/graphql/mutations'
import { getTransactions } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { ref } from '@vue/composition-api'

const useProfile = () => {
  const loadingTransactions = ref(false)
  const transactionList = ref([])

  const fetchTransactions = async pagination => {
    loadingTransactions.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getTransactions,
        fetchPolicy: 'no-cache',
        variables: {
          pagination,
        },
      }).then(({ data }) => {
        loadingTransactions.value = false
        transactionList.value = data.transactions
        resolve(data.transactions)
      }).catch(error => {
        loadingTransactions.value = false
        reject(error)
        errorHandling(error)
      })
    })
  }

  const cancelTransactionFunc = async orderId => {
    loadingTransactions.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: cancelTransaction,
        fetchPolicy: 'no-cache',
        variables: {
          order_id: orderId,
        },
      }).then(({ data }) => {
        loadingTransactions.value = false
        resolve(data.transactions)
      }).catch(error => {
        loadingTransactions.value = false
        reject(error)
        errorHandling(error)
      })
    })
  }

  return {
    fetchTransactions,
    loadingTransactions,
    cancelTransactionFunc,
  }
}

export default useProfile
