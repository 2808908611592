import gql from 'graphql-tag'

const createWorkspace = gql`
  mutation(
    $name: String!
    $identifier_id: String!
    $photo: Upload
    $defaultVisibilityId_tasks: Float!
    $defaultVisibilityId_job: Float!
    $defaultVisibilityId_customer: Float!
  ) {
    createWorkspace(
      name: $name
      identifier_id: $identifier_id
      photo: $photo
      defaultVisibilityId_tasks: $defaultVisibilityId_tasks
      defaultVisibilityId_job: $defaultVisibilityId_job
      defaultVisibilityId_customer: $defaultVisibilityId_customer
    ) {
      status
      msg
      data
    }
  }
`

export default createWorkspace
