import gql from 'graphql-tag'

const workspaceTokenList = gql`
  query workspaceTokenList (
    $platformId: Float!
    $workspace_id: Float!
    $is_active_only: Boolean
  ) {
    workspaceTokenList(
      platformId: $platformId
      workspace_id: $workspace_id
      is_active_only: $is_active_only
    ) {
      id
      connection_name
      shop_url
      access_token
      refresh_token
      identifier_accurate
      shop_id
      created_at
      updated_at
      status
    }
  }
`

export default workspaceTokenList
