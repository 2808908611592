import gql from 'graphql-tag'

const getMyTrelloUserData = gql`
  query getUserTrelloData(
    $workspace_id: Float!
  ) {
    getUserTrelloData(
      workspace_id: $workspace_id
    ) {
      id
      username
      fullName
      avatarUrl
      token
    }
  }
`

export default getMyTrelloUserData
