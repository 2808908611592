import gql from 'graphql-tag'

const addComment = gql`
  mutation(
    $job_id: Float
    $task_id: Float
    $comment: String!
    $paramTagUser: paramTagUser
    $commentFile: [paramCommentFile!]
  ) {
    addComment(
      job_id: $job_id
      task_id: $task_id
      comment: $comment
      paramTagUser: $paramTagUser
      commentFile: $commentFile
    ) {
      status
      msg
      data
    }
  }
`

export default addComment
