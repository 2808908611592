import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,{staticClass:"d-flex flex-column h-full"},[_c('div',{staticClass:"d-flex align-start mb-4"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-subtitle-1 font-weight-bold"},on),[_vm._v(" Sisa Kuota Automasi Bulan ini: "+_vm._s(_vm.userData.kuota_automasi)+" kali ")])]}}])},[_c('div',[_vm._v("Jika Kuota habis, maka workflow tidak akan dijalankan")]),_c('div',[_vm._v("Kuota automasi diisi ulang setiap awal bulan")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"text-caption-2 mb-1"},[_vm._v(" Filter Tanggal ")]),_c('DateRangePicker',{ref:"dateRangePicker",staticClass:"mb-4",attrs:{"max-date":_vm.today},on:{"update":_vm.changeFilterDate},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"text-caption-2 mb-1"},[_vm._v(" Filter Status ")]),_c(VSelect,{attrs:{"items":_vm.statusOptions,"item-text":"label","item-value":"value","outlined":"","dense":"","flat":"","hide-details":"auto"},on:{"change":_vm.loadWorkflowLog},model:{value:(_vm.filterLog.status),callback:function ($$v) {_vm.$set(_vm.filterLog, "status", $$v)},expression:"filterLog.status"}})],1)],1),_c('div',[(_vm.filterLog.status !== null || _vm.filterLog.date_min || _vm.filterLog.date_max)?_c(VChip,{staticClass:"mb-4",attrs:{"small":"","close":"","color":"error"},on:{"click:close":_vm.resetFilter}},[_vm._v(" Reset Filter ")]):_vm._e()],1),_c(VDataTable,{staticClass:"mt-4",attrs:{"item-key":"id","height":"52vh","fixed-header":"","headers":_vm.tableColumns,"items":_vm.workflowLogList,"mobile-breakpoint":"0","server-items-length":_vm.workflowLogCount,"disable-sort":"","footer-props":{
      'items-per-page-options': [10, 25, 50],
      'items-per-page-text': 'Jumlah data',
    },"options":_vm.paginationLog,"loading":_vm.loadingLogWorkflow},on:{"update:options":function($event){_vm.paginationLog=$event}},scopedSlots:_vm._u([{key:"item.executed_at",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDate(props.item.executed_at, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }))+" ")]}},{key:"item.workflow",fn:function(props){return [_c(VListItem,{on:{"click":function($event){return _vm.goToWorkflowDetail(props.item.workspace_id, props.item.trigger_id)}}},[_c('a',[_vm._v(_vm._s(props.item.trigger_name))])])]}},{key:"item.workspace",fn:function(props){return [_c(VChip,{attrs:{"small":""},on:{"click":function($event){return _vm.changeWorkspace(props.item.workspace_id, props.item.workspace_identifier)}}},[_c('span',[_vm._v(_vm._s(props.item.workspace_name))])])]}},{key:"item.status",fn:function(props){return [_c(VChip,{attrs:{"color":props.item.is_error ? 'error' : 'success',"outlined":""}},[_vm._v(" "+_vm._s(props.item.is_error ? 'Error' : 'Success')+" ")])]}},{key:"item.message",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.item.message ? props.item.message : '-'))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }