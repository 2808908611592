import gql from 'graphql-tag'

const prospectDetail = gql`
query prospectDetail($prospect_id: Float!, $workspace_id: Float!) {
  prospectDetail(prospect_id: $prospect_id, workspace_id:$workspace_id){
    id
    name
    description
    status{
      id
      name
    }
    expired_at
    product{
      product{
        id
        name
        description
        code_sku
        price
        status
        stock
        photo{
          id
          photo
          order_photo
        }
        created_at
        updated_at
      }
      quantity
    }
    customer{
      id
      name
      gender
      description
      address
      job_title
      birth_date
      phone_number
      whatsapp_number
      email
      instagram
      facebook
      type{
        id
        name
        color
      }
      city{
        id
        name
      }
      created_at
      updated_at
    }
    assignedTo{
      id
      user {
        id
        name
        email
        name
        first_name
        last_name
        photo
        notify_task_assignment
        notify_comment
        notify_job_assignment
        user_status{
          id
          name
        }
        availability
      }
    }
    created_by{
      id
      name
    }
    visibility{
      id
      name
    }
    value
    lexorank
    created_at
    updated_at
    is_subscribed
  }
}
`
export default prospectDetail
