<template>
  <div>
    <v-slide-y-transition>
      <v-row>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="filterName"
            dense
            outlined
            hide-details
            hint="Cari pengguna..."
            label="Filter Berdasarkan Nama"
            class="mt-6"
            @input="debouncedFetchWorkspaceMember"
          >
            <template #append>
              <div class="d-flex align-center">
                <v-icon
                  class="mr-2"
                  size="22"
                >
                  {{ icons.mdiMagnify }}
                </v-icon>
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <v-data-iterator
      :items="members"
      :items-per-page="10"
      show-select
      disable-sort
      :footer-props="{
        'items-per-page-options': [10, 20, 30],
        'items-per-page-text': 'Jumlah data',
      }"
      :server-items-length="memberCount"
      mobile-breakpoint="0"
      :loading="loading"
      :options.sync="pagination"
      class="mt-2"
    >
      <template v-slot:default="props">
        <!-- <div
            v-for="member in props.items"
            :key="member.user.id"
          >
            {{ member }}
          </div> -->

        <PerfectScrollbar
          :options="{suppressScrollX:true, useBothWheelAxes:false}"
          class="ps-user-workspace mt-2 pa-2"
        >
          <v-overlay
            absolute
            :value="loading"
            opacity="0.25"
          >
            <v-progress-circular
              indeterminate
              size="64"
              color="primary"
            />
            >
          </v-overlay>
          <v-row>
            <v-col
              v-for="(member, index) in props.items"
              :key="index"
              cols="12"
              lg="6"
              class="pa-1"
            >
              <v-card outlined>
                <div class="d-flex pa-3 justify-space-between">
                  <div class="d-flex">
                    <v-avatar size="35">
                      <v-img src="@/assets/images/avatars/5.png" />
                    </v-avatar>
                    <div class="ml-4">
                      <div>
                        <p
                          class="mb-0 mr-2 body-2 font-weight-semibold d-inline-block text-truncate"
                          style="max-width: 200px;"
                        >
                          {{ member.user ? member.user.name : member.email }}
                        </p>
                        <v-chip
                          v-show="member.user && userData.id === member.user.id"
                          x-small
                          color="primary"
                        >
                          Anda
                        </v-chip>
                      </div>
                      <span class="text-caption text--disabled">{{ member.user ? member.user.email : member.email }}</span>
                      <!-- <v-select
                        style="max-width:200px;"
                        dense
                        :items="roles"
                        :value="member.role.id"
                        :disabled="((member.role.id >= 1) && !isMyWorkspace) || (isMyWorkspace && member.user.id === userData.id)"
                        item-value="id"
                        item-text="name"
                        label="Role"
                        return-object
                        class="mt-6"
                        hide-details="auto"
                        @change="(data) => updateUserRole(member.user, data)"
                      >
                        <template #item="{item, on, attr}">
                          <v-list-item
                            v-show="item.id !== 1"
                            :value="item"
                            v-bind="attr"
                            v-on="on"
                          >
                            {{ item.name }}
                          </v-list-item>
                        </template>
                      </v-select> -->
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <div v-if="member.is_pending">
                      <v-chip
                        small
                        color="warning"
                        outlined
                        label
                      >
                        Pending
                      </v-chip>
                    </div>
                    <v-menu
                      v-if="member.user && userData.id !== member.user.id && member.role.id > 2 && userDataRole.id <= 1"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          plain
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title
                            class="error--text"
                            style="cursor: pointer"
                            @click="removeUser(member.user)"
                          >
                            Keluarkan dari Workspace
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </PerfectScrollbar>
      </template>
    </v-data-iterator>
  </div>
</template>
<script>
import {
  computed, defineComponent, onMounted, ref, watch,
} from '@vue/composition-api'
import { mdiDotsVertical, mdiMagnify } from '@mdi/js'
import Vue from 'vue'
// import { users } from '../variable'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { roleList, workspaceMembers } from '@/graphql/queries'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { removeFromWorkspace, updateStatusRole } from '@/graphql/mutations'
import { useDebounceFn } from '@vueuse/core'

export default defineComponent({
  components: {
    PerfectScrollbar,
  },
  name: 'ActiveSharedUser',
  props: {
    workspace: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false)
    const userDataRole = computed(() => store.getters.getUserRole)
    const members = ref([])
    const memberCount = ref(0)
    const roleSelect = ref()
    const pagination = ref({})
    const roles = ref([])
    const userData = computed(() => store.state.auth.userData)
    const filterName = ref('')
    const isMyWorkspace = computed(() => props.workspace.owner.id === userData.value.id)

    const updateUserRole = (user, data) => {
      Vue.$dialog({
        title: 'Ganti Role?',
        body: `Yakin ingin mengganti role untuk akun ${user.name} menjadi ${data.name}?`,
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: updateStatusRole,
            variables: {
              workspace_id: props.workspace.id,
              user_id: user.id,
              role_id: data.id,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil update role pengguna!',
            })
          }).catch(err => {
            loading.value = false
            errorHandling(err)
          })
        }
      })
    }
    const fetchWorkspaceMember = () => {
      loading.value = true
      apolloClient.query({
        query: workspaceMembers,
        fetchPolicy: 'no-cache',
        variables: {
          search: filterName.value,
          workspace_id: props.workspace.id,
          pagination: {
            limit: pagination.value.itemsPerPage ? pagination.value.itemsPerPage : 10,
            offset: pagination.value.page === 1 ? 0 : (pagination.value.page - 1) * pagination.value.itemsPerPage ?? 0,
          },
        },
      }).then(({ data }) => {
        members.value = data.workspaceMembers.members
        memberCount.value = data.workspaceMembers.users_count
        loading.value = false
      }).catch(err => {
        loading.value = false
        errorHandling(err)
      })
    }
    const removeUser = user => {
      Vue.$dialog({
        title: 'Keluarkan Pengguna?',
        body: `Yakin ingin mengeluarkan ${user.name}?`,
      }).then(result => {
        if (result) {
          loading.value = true
          apolloClient.mutate({
            mutation: removeFromWorkspace,
            variables: {
              workspace_id: props.workspace.id,
              user_id: user.id,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengeluarkan pengguna!',
            })
            loading.value = false
            fetchWorkspaceMember()
          }).catch(err => {
            loading.value = false
            errorHandling(err)
          })
        }
      })
    }
    const fetchRoles = () => {
      loading.value = true
      apolloClient.query({
        query: roleList,
        variables: {
          workspace_id: props.workspace.id,
        },
      }).then(result => {
        loading.value = false
        roles.value = result.data.roleList
      })
    }
    watch(pagination, () => {
      fetchWorkspaceMember()
    })
    onMounted(() => {
      fetchRoles()
      fetchWorkspaceMember()
    })

    const debouncedFetchWorkspaceMember = useDebounceFn(fetchWorkspaceMember, 500)

    return {
      pagination,
      loading,
      userDataRole,
      updateUserRole,
      removeUser,
      roleSelect,
      roles,
      members,
      memberCount,
      userData,
      icons: {
        mdiDotsVertical,
        mdiMagnify,
      },
      filterName,
      debouncedFetchWorkspaceMember,
      isMyWorkspace,
    }
  },
})
</script>
<style lang="scss">
  .userlist-footer {
    position: fixed;
    top: 16px;
    right: 0;
    margin: 0 24px;
    z-index: 20;

    .userlist-pagination {
      .v-data-footer__select {
        margin-left: 0 !important;
      }

      .v-data-footer__pagination {
        margin-left: auto !important;
      }
    }
  }
.ps-user-workspace{
  max-height: 24rem;
}

</style>
