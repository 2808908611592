import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{staticClass:"content-layout horizontal-nav",class:{ 'content-full': _vm.appContentWidth === 'full' }},[_c(VSystemBar,{staticClass:"app-system-bar",class:[{ 'app-system-bar-boxed': _vm.appContentWidth === 'boxed' }, { 'bg-blur': _vm.appBarIsBlurred }],attrs:{"app":"","height":"64","absolute":_vm.appBarType === 'static'}},[_vm._t("navbar")],2),(!_vm.menuIsMenuHidden)?_c(VAppBar,{staticClass:"navigation-menu",class:{ 'bg-blur': _vm.appBarIsBlurred },attrs:{"app":"","height":"64","absolute":_vm.appBarType === 'static',"elevation":_vm.$vuetify.theme.isDark ? 4 : 3}},[_c('horizontal-nav-menu',{class:{ 'horizontal-nav-menu-boxed mx-auto': _vm.appContentWidth === 'boxed' },attrs:{"nav-menu-items":_vm.navMenuItems}})],1):_vm._e(),_vm._t("v-app-content"),_c(VMain,[_c('app-content-container',[_vm._t("default")],2)],1),_c(VOverlay,{staticClass:"content-overlay",attrs:{"value":_vm.$store.state.app.shallContentShowOverlay,"z-index":"6","absolute":""}}),(_vm.footerType !== 'hidden')?_c(VFooter,{attrs:{"app":"","inset":"","absolute":_vm.footerType === 'static',"padless":"","color":_vm.footerType === 'static' ? 'transparent' : null}},[_c(VCol,{attrs:{"cols":"12"}},[_vm._t("footer")],2)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }