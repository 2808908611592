import gql from 'graphql-tag'

const addProspect = gql`
mutation addProspect(
 $assign_user_id: [Int!]
 $assign_team_id: [Int!]
 $name: String!
 $description: String
 $expired_at: DateTime
 $prospect_product: [prospectProduct!]
 $order_id: Float
 $customer: Float!
 $prospect_status: Float!
 $visibility: Float!
 $visibility_5_users: [Int!]
 $workspace_id: Float!
 $custom_attribute: [customAttributeValues!]!
 ) {
  addProspect(
    assign_user_id:$assign_user_id
    assign_team_id:$assign_team_id
    name: $name
    description: $description
    expired_at: $expired_at
    prospect_product: $prospect_product
    order_id: $order_id
    customer: $customer
    prospect_status:$prospect_status
    visibility:$visibility
    visibility_5_users:$visibility_5_users
    workspace_id:$workspace_id
    custom_attribute: $custom_attribute
    ) {
    status
    msg
    data
  }
}
`
export default addProspect
