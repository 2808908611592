import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canViewVerticalNavMenuGroup(_vm.item))?_c(VMenu,{ref:"refMenu",attrs:{"offset-x":"","attach":"","open-on-hover":_vm.shallOpenGroupOnHover,"left":_vm.openChildMenuLeft,"max-height":"60vh","content-class":_vm.contentClasses},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({ref:"refActivator",staticClass:"horizontal-nav-menu-group",class:[{ 'horizontal-nav-menu-group-active': _vm.isActive }, { 'menu-open': _vm.isMenuActive }]},'v-list-item',attrs,false),on),[_c(VListItemIcon,[_c(VIcon,{attrs:{"size":_vm.item.icon ? 22 : 15}},[_vm._v(" "+_vm._s(_vm.item.icon || _vm.alternateIcon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.t(_vm.item.title)))])],1),_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiChevronRight))])],1)],1)]}}],null,false,3766112362)},[_c(VList,{ref:"refContent",attrs:{"elevation":"8"}},[_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.item.children),function(child){return _c(_vm.resolveNavComponent(child),{key:child.subheader || child.title,tag:"component",attrs:{"item":child}})}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }