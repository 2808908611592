import gql from 'graphql-tag'

const selectSavedView = gql`
  mutation (
    $saved_view_id: Float!
  ) {
    selectSavedView(
      saved_view_id: $saved_view_id
    ) {
      status
      msg
    }
  }
`

export default selectSavedView
