import gql from 'graphql-tag'

const updateJobStatus = gql`
  mutation(
    $workspace_id: Float!
    $job_status: [paramUpdateJobStatus!]!
  ) {
    updateJobStatus(
      workspace_id: $workspace_id
      job_status: $job_status
    ) {
      status
      data
      msg
    }
  }
`

export default updateJobStatus
