import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"offset-y":"","left":"","nudge-bottom":"14","min-width":"230","content-class":"user-profile-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"v-avatar-light-bg primary--text ms-4",attrs:{"size":"40px","color":"primary"}},'v-avatar',attrs,false),on),[(_vm.user && _vm.user.photo !== null)?_c(VImg,{attrs:{"src":_vm.user.photo}}):_c(VIcon,{attrs:{"size":"20","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccount)+" ")])],1)]}}])},[_c(VList,[(_vm.user)?_c('div',{staticClass:"pb-3 pt-2 d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center mb-3"},[_c(VAvatar,{staticClass:"ms-3 v-avatar-light-bg primary--text",attrs:{"offset-x":"12","offset-y":"12","size":"40px","color":"primary"}},[(_vm.user.photo !== null)?_c(VImg,{attrs:{"src":_vm.user.photo}}):_c('span',[_vm._v(" "+_vm._s(_vm.avatarText(((_vm.user.first_name) + " " + (_vm.user.last_name))))+" ")])],1),_c('div',{staticClass:"d-flex flex-column justify-center ms-2",staticStyle:{"vertical-align":"middle"}},[_c('span',{staticClass:"text-subtitle-2 text--primary font-weight-semibold mb-1"},[_vm._v(_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name))]),_c(VChip,{staticClass:"align-self-start",attrs:{"x-small":"","color":"primary"}},[_c('span',{staticClass:"text-caption"},[_vm._v("ADMIN")])])],1)],1),_c('small',{staticClass:"text-capitalize ml-3"},[_vm._v(_vm._s(_vm.user.email))])]):_vm._e(),_c(VDivider,{staticClass:"my-2"}),_c(VListItem,{on:{"click":function($event){return _vm.$store.dispatch('logout')}}},[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLogoutVariant)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Logout")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }