import { setupStore } from 'vuex-use-fields'
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import auth from './module/auth'
import customer from './module/customer'
import user from './module/user'
import product from './module/product'
import job from './module/job'
import team from './module/team'
import corporation from './module/corporation'
import chat from './module/chat'
import workspace from '@/store/module/workspace'
import task from './module/task'
import prospect from '@/store/module/prospect'
import dashboard from '@/store/module/dashboard'
import trello from '@/store/module/trello'
import accurate from '@/store/module/accurate'
// import google from '@/store/module/google'
import google from '@/store/module/google'
import globalSearch from '@/store/module/globalSearch'
import transaction from '@/store/module/transaction'
import folder from '@/store/module/folder'
import jobType from '@/store/module/jobType'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
    corporation,
    customer,
    user,
    product,
    team,
    job,
    chat,
    workspace,
    task,
    prospect,
    dashboard,
    trello,
    google,
    globalSearch,
    transaction,
    folder,
    jobType,
    accurate,
  },
  plugins: [setupStore],
})
