<template>
  <v-card-text class="d-flex flex-column h-full">
    <h4 class="mb-1">
      Utama
    </h4>
    <v-form
      ref="workspaceForm"
      lazy-validation
      class="d-flex flex-column"
    >
      <v-row>
        <v-col
          cols="12"
          md="auto"
        >
          <span class="text-caption mb-1">Icon Workspace</span>
          <v-card
            width="110"
            height="110"
            outlined
            class="mt-1 d-flex align-center justify-center"
            @click="$refs.workspaceIconPicker.click()"
          >
            <v-img
              v-if="workspaceData.photoPreview"
              :src="workspaceData.photoPreview"
              height="110"
              width="110"
              class="rounded"
            />
            <v-icon
              v-else
              class="text--disabled"
              size="32"
            >
              {{ icons.mdiImage }}
            </v-icon>
          </v-card>
          <input
            ref="workspaceIconPicker"
            type="file"
            class="d-none"
            accept=".jpg, .jpeg, .png"
            @change="onFileChange($event.target.files)"
          >
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="d-flex flex-column"
        >
          <span class="text-caption mb-1">Nama Workspace</span>
          <v-text-field
            v-model="workspaceData.name"
            :rules="[required]"
            hide-details="auto"
            placeholder="Input nama workspace"
            outlined
            dense
            class="mb-2"
          />
          <span class="text-caption mb-1">Workspace ID</span>
          <v-text-field
            v-model="workspaceData.identifier"
            :rules="[
              required,
              alphaDashValidator,
              maxLength(workspaceData.identifier, 20),
            ]"
            placeholder="Input Workspace ID"
            outlined
            dense
            hide-details="auto"
            :error="isIDError && !isIDApproved"
            :success="isIDApproved && !isIDError"
            :error-messages="isIDError && !isIDApproved ? 'ID workspace tidak tersedia' : null"
            @keyup="checkID"
          >
            <template #append>
              <v-menu
                open-on-hover
                offset-y
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="text--disabled"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiHelpCircleOutline }}
                  </v-icon>
                </template>
                <v-card
                  max-width="300"
                  class="pa-3 d-flex flex-column"
                >
                  <span class="text-caption font-medium mb-2">Workspace ID</span>
                  <span class="text-caption">Workspace ID digunakan untuk mengatur alamat URL unik dari workspace mu.</span>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <span class="text-caption error--text mb-1">{{ errorPhoto }}</span>
      <!-- <h4 class="mb-1 mt-4">
        Visibilitas
      </h4>
      <span class="d-block mb-1 text-caption">
        Atur visibilitas default dari masing masing objek yang akan dibuat.
      </span>
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column"
        >
          <VisibilitySelect
            v-model="workspaceData.defaultVisibility.customer"
            label="Visibilitas Pelanggan"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column"
        >
          <VisibilitySelect
            v-model="workspaceData.defaultVisibility.job"
            label="Visibilitas Job"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column"
        >
          <VisibilitySelect
            v-model="workspaceData.defaultVisibility.tasks"
            label="Visibilitas Task"
          />
        </v-col>
      </v-row> -->
      <div class="mt-6 ml-auto">
        <v-btn
          color="primary"
          :loading="loadingUpdateWorkspace"
          :disabled="loadingUpdateWorkspace"
          @click="updateWorkspace"
        >
          Update Workspace
        </v-btn>
      </div>
    </v-form>
    <div v-if="role ? role.id === 1 : false">
      <v-divider class="my-6" />
      <div class="d-flex flex-column flex-md-row">
        <div>
          <h4 class="mb-1 text-error">
            Hapus Workspace
          </h4>
          <span>Hapus workspace beserta seluruh data di dalamnya. Data yang dihapus tidak dapat dikembalikan.</span>
        </div>
        <div class="mt-4 mt-md-0 ml-md-auto">
          <v-btn
            small
            color="error"
            outlined
            :loading="loading"
            @click="deleteWorkspaceFunc"
          >
            Hapus Workspace
          </v-btn>
        </div>
      </div>
      <v-divider class="my-6" />
    </div>
  </v-card-text>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import {
  mdiImage, mdiHelpCircleOutline,
} from '@mdi/js'
import Vue from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { required, alphaDashValidator, maxLength } from '@core/utils/validation'
import { apolloClient } from '@/vue-apollo'
import { myRole } from '@/graphql/queries'
import { deleteWorkspace, identifierIDChecker } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import router from '@/router'
import useWorkspace from '@/composables/useWorkspace'
// import VisibilitySelect from '@/components/inputs/VisibilitySelect.vue'

export default {
  name: 'WorkspaceSetting',
  // components: {
  //   VisibilitySelect,
  // },
  props: {
    workspace: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props, { emit }) {
    const workspaceData = ref({
      id: null,
      name: '',
      identifier: '',
      photo: null,
    })

    const { changeWorkspaceProfile, loadingUpdateWorkspace } = useWorkspace()

    const userData = computed(() => store.state.auth.userData)
    const isMyWorkspace = computed(() => props.workspace.owner.id === userData.value.id)
    const identifierID = ref('')
    const isIDApproved = ref(false)
    const isIDError = ref(false)
    const loading = ref(false)
    const workspaceForm = ref()
    const errorPhoto = ref('')
    const role = ref()

    const fetchRole = () => {
      loading.value = true
      apolloClient.query({
        query: myRole,
        variables: {
          workspace_id: workspaceData.value.id,
        },
      }).then(({ data }) => {
        role.value = data.myRole
        loading.value = false
      }).catch(err => {
        loading.value = false
        errorHandling(err)
      })
    }
    const checkID = useDebounceFn(() => {
      if (identifierID.value !== workspaceData.value.identifier) {
        isIDApproved.value = false
        isIDError.value = false
        apolloClient.mutate({
          mutation: identifierIDChecker,
          variables: {
            identifier_id: workspaceData.value.identifier,
          },
        }).then(result => {
          if (result.data.identifierIDChecker.status === 'success') {
            isIDApproved.value = true
            isIDError.value = false
          } else {
            isIDApproved.value = false
            isIDError.value = true
          }
        })
      } else {
        isIDApproved.value = true
        isIDError.value = false
      }
    }, 1000)
    onMounted(() => {
      identifierID.value = props.workspace.identifier_id
      workspaceData.value = {
        id: props.workspace.id,
        name: props.workspace.name,
        identifier: props.workspace.identifier_id,
        photo: null,
        photoPreview: props.workspace.photo,
        defaultVisibility: {
          job: props.workspace.defaultVisibilityId_job,
          customer: props.workspace.defaultVisibilityId_customer,
          tasks: props.workspace.defaultVisibilityId_tasks,
        },
      }
      fetchRole()
    })
    const onFileChange = files => {
      errorPhoto.value = ''

      // check for error photo
      if (files.length) {
        if (!files[0].type.includes('image')) errorPhoto.value = 'File tidak valid'
        if (files[0].size / 1000 > 2048) errorPhoto.value = 'Ukuran melebihi batas. Maksimum 2mb.'

        workspaceData.value.photoPreview = URL.createObjectURL(files[0])

        // eslint-disable-next-line prefer-destructuring
        workspaceData.value.photo = files[0]
      }
    }

    const updateWorkspace = () => {
      if (workspaceForm.value.validate() && !errorPhoto.value.length && !isIDError.value) {
        const {
          id, name, identifier, photo,
        } = workspaceData.value

        changeWorkspaceProfile(id, { name, identifier, photo })
      } else {
        Vue.notify({
          type: 'warn',
          title: 'Tidak Valid',
          text: 'Harap cek kembali data anda',
        })
      }
    }
    // const update = () => {
    //   if (workspaceForm.value.validate() && !errorPhoto.value.length && !isIDError.value) {
    //     const {
    //       id, name, identifier, photo, defaultVisibility,
    //     } = workspaceData.value
    //     loading.value = true
    //     apolloClient.mutate({
    //       mutation: updateWorkspace,
    //       variables: {
    //         name,
    //         identifier_id: identifier,
    //         photo,
    //         defaultVisibilityId_tasks: defaultVisibility.tasks,
    //         defaultVisibilityId_job: defaultVisibility.job,
    //         defaultVisibilityId_customer: defaultVisibility.customer,
    //         job_digit_counter: 3,
    //         job_reset_count: 'Bulan',
    //         workspace_id: id,
    //       },
    //     }).then(() => {
    //       loading.value = false
    //       Vue.notify({
    //         title: 'Sukses!',
    //         text: 'Berhasil mengupdate workspace!',
    //       })
    //     }).catch(err => {
    //       loading.value = false
    //       errorHandling(err, 'Update Workspace')
    //     })
    //   } else {
    //     Vue.notify({
    //       type: 'warn',
    //       title: 'Tidak Valid',
    //       text: 'Harap cek kembali data anda',
    //     })
    //   }
    // }

    const deleteWorkspaceFunc = () => {
      Vue.$dialog({
        title: 'Hapus workspace?',
        body: 'Data yang dihapus tidak dapat dikembalikan',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: deleteWorkspace,
            variables: {
              workspace_id: workspaceData.value.id,
            },
          }).then(() => {
            loading.value = false
            store.dispatch('getProfileInfo').then(() => {
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil menghapus workspace!',
              })
              emit('onDelete')
              router.push(`/${store.state.workspace.workspaceList[0].workspace.identifier_id}`)
            })
          }).catch(err => {
            loading.value = false
            errorHandling(err, 'Hapus Workspace')
          })
        }
      })
    }

    return {
      isIDApproved,
      isIDError,
      errorPhoto,
      workspaceData,
      workspaceForm,
      loading,
      onFileChange,
      deleteWorkspaceFunc,
      role,
      checkID,
      icons: {
        mdiImage,
        mdiHelpCircleOutline,
      },

      // validations
      required,
      alphaDashValidator,
      maxLength,
      isMyWorkspace,
      userData,
      updateWorkspace,
      loadingUpdateWorkspace,
    }
  },
}
</script>

<style>

</style>
