import gql from 'graphql-tag'

const addJobStatus = gql`
  mutation(
    $workspace_id: Float!
    $job_type: Float!
    $job_status: [paramAddJobStatus!]!
  ) {
    addJobStatus(
      workspace_id: $workspace_id
      job_type: $job_type
      job_status: $job_status
    ) {
      status
      msg
      data
    }
  }
`

export default addJobStatus
