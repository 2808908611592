const { default: gql } = require('graphql-tag')

const deleteWorkspaceToken = gql`
mutation deleteWorkspaceToken($id: Float! $workspace_id: Float!) {
  deleteWorkspaceToken(id: $id workspace_id: $workspace_id) {
    status
    msg
    data
  }
}
`
export default deleteWorkspaceToken
