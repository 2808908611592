import gql from 'graphql-tag'

const getCalendarList = gql`
  query getCalendarList(
    $refresh_token: String!
  ) {
    getCalendarList(
      refresh_token: $refresh_token
    ) {
      etag
      items {
        accessRole
        backgroundColor
        colorId
        deleted
        description
        etag
        foregroundColor
        hidden
        id
        kind
        location
        primary
        selected
        summary
        summaryOverride
        timeZone
      }
      kind
    }
  }
`

export default getCalendarList
