import gql from 'graphql-tag'

const addFolder = gql`
  mutation addFolder(
    $name: String!,
    $color: String!,
    $is_public: Boolean!,
    $user_permissions: [UserFolderPermission!]!,
    $workspace_id: Float!
  ) {
    addFolder(
      name: $name,
      color: $color,
      is_public: $is_public,
      user_permissions: $user_permissions,
      workspace_id: $workspace_id
    ) {
      msg
      status
    }
  }
`

export default addFolder
