import gql from 'graphql-tag'

const updateSavedView = gql`
  mutation (
    $saved_view_id: Float!
    $name: String!
    $fields: [JSON!]!
  ) {
    updateSavedView(
      saved_view_id: $saved_view_id
      name: $name
      fields: $fields
    ) {
      status
      msg
    }
  }
`

export default updateSavedView
