import gql from 'graphql-tag'

const referenceLogs = gql`
  query referenceLogs(
    $category_id: Float
    $created_by: [Int!]
    $job_id: Float
    $task_id: Float
    $pagination: pagination!,
) { 
    referenceLogs (
      pagination: $pagination,
      category_id: $category_id
      created_by: $created_by
      job_id: $job_id
      task_id: $task_id
    ) {
      count
      referenceLogs {
        id
        category_id
        created_at
        custom_attribute {
          id
          name
          data_type {
            id
          }
        }
        created_by {
          id
          name
        }
        from
        to
        from_value
        to_value
      }
    }
  }
`

export default referenceLogs
