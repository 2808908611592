import gql from 'graphql-tag'

const unpinChannel = gql`
  mutation (
    $channel_id: Float!
  ) {
    unpinChannel (
      channel_id: $channel_id
    ) {
      status
      msg
      data
    }
  }
`

export default unpinChannel
