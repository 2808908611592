<template>
  <v-card-text class="d-flex flex-column align-start h-full">
    <PerfectScrollbar
      :options="{useBothWheelAxes:true}"
      class="chat-setting-list mb-6 mt-2"
    >
      <v-card
        class="pa-3 mr-2 chat-setting-list-card"
        outlined
        @click="$refs.chatAutoReply.show()"
      >
        <h4 class="mb-2">
          Autoreply
        </h4>
        <p class="text-caption mb-0">
          Atur balasan pesan otomatis ketika pelanggan mengirimkan pesan di luar jam kerja.
        </p>
      </v-card>
      <v-card
        class="pa-2 chat-setting-list-card"
        outlined
        @click="$refs.chatCommand.show()"
      >
        <h4 class="mb-2">
          Chat Bot Command
        </h4>
        <p class="text-caption">
          Atur command yang dapat dipakai pelanggan.
        </p>
      </v-card>
    </PerfectScrollbar>

    <h3>Jam Operasional</h3>

    <v-select
      v-model="selectedTimeZone"
      :items="timeZone"
      placeholder="Zona Waktu"
      label="Zona Waktu"
      class="mt-4"
      outlined
      hide-details
      dense
      item-value="data"
      item-text="name"
    >
      <template #item="{ item }">
        <div class="d-flex flex-column my-1">
          <span class="text-subtitle-2">{{ item.name }}</span>
          <span class="text-caption text--disabled">{{ item.description }}</span>
        </div>
      </template>
    </v-select>

    <v-row
      class="w-full mt-4 mb-4"
      dense
    >
      <v-col
        v-for="day in daily"
        :key="day.day"
        cols="12"
        md="4"
        lg="3"
      >
        <v-card
          outlined
          class="pa-2 pt-1"
        >
          <span class="text-caption primary--text">
            {{ day.name }}
          </span>
          <div class="d-flex">
            <v-menu
              :close-on-content-click="false"
              min-width="280px"
              max-width="280px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-sheet
                  class="w-full d-flex flex-column mr-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="text-avatar-small text--disabled">Buka</span>
                  <span class="text-subtitle-2 font-medium">{{ day.start }}</span>
                </v-sheet>
              </template>
              <v-card>
                <v-time-picker
                  v-model="day.start"
                  locale="id-ID"
                  full-width
                  format="24hr"
                />
              </v-card>
            </v-menu>
            <v-menu
              :close-on-content-click="false"
              min-width="280px"
              max-width="280px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-sheet
                  class="w-full d-flex flex-column mr-1"
                  v-bind="attrs"
                  height="36px"
                  v-on="on"
                >
                  <span class="text-avatar-small text--disabled">Tutup</span>
                  <span class="text-subtitle-2 font-medium">{{ day.end ? day.end : '' }}</span>
                </v-sheet>
              </template>
              <v-card>
                <v-time-picker
                  v-model="day.end"
                  locale="id-ID"
                  full-width
                  format="24hr"
                />
              </v-card>
            </v-menu>
          </div>
          <span
            v-if="day.error"
            class="error--text text-avatar-small"
          >{{ day.error }}</span>
        </v-card>
      </v-col>
    </v-row>

    <v-card
      class="pa-3 mb-2 d-flex flex-column"
      outlined
      max-width="360px"
      @click="autoreply = !autoreply"
    >
      <div class="d-flex justify-space-between align-start mb-1">
        <span class="text-subtitle-2">Autoreply</span>
        <v-switch
          v-model="autoreply"
          hide-details
          dense
          class="ma-0 mr-n1 mt-n1"
          @click.stop
        />
      </div>
      <span class="text-caption text--disabled">
        Kirimkan pesan otomatis / autoreply jika ada pelanggan yang mengirimkan pesan di luar jam kerja.
      </span>
    </v-card>

    <v-btn
      color="primary"
      class="mt-2"
      :loading="loadingSubmit"
      @click="submit"
    >
      Update Jam Operasional
    </v-btn>

    <chat-command
      ref="chatCommand"
    />

    <chat-auto-reply
      ref="chatAutoReply"
    />
  </v-card-text>
</template>

<script>
import Vue from 'vue'
import { ref, onMounted } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import ChatCommand from '@/views/chat/components/ChatCommand.vue'
import ChatAutoReply from '@/views/chat/components/ChatAutoReply.vue'
import { apolloClient } from '@/vue-apollo'
import { updateWorkingHours } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

export default {
  components: {
    PerfectScrollbar,
    ChatCommand,
    ChatAutoReply,
  },
  props: {
    workspace: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props) {
    const loadingSubmit = ref(false)
    const daily = ref([
      {
        day: 1,
        name: 'Senin',
        start: null,
        end: null,
        error: '',
      },
      {
        day: 2,
        name: 'Selasa',
        start: null,
        end: null,
        error: '',
      },
      {
        day: 3,
        name: 'Rabu',
        start: null,
        end: null,
        error: '',
      },
      {
        day: 4,
        name: 'Kamis',
        start: null,
        end: null,
        error: '',
      },
      {
        day: 5,
        name: 'Jumat',
        start: null,
        end: null,
        error: '',
      },
      {
        day: 6,
        name: 'Sabtu',
        start: null,
        end: null,
        error: '',
      },
      {
        day: 7,
        name: 'Minggu',
        start: null,
        end: null,
        error: '',
      },
    ])

    const autoreply = ref(false)

    const selectedTimeZone = ref('asia/jakarta')
    const timeZone = [
      {
        name: 'WIB',
        description: 'Waktu Indonesia Barat',
        data: 'asia/jakarta',
      },
      {
        name: 'WITA',
        description: 'Waktu Indonesia Tengah',
        data: 'asia/makassar',
      },
      {
        name: 'WIT',
        description: 'Waktu Indonesia Timur',
        data: 'asia/jayapura',
      },
    ]

    const submit = () => {
      // validation
      let hasError = false

      daily.value.forEach((day, index) => {
        daily.value[index].error = ''
        if (day.start > day.end) {
          daily.value[index].error = 'Jam tutup tidak boleh lebih kecil dari jam buka'
          hasError = true
        }
      })

      if (!hasError) {
        loadingSubmit.value = true

        const workingHours = daily.value.map(el => ({
          day: el.day,
          start: el.start,
          end: el.end,
        }))

        apolloClient.mutate({
          mutation: updateWorkingHours,
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            autoreply: autoreply.value,
            timezone: selectedTimeZone.value,
            working_hours: JSON.stringify(workingHours),
          },
        }).then(() => {
          loadingSubmit.value = false
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil mengubah jam operasional!',
          })
        }).catch(err => {
          loadingSubmit.value = false
          errorHandling(err)
        })
      }
    }

    onMounted(() => {
      if (props.workspace.timezone) {
        selectedTimeZone.value = props.workspace.timezone
      }

      autoreply.value = props.workspace.out_of_working_hours_autoreply

      if (props.workspace.working_hours) {
        const dayName = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu']

        daily.value = JSON.parse(props.workspace.working_hours).map(el => ({
          ...el,
          name: dayName[el.day - 1],
          error: '',
        }))
      }
    })

    return {
      daily,
      autoreply,

      selectedTimeZone,
      timeZone,
      loadingSubmit,

      submit,
    }
  },
}
</script>

<style lang="scss" scoped>
.chat-setting-list {
  display: flex;
  overflow-x: auto;

  .chat-setting-list-card {
    flex-shrink: 0;
    width: 320px;
    margin-right: 12px;
  }
}
</style>
