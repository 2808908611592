import gql from 'graphql-tag'

const deleteAccurateToken = gql`
  mutation deleteAccurateToken(
    $token_id: Float!
    $workspace_id: Float!
  ) {
    deleteAccurateToken(
      token_id: $token_id
      workspace_id: $workspace_id
    ) {
      status
      msg
    }
  }
`

export default deleteAccurateToken
