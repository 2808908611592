import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    activeTabKanban: 0,
    jobPriority: [],
    jobFilter: {
      pagination: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      sort: {
        label: 'Tanggal Ditutup Terlama',
        field: 'expected_close_date',
        type: 'ASC',
      },
      group: {
        label: 'Status',
        field: 'status',
      },
      groupOrder: 'ASC',
      search: '',
      created_by: [],
      assigned_to: [],
      status: [],
      types: [],
      customer: [],
      priority: [],
      raw_filter: [],
      custom_filter: [],
      search_field: [
        {
          field: 'name',
          label: 'Nama',
          value: true,
        },
        {
          field: 'description',
          label: 'Deskripsi',
          value: true,
        },
        {
          field: 'created_by',
          label: 'Dibuat Oleh',
          value: true,
        },
        {
          field: 'customer',
          label: 'Pelanggan',
          value: false,
        },
        {
          field: 'assigned_to',
          label: 'Ditugaskan ke',
          value: false,
        },
        {
          field: 'custom_attribute_values',
          label: 'Custom Field',
          value: false,
        },
      ],
      updated_at: [],
      expected_close_date: [],
    },
    jobTypeFilter: {
      my_jobType: {
        id: 1,
        text: 'All',
        value: null,
      },
      show_archived: {
        id: 2,
        text: 'Hide',
        value: false,
      },
      sort: {
        id: 1,
        text: 'Nama (A-Z)',
        value: 'name',
        sortType: 'ASC',
      },
      search: '',
    },
    jobTypes: [],
    jobStatus: [],
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {
    resetFilter(state) {
      state.state.jobFilter = {
        ...state.state.jobFilter,
        search: '',
        raw_filter: [],
        custom_filter: [],
        created_by: [],
        assigned_to: [],
        status: [],
        customer: [],
        priority: [],
        updated_at: [],
        expected_close_date: [],
      }
    },
    resetJobTypeFilter(state) {
      state.state.jobTypeFilter = {
        my_jobType: {
          id: 1,
          text: 'All',
          value: null,
        },
        show_archived: {
          id: 2,
          text: 'Hide',
          value: false,
        },
        sort: {
          id: 1,
          text: 'Nama (A-Z)',
          value: 'name',
          sortType: 'ASC',
        },
        search: '',
      }
    },
  },
}
