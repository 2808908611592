import gql from 'graphql-tag'

const joinWorkspace = gql`
  mutation(
    $invite_code: String!
  ) {
    joinWorkspace(
      invite_code: $invite_code
    ) {
      status
      msg
      data
    }
  }
`

export default joinWorkspace
