import gql from 'graphql-tag'

const savedViews = gql`
  query savedViews(
    $workspace_id: Float!
    $object_type_id: Float!
  ) {
    savedViews(
      workspace_id: $workspace_id
      object_type_id: $object_type_id
    ) {
      saved_view {
        id
        name
        is_selected
        fields
        object_type {
          id
          name
        }
      }
      saved_default_view {
        id
        name
        fields
        is_default
        object_type {
          id
          name
        }
        is_selected
      }
    }
  }
`

export default savedViews
