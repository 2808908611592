import gql from 'graphql-tag'

const createSubsTransaction = gql`
  mutation createSubsTransaction(
    $param: paramCreateTransaction!
  ) {
    createSubsTransaction(
      param: $param
    ) {
      status
      msg
      data
    }
  }
`

export default createSubsTransaction
