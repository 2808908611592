import gql from 'graphql-tag'

const changeCustomAttributeValue = gql`
  mutation updateCustomAttributeValue(
    $objectId: Float!,
    $typeId: Float!,
    $values: [customAttributeValues!]!
  ) {
    updateCustomAttributeValue(
      objectId: $objectId,
      typeId: $typeId,
      values: $values
    ) {
    status
    msg
    data
  }
}
`

export default changeCustomAttributeValue
