import gql from 'graphql-tag'

const deleteSavedView = gql`
  mutation (
    $saved_view_id: Float!
  ) {
    deleteSavedView(
      saved_view_id: $saved_view_id
    ) {
      status
      msg
    }
  }
`

export default deleteSavedView
