import gql from 'graphql-tag'

const deletedProspect = gql`
query deletedProspect(
  $pagination: pagination!
  $search: String
  $workspace_id: Float!
  ){
  deletedProspects(
    pagination: $pagination
    search:$search
    workspace_id:$workspace_id
  ){
    count
    prospects{
      id
      name
      description
      status{
        id
        name
      }
      customer{
        id
        name
      }
      created_by{
        id
        email
        name
      }
      workspace{
        id
      }
      lexorank
      created_at
      updated_at
      deleted_at
    }
  }
}

`
export default deletedProspect
