import gql from 'graphql-tag'

const unarchiveThread = gql`
  mutation (
    $thread_id: Float!
  ) {
    unarchiveThread(
      thread_id: $thread_id
    ) {
      status
      msg
      data
    }
  }
`

export default unarchiveThread
