import gql from 'graphql-tag'

const rejectRequestInvite = gql`
  mutation (
    $user_id: Float!
    $team_id: Float!
    $workspace_id: Float!
  ) {
    rejectRequestInvite(
      user_id: $user_id
      team_id: $team_id
      workspace_id: $workspace_id
    ) {
      data
      msg
      status
    }
  }
`

export default rejectRequestInvite
