import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,{staticClass:"d-flex flex-column align-start h-full"},[_c('PerfectScrollbar',{staticClass:"chat-setting-list mb-6 mt-2",attrs:{"options":{useBothWheelAxes:true}}},[_c(VCard,{staticClass:"pa-3 mr-2 chat-setting-list-card",attrs:{"outlined":""},on:{"click":function($event){return _vm.$refs.chatAutoReply.show()}}},[_c('h4',{staticClass:"mb-2"},[_vm._v(" Autoreply ")]),_c('p',{staticClass:"text-caption mb-0"},[_vm._v(" Atur balasan pesan otomatis ketika pelanggan mengirimkan pesan di luar jam kerja. ")])]),_c(VCard,{staticClass:"pa-2 chat-setting-list-card",attrs:{"outlined":""},on:{"click":function($event){return _vm.$refs.chatCommand.show()}}},[_c('h4',{staticClass:"mb-2"},[_vm._v(" Chat Bot Command ")]),_c('p',{staticClass:"text-caption"},[_vm._v(" Atur command yang dapat dipakai pelanggan. ")])])],1),_c('h3',[_vm._v("Jam Operasional")]),_c(VSelect,{staticClass:"mt-4",attrs:{"items":_vm.timeZone,"placeholder":"Zona Waktu","label":"Zona Waktu","outlined":"","hide-details":"","dense":"","item-value":"data","item-text":"name"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column my-1"},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(_vm._s(item.description))])])]}}]),model:{value:(_vm.selectedTimeZone),callback:function ($$v) {_vm.selectedTimeZone=$$v},expression:"selectedTimeZone"}}),_c(VRow,{staticClass:"w-full mt-4 mb-4",attrs:{"dense":""}},_vm._l((_vm.daily),function(day){return _c(VCol,{key:day.day,attrs:{"cols":"12","md":"4","lg":"3"}},[_c(VCard,{staticClass:"pa-2 pt-1",attrs:{"outlined":""}},[_c('span',{staticClass:"text-caption primary--text"},[_vm._v(" "+_vm._s(day.name)+" ")]),_c('div',{staticClass:"d-flex"},[_c(VMenu,{attrs:{"close-on-content-click":false,"min-width":"280px","max-width":"280px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VSheet,_vm._g(_vm._b({staticClass:"w-full d-flex flex-column mr-1"},'v-sheet',attrs,false),on),[_c('span',{staticClass:"text-avatar-small text--disabled"},[_vm._v("Buka")]),_c('span',{staticClass:"text-subtitle-2 font-medium"},[_vm._v(_vm._s(day.start))])])]}}],null,true)},[_c(VCard,[_c(VTimePicker,{attrs:{"locale":"id-ID","full-width":"","format":"24hr"},model:{value:(day.start),callback:function ($$v) {_vm.$set(day, "start", $$v)},expression:"day.start"}})],1)],1),_c(VMenu,{attrs:{"close-on-content-click":false,"min-width":"280px","max-width":"280px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VSheet,_vm._g(_vm._b({staticClass:"w-full d-flex flex-column mr-1",attrs:{"height":"36px"}},'v-sheet',attrs,false),on),[_c('span',{staticClass:"text-avatar-small text--disabled"},[_vm._v("Tutup")]),_c('span',{staticClass:"text-subtitle-2 font-medium"},[_vm._v(_vm._s(day.end ? day.end : ''))])])]}}],null,true)},[_c(VCard,[_c(VTimePicker,{attrs:{"locale":"id-ID","full-width":"","format":"24hr"},model:{value:(day.end),callback:function ($$v) {_vm.$set(day, "end", $$v)},expression:"day.end"}})],1)],1)],1),(day.error)?_c('span',{staticClass:"error--text text-avatar-small"},[_vm._v(_vm._s(day.error))]):_vm._e()])],1)}),1),_c(VCard,{staticClass:"pa-3 mb-2 d-flex flex-column",attrs:{"outlined":"","max-width":"360px"},on:{"click":function($event){_vm.autoreply = !_vm.autoreply}}},[_c('div',{staticClass:"d-flex justify-space-between align-start mb-1"},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Autoreply")]),_c(VSwitch,{staticClass:"ma-0 mr-n1 mt-n1",attrs:{"hide-details":"","dense":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.autoreply),callback:function ($$v) {_vm.autoreply=$$v},expression:"autoreply"}})],1),_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(" Kirimkan pesan otomatis / autoreply jika ada pelanggan yang mengirimkan pesan di luar jam kerja. ")])]),_c(VBtn,{staticClass:"mt-2",attrs:{"color":"primary","loading":_vm.loadingSubmit},on:{"click":_vm.submit}},[_vm._v(" Update Jam Operasional ")]),_c('chat-command',{ref:"chatCommand"}),_c('chat-auto-reply',{ref:"chatAutoReply"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }