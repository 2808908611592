import gql from 'graphql-tag'

const customerDetail = gql`
  query (
    $customer_id: Float! 
    $workspace_id: Float!
  ) {
    customerDetail(customer_id: $customer_id workspace_id: $workspace_id) {
      id
      name
      gender
      description
      address
      job_title
      birth_date
      phone_number
      whatsapp_number
      email
      instagram
      facebook
      city {
        id
        name
      }
      type {
        id
        name
        color
      }
      visibility {
        id
        name
        description
      }
      created_at
      updated_at
      deleted_at
      created_by {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
    }
  }
`

export default customerDetail
