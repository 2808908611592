import gql from 'graphql-tag'

const updateWorkingHours = gql`
  mutation updateWorkingHours(
    $workspace_id: Float!
    $autoreply: Boolean!
    $timezone: String!
    $working_hours: String!
  ) {
    updateWorkingHours(
      workspace_id: $workspace_id
      out_of_working_hours_autoreply: $autoreply
      timezone: $timezone
      working_hours: $working_hours
    ) {
      status
      msg
      data
    }
  }
`

export default updateWorkingHours
