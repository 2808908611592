import gql from 'graphql-tag'

const customAttributeList = gql`
  query customAttributeList(
    $workspace_id: Float!
    $object_type_id: Float!
    $job_type_id: Float!
  ) {
    customAttributeList(
      workspace_id: $workspace_id
      object_type_id: $object_type_id
      job_type_id: $job_type_id
    ) {
      id
      name
      data_type {
        id
        name
      }
      options {
        id
        name
      }
    }
  }
`

export default customAttributeList
