import { joinWorkspace, quitWorkspace, updateWorkspaceProfile } from '@/graphql/mutations'
import { workspaceDetail as getWorkspaceDetail } from '@/graphql/queries'
import invitationPage from '@/graphql/query/invitationPage'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { ref } from '@vue/composition-api'
import Vue from 'vue'

const useWorkspace = () => {
  const loadingInvitationPage = ref(false)
  const loadingJoinWorkspace = ref(false)
  const loadingWorkspaceDetail = ref(false)
  const loadingUpdateWorkspace = ref(false)
  const loadingQuitWorkspace = ref(false)

  const workspaceData = ref({})
  const workspaceDetail = ref({})

  const WorkspacePermissionGuard = (data, role) => {
    console.log(data.workspace.owner.id)
    console.log(store.getters.getUserData.id)
    console.log()
    if (data.workspace.owner.id === store.getters.getUserData.id) return true
    if (data.role.id <= role) return true

    return false
  }

  const getInvitationPage = inviteCode => {
    loadingInvitationPage.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: invitationPage,
        variables: {
          invite_code: inviteCode,
        },
      }).then(({ data }) => {
        loadingInvitationPage.value = false
        resolve(data.invitationPage)
        workspaceData.value = data.invitationPage
      }).catch(err => {
        loadingInvitationPage.value = false
        reject(err)
        errorHandling(err)
      })
    })
  }

  const joinToWorkspace = inviteCode => {
    loadingJoinWorkspace.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: joinWorkspace,
        variables: {
          invite_code: inviteCode,
        },
      }).then(({ data }) => {
        loadingJoinWorkspace.value = false
        resolve(data.joinWorkspace)
      }).catch(err => {
        loadingJoinWorkspace.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const fetchWorkflowDetail = workspaceId => {
    loadingWorkspaceDetail.value = true

    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: getWorkspaceDetail,
        variables: {
          workspace_id: workspaceId,
        },
      }).then(({ data }) => {
        loadingWorkspaceDetail.value = false
        workspaceDetail.value = data.workspaceDetail
        resolve(data.workspaceDetail)
      }).catch(err => {
        loadingWorkspaceDetail.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const changeWorkspaceProfile = (workspaceId, param) => {
    loadingUpdateWorkspace.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: updateWorkspaceProfile,
        variables: {
          workspace_id: workspaceId,
          param,
        },
      }).then(({ data }) => {
        loadingUpdateWorkspace.value = false
        resolve(data.updateWorkspaceProfile)
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah data workspace!',
        })
      }).catch(err => {
        loadingUpdateWorkspace.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  const quitFromWorkspace = workspaceId => {
    loadingQuitWorkspace.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: quitWorkspace,
        variables: {
          workspace_id: workspaceId,
        },
      }).then(result => {
        loadingQuitWorkspace.value = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil keluar dari workspace!',
        })
        resolve(result)
      }).catch(err => {
        loadingQuitWorkspace.value = false
        errorHandling(err)
        reject(err)
      })
    })
  }

  return {
    WorkspacePermissionGuard,

    getInvitationPage,
    loadingInvitationPage,
    workspaceData,

    joinToWorkspace,
    loadingJoinWorkspace,

    fetchWorkflowDetail,
    loadingWorkspaceDetail,
    workspaceDetail,

    changeWorkspaceProfile,
    loadingUpdateWorkspace,

    quitFromWorkspace,
    loadingQuitWorkspace,
  }
}

export default useWorkspace
