import gql from 'graphql-tag'

const readTelegramChat = gql`
  mutation (
    $message_id: [Float!]!
    $roomId: Float!
    $workspace_id: Float!
  ) {
    readTelegramChat(
      message_id: $message_id
      room_id: $roomId
      workspace_id: $workspace_id
    ) {
      status
      msg
    }
  }
`

export default readTelegramChat
