import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"items":_vm.items,"label":_vm.label,"outlined":"","item-value":"id","item-text":"name","dense":"","attach":_vm.attach,"return-object":_vm.returnObject,"hide-details":"auto","multiple":_vm.multiple,"rules":_vm.inputRules,"filter":_vm.filter,"clearable":_vm.clearable},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2 text-subtitle-2 text--disabled text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Pengguna tidak ditemukan. ")])])]},proxy:true},{key:"item",fn:function(data){return [_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"primary"}},[(data.item.photo)?_c(VImg,{attrs:{"src":data.item.photo}}):_c('span',[_vm._v(_vm._s(_vm.avatarText(data.item.name)))])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(data.item.name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(data.item.email))]),_c(VListItemSubtitle,[_vm._v(_vm._s(data.item.phone_number))])],1)]}},(_vm.pill)?{key:"selection",fn:function(data){return [_c(VChip,{staticClass:"mt-1",attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.remove(data.item.id)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}:null,{key:"append-outer",fn:function(){return [_vm._t("match")]},proxy:true}],null,true),model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}})}
var staticRenderFns = []

export { render, staticRenderFns }