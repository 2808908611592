import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,{staticClass:"d-flex flex-column h-full"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Utama ")]),_c(VForm,{ref:"workspaceForm",staticClass:"d-flex flex-column",attrs:{"lazy-validation":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"auto"}},[_c('span',{staticClass:"text-caption mb-1"},[_vm._v("Icon Workspace")]),_c(VCard,{staticClass:"mt-1 d-flex align-center justify-center",attrs:{"width":"110","height":"110","outlined":""},on:{"click":function($event){return _vm.$refs.workspaceIconPicker.click()}}},[(_vm.workspaceData.photoPreview)?_c(VImg,{staticClass:"rounded",attrs:{"src":_vm.workspaceData.photoPreview,"height":"110","width":"110"}}):_c(VIcon,{staticClass:"text--disabled",attrs:{"size":"32"}},[_vm._v(" "+_vm._s(_vm.icons.mdiImage)+" ")])],1),_c('input',{ref:"workspaceIconPicker",staticClass:"d-none",attrs:{"type":"file","accept":".jpg, .jpeg, .png"},on:{"change":function($event){return _vm.onFileChange($event.target.files)}}})],1),_c(VCol,{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"8"}},[_c('span',{staticClass:"text-caption mb-1"},[_vm._v("Nama Workspace")]),_c(VTextField,{staticClass:"mb-2",attrs:{"rules":[_vm.required],"hide-details":"auto","placeholder":"Input nama workspace","outlined":"","dense":""},model:{value:(_vm.workspaceData.name),callback:function ($$v) {_vm.$set(_vm.workspaceData, "name", $$v)},expression:"workspaceData.name"}}),_c('span',{staticClass:"text-caption mb-1"},[_vm._v("Workspace ID")]),_c(VTextField,{attrs:{"rules":[
            _vm.required,
            _vm.alphaDashValidator,
            _vm.maxLength(_vm.workspaceData.identifier, 20) ],"placeholder":"Input Workspace ID","outlined":"","dense":"","hide-details":"auto","error":_vm.isIDError && !_vm.isIDApproved,"success":_vm.isIDApproved && !_vm.isIDError,"error-messages":_vm.isIDError && !_vm.isIDApproved ? 'ID workspace tidak tersedia' : null},on:{"keyup":_vm.checkID},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VMenu,{attrs:{"open-on-hover":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"text--disabled"},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiHelpCircleOutline)+" ")])]}}])},[_c(VCard,{staticClass:"pa-3 d-flex flex-column",attrs:{"max-width":"300"}},[_c('span',{staticClass:"text-caption font-medium mb-2"},[_vm._v("Workspace ID")]),_c('span',{staticClass:"text-caption"},[_vm._v("Workspace ID digunakan untuk mengatur alamat URL unik dari workspace mu.")])])],1)]},proxy:true}]),model:{value:(_vm.workspaceData.identifier),callback:function ($$v) {_vm.$set(_vm.workspaceData, "identifier", $$v)},expression:"workspaceData.identifier"}})],1)],1),_c('span',{staticClass:"text-caption error--text mb-1"},[_vm._v(_vm._s(_vm.errorPhoto))]),_c('div',{staticClass:"mt-6 ml-auto"},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.loadingUpdateWorkspace,"disabled":_vm.loadingUpdateWorkspace},on:{"click":_vm.updateWorkspace}},[_vm._v(" Update Workspace ")])],1)],1),(_vm.role ? _vm.role.id === 1 : false)?_c('div',[_c(VDivider,{staticClass:"my-6"}),_c('div',{staticClass:"d-flex flex-column flex-md-row"},[_c('div',[_c('h4',{staticClass:"mb-1 text-error"},[_vm._v(" Hapus Workspace ")]),_c('span',[_vm._v("Hapus workspace beserta seluruh data di dalamnya. Data yang dihapus tidak dapat dikembalikan.")])]),_c('div',{staticClass:"mt-4 mt-md-0 ml-md-auto"},[_c(VBtn,{attrs:{"small":"","color":"error","outlined":"","loading":_vm.loading},on:{"click":_vm.deleteWorkspaceFunc}},[_vm._v(" Hapus Workspace ")])],1)]),_c(VDivider,{staticClass:"my-6"})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }