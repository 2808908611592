import gql from 'graphql-tag'

const requestInviteToTeam = gql`
  mutation RequestInviteToTeam(
    $team_id: Float!
  ) {
    requestInviteToTeam(
      team_id: $team_id
    ) {
      status
      msg
      data
    }
  }
`

export default requestInviteToTeam
