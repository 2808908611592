import gql from 'graphql-tag'

const toggleNotificationReference = gql`
  mutation toggleNotificationReference(
    $is_subscribe: Boolean!
    $job_id: Float
    $prospect_id: Float
    $task_id: Float
    $workspace_id: Float!
  ) {
    toggleNotificationReference(
      is_subscribe: $is_subscribe
      job_id: $job_id
      prospect_id: $prospect_id
      task_id: $task_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default toggleNotificationReference
