<template>
  <v-card-text class="d-flex flex-column h-full">
    <div class="d-flex align-start mb-4">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <span
            class="text-subtitle-1 font-weight-bold"
            v-on="on"
          >
            Sisa Kuota Automasi Bulan ini: {{ userData.kuota_automasi }} kali
          </span>
        </template>
        <div>Jika Kuota habis, maka workflow tidak akan dijalankan</div>
        <div>Kuota automasi diisi ulang setiap awal bulan</div>
      </v-tooltip>
    </div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <div class="text-caption-2 mb-1">
          Filter Tanggal
        </div>
        <DateRangePicker
          ref="dateRangePicker"
          v-model="filterDate"
          class="mb-4"
          :max-date="today"
          @update="changeFilterDate"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <div class="text-caption-2 mb-1">
          Filter Status
        </div>
        <v-select
          v-model="filterLog.status"
          :items="statusOptions"
          item-text="label"
          item-value="value"
          outlined
          dense
          flat
          hide-details="auto"
          @change="loadWorkflowLog"
        />
      </v-col>
    </v-row>
    <div>
      <v-chip
        v-if="filterLog.status !== null || filterLog.date_min || filterLog.date_max"
        class="mb-4"
        small
        close
        color="error"
        @click:close="resetFilter"
      >
        Reset Filter
      </v-chip>
    </div>

    <v-data-table
      item-key="id"
      height="52vh"
      fixed-header
      class="mt-4"
      :headers="tableColumns"
      :items="workflowLogList"
      mobile-breakpoint="0"
      :server-items-length="workflowLogCount"
      disable-sort
      :footer-props="{
        'items-per-page-options': [10, 25, 50],
        'items-per-page-text': 'Jumlah data',
      }"
      :options.sync="paginationLog"
      :loading="loadingLogWorkflow"
    >
      <template v-slot:[`item.executed_at`]="props">
        {{ formatDate(props.item.executed_at, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }) }}
      </template>
      <template v-slot:[`item.workflow`]="props">
        <v-list-item
          @click="goToWorkflowDetail(props.item.workspace_id, props.item.trigger_id)"
        >
          <a>{{ props.item.trigger_name }}</a>
        </v-list-item>
      </template>
      <template v-slot:[`item.workspace`]="props">
        <v-chip
          small
          @click="changeWorkspace(props.item.workspace_id, props.item.workspace_identifier)"
        >
          <span>{{ props.item.workspace_name }}</span>
        </v-chip>
      </template>
      <!-- <template v-slot:[`item.action`]="props">
        <span>{{ props.item.object_name }}</span>
        <v-icon
          size="16"
          class="mx-2"
        >
          {{ icons.mdiArrowRight }}
        </v-icon>
        <span>{{ props.item.action_name }}</span>
      </template> -->
      <template v-slot:[`item.status`]="props">
        <v-chip
          :color="props.item.is_error ? 'error' : 'success'"
          outlined
        >
          {{ props.item.is_error ? 'Error' : 'Success' }}
        </v-chip>
      </template>
      <template v-slot:[`item.message`]="props">
        <span>{{ props.item.message ? props.item.message : '-' }}</span>
      </template>
    </v-data-table>
  </v-card-text>
</template>
<script>
import { mdiArrowRight } from '@mdi/js'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import useAutomationV2 from '@/composables/useAutomation'
import { avatarText, formatDate } from '@core/utils/filter'
import store from '@/store'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import router from '@/router'

export default {
  components: {
    DateRangePicker,
  },
  setup(props, { emit }) {
    const today = new Date()
    const dateRangePicker = ref(null)
    const userData = computed(() => store.state.auth.userData)
    const filterDate = { startDate: null, endDate: null }
    const workspaces = computed(() => store.state.workspace.workspaceList
      .filter(el => el.user.id === userData.value.id))
    const {
      fetchWorkflowLog, loadingLogWorkflow, workflowLogList, workflowLogCount,
    } = useAutomationV2()

    const statusOptions = ref([
      { label: 'Semua', value: null },
      { label: 'Success', value: false },
      { label: 'Error', value: true },
    ])

    const changeWorkspace = workspaceId => {
      const workspace = workspaces.value.find(el => el.workspace.id === +workspaceId)
      if (workspace && router.currentRoute.params.workspace !== workspace.workspace.identifier_id) {
        setTimeout(() => {
          store.dispatch('setSelectedWorkspace', workspace)
          router.push({
            name: router.currentRoute.name,
            params: {
              workspace: workspace.workspace.identifier_id,
            },
          }).then(() => {
            emit('close')
          })
        }, 300)
      }
    }

    const goToWorkflowDetail = (workspaceId, triggerId) => {
      const workspace = workspaces.value.find(el => el.workspace.id === +workspaceId)
      if (workspace) {
        if (router.currentRoute.params.id === triggerId) {
          emit('close')
        } else {
          setTimeout(() => {
            store.dispatch('setSelectedWorkspace', workspace)
            router.push({
              params: {
                workspace: workspace.workspace.identifier_id,
                id: triggerId,
              },
              path: `/${workspace.workspace.identifier_id}/workflows/detail/${triggerId}`,
            }).then(() => {
              emit('close')
            })
          }, 300)
        }
      }
    }

    const tableColumns = ref([
      {
        text: 'Tanggal',
        value: 'executed_at',
      },
      {
        text: 'Workflow',
        value: 'workflow',
      },
      {
        text: 'Workspace',
        value: 'workspace',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Keterangan',
        value: 'message',
      },
    ])

    const paginationLog = ref({})

    const filterLog = ref({
      sort: 'executed_at', sort_type: 'DESC', date_min: null, date_max: null, status: null, created_by_id: userData.value.id,
    })

    const loadWorkflowLog = () => {
      fetchWorkflowLog({
        limit: paginationLog.value.itemsPerPage ? paginationLog.value.itemsPerPage : 10,
        offset: paginationLog.value.page ? (paginationLog.value.page - 1) * paginationLog.value.itemsPerPage : 0,
      }, filterLog.value, true)
    }

    const changeFilterDate = val => {
      filterLog.value = {
        ...filterLog.value,
        date_min: val.startDate ? val.startDate.toISOString() : null,
        date_max: val.endDate ? val.endDate.toISOString() : null,
      }
      loadWorkflowLog()
    }

    onMounted(() => {
      loadWorkflowLog()
    })

    const resetFilter = () => {
      filterLog.value = {
        sort: 'executed_at', sort_type: 'DESC', date_min: null, date_max: null, status: null, created_by_id: userData.value.id,
      }
      filterDate.value = { startDate: null, endDate: null }
      dateRangePicker.value.reset()
      loadWorkflowLog()
    }

    watch(paginationLog, () => {
      loadWorkflowLog()
    })

    return {
      today,
      tableColumns,

      loadingLogWorkflow,
      workflowLogList,
      workflowLogCount,

      paginationLog,
      loadWorkflowLog,

      filterLog,

      avatarText,
      formatDate,

      icons: {
        mdiArrowRight,
      },
      changeFilterDate,
      filterDate,
      statusOptions,
      dateRangePicker,
      resetFilter,
      changeWorkspace,
      goToWorkflowDetail,

      userData,
    }
  },
}
</script>
