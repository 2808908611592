import gql from 'graphql-tag'

const sendMessage = gql`
  mutation(
    $text: String!
    $file: [Upload!]
    $type: String!
    $reply_to: Float
    $room_id: Float!
    $workspace_id: Float!
  ) {
    sendMessage(
      text: $text
      file: $file
      type: $type
      reply_to: $reply_to
      room_id: $room_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default sendMessage
