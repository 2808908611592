<template>
  <v-card-text class="d-flex flex-column h-full">
    <div>
      <h3>Notifikasi</h3>
      <v-switch
        v-model="profileData.notify"
        inset
        flat
        hide-details
        label="Semua Notifikasi"
        class="my-5"
        @change="onChange(profileData.notify, 'notify')"
      />
      <v-switch
        v-model="profileData.notify_task_assignment"
        inset
        flat
        :disabled="!profileData.notify"
        hide-details
        label="Notifikasi Task"
        class="my-5"
        @change="onChange(profileData.notify_task_assignment, 'notify_task_assignment')"
      />
      <v-switch
        v-model="profileData.notify_comment"
        inset
        flat
        :disabled="!profileData.notify"
        hide-details
        label="Notifikasi Komen"
        class="my-5"
        @change="onChange(profileData.notify_comment, 'notify_comment')"
      />
      <v-switch
        v-model="profileData.notify_job_assignment"
        inset
        flat
        :disabled="!profileData.notify"
        hide-details
        label="Notifikasi Job"
        class="my-5"
        @change="onChange(profileData.notify_job_assignment, 'notify_job_assignment')"
      />
      <v-switch
        v-model="profileData.notify_prospect_assignment"
        inset
        flat
        :disabled="!profileData.notify"
        hide-details
        label="Notifikasi Prospek"
        class="my-5"
        @change="onChange(profileData.notify_prospect_assignment, 'notify_prospect_assignment')"
      />
    </div>
  </v-card-text>
</template>

<script>
import Vue from 'vue'
import { onMounted, ref } from '@vue/composition-api'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import { updateUserSetting } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
// import { useStorage } from '@vueuse/core'
// import { getVuetify } from '@/@core/utils'
// import { updateUserSetting } from '@/graphql/mutations'
// import errorHandling from '@/utils/errorHandling'

export default {
  setup() {
    // const notification = ref()
    const profileData = ref({
      notify: false,
      notify_task_assignment: false,
      notify_comment: false,
      notify_job_assignment: false,
      notify_prospect_assignment: false,
    })

    const onChange = (value, type) => {
      apolloClient.mutate({
        mutation: updateUserSetting,
        variables: {
          [type]: value,
        },
        fetchPolicy: 'no-cache',
      }).then(() => {
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah pengaturan!',
        })
        store.state.auth.userData[type] = value
      }).catch(err => {
        errorHandling(err)
      })
    }

    const fetchProfileData = () => {
      const {
        notify,
        /* eslint-disable camelcase */
        notify_task_assignment,
        notify_comment,
        notify_job_assignment,
        notify_prospect_assignment,
      } = store.getters.getUserData

      profileData.value = {
        notify,
        notify_task_assignment,
        notify_comment,
        notify_job_assignment,
        notify_prospect_assignment,
      }
    }

    onMounted(() => {
      fetchProfileData()
    })

    return {
      profileData,
      onChange,
    }
  },
}
</script>
