import gql from 'graphql-tag'

const restoreProspect = gql`
mutation restoreProspect (
  $id: Float!
  $workspace_id: Float!
){
  restoreProspect(
    id: $id
    workspace_id: $workspace_id
  ){
    status
    msg
    data
  }
}

`
export default restoreProspect
