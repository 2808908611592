import gql from 'graphql-tag'

const customAttributes = gql`
  query customAttributes(
    $workspace_id: Float!
    $object_type_id: Float!
    $object_id: Float
    $job_type_id: Float
  ) {
    customAttributes(
      workspace_id: $workspace_id
      object_type_id: $object_type_id
      object_id: $object_id
      job_type_id: $job_type_id
    ) {
      custom_attribute {
        id
        name
        options {
          id
          name
        }
        data_type {
          id
          operator {
            name
            value
          }
        }
      }
      value
    }
  }
`

export default customAttributes
