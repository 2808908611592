<template>
  <v-dialog
    v-model="isOpen"
    max-width="800"
    persistent
  >
    <v-form
      ref="editFolderForm"
      @submit.prevent="onSubmit"
    >
      <v-card>
        <div class="d-flex justify-space-between pa-4">
          <span class="text-h6 font-medium primary--text">Ubah Atribut Folder</span>
          <v-icon
            @click="close()"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>
        <v-card-text class="pt-2">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.name"
                :rules="[required]"
                label="Nama folder"
                placeholder="Masukkan nama folder"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <color-picker
                v-model="formData.color"
                label="Warna"
                class="mb-2"
                outlined
                :rules="[required]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div
            class="mt-4 ml-md-auto"
          >
            <v-btn
              color="primary"
              :loading="loadingEditFolder"
              @click="submitForm"
            >
              Simpan
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiAccountGroup, mdiShieldLock } from '@mdi/js'
import { required } from '@core/utils/validation'
import ColorPicker from '@/components/inputs/ColorPicker.vue'
import useFolder from '@/composables/useFolder'

export default {
  components: {
    ColorPicker,
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const formData = ref({})
    const editFolderForm = ref(null)
    const { updateFolderAttribute, loadingEditFolder } = useFolder()

    const show = data => {
      isOpen.value = true
      formData.value = {
        id: data.id,
        name: data.name,
        color: data.color,
      }
    }

    const close = () => {
      isOpen.value = false
    }

    const submitForm = () => {
      if (editFolderForm.value.validate()) {
        updateFolderAttribute(formData.value).then(() => {
          emit('onChangeFolder')
          close()
        })
      }
    }

    return {
      isOpen,
      show,
      required,
      formData,
      icons: {
        mdiClose,
        mdiAccountGroup,
        mdiShieldLock,
      },
      close,

      submitForm,

      loadingEditFolder,
      editFolderForm,
    }
  },
}
</script>
