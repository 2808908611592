import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    loadingAuthorize: false,
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {
  },
}
