import gql from 'graphql-tag'

const workspaceDetail = gql`
  query workspaceDetail($workspace_id: Float!) {
    workspaceDetail(workspace_id: $workspace_id) {
      id
      name
      identifier_id
      invite_code
      open_invite
      photo
      members {
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
        role {
          id
          name
        }
      }
      token {
        id
        access_token
        refresh_token
        identifier_accurate
        shop_id
        platform {
          id
          name
        }
      }
      working_hours
      timezone
      out_of_working_hours_autoreply
      job_reset_count
      job_digit_counter
      owner {
        id
        name
      }
    }
  }
`

export default workspaceDetail
