import gql from 'graphql-tag'

const resetPassword = gql`
  mutation resetPassword(
    $forgot_password_code: String!,
    $new_password: String!,
    $confirm_password: String!
  ) {
    resetPassword(
      forgot_password_code: $forgot_password_code,
      new_password: $new_password,
      confirm_password: $confirm_password
    ) {
      msg
      data
      status
    }
  }
`

export default resetPassword
