import gql from 'graphql-tag'

const getUserByVerificationCode = gql`
  query getUserByVerificationCode($verification_code: String!) {
    getUserByVerificationCode(verification_code: $verification_code) {
      id
      phone
      email
      first_name
      last_name
      photo
    }
  }
`

export default getUserByVerificationCode
