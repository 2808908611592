import gql from 'graphql-tag'

const uploadPostPhoto = gql`
  mutation uploadPostPhoto(
    $post_id: Float!, 
    $photo: Upload!
  ) {
    uploadPostPhoto(
      post_id: $post_id, 
      photo: $photo
    ) {
      status
      msg
      data
    }
  }
`

export default uploadPostPhoto
