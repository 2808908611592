import Vue from 'vue'
import { createHelpers } from 'vuex-map-fields'
import gql from 'graphql-tag'
import jwtDecode from 'jwt-decode'
import { apolloClient } from '@/vue-apollo'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { login } from '@/graphql/mutations'
import {
  getPermission, myRole, permissionsList, profileInfo, workspaceList,
} from '@/graphql/queries'
import ability from '@/plugins/acl/ability'
import { acStoreParser } from '@/lib/accesscontrol/acStoreParser'
import { initialAbility } from '@/plugins/acl/config'
import errorHandling from '@/utils/errorHandling'

const { getAuthField, updateAuthField } = createHelpers({
  getterType: 'getAuthField',
  mutationType: 'updateAuthField',
})

export default {
  /**
   * @type {import('./definition/auth').StateClass}
   */
  state: {
    userData: null,
    loadingLogin: false,
    globalLoading: false,
    isAuthenticated: false,
    permissionList: [],
    ability: [],
    role: {},
  },
  getters: {
    getAuthField,

    /**
     * @method
     * @param {import('./definition/auth').StateClass} state
     * @returns
     */
    getUserData: state => state.userData,

    /**
     * @method
     * @param {import('./definition/auth').StateClass} state
     * @returns
     */
    getUserAbility: state => state.ability,
    getUserRole: state => state.role,
  },
  mutations: {
    updateAuthField,

    /**
     * @method
     * @param {import('./definition/auth').StateClass} state
     * @returns {void}
     */
    setUserData(state, data) {
      state.userData = data
    },
  },
  actions: {
    /**
     * @method
     * @param {import('./definition/auth').IState} state
     * @param {any} payload
     * @returns {Promise<any>}
     */
    login(state, payload) {
      return new Promise((resolve, reject) => {
        state.commit('updateAuthField', { path: 'loadingLogin', value: true })
        apolloClient
          .mutate({
            mutation: login,
            variables: payload,
          })
          .then(async result => {
            state.commit('updateAuthField', { path: 'isAuthenticated', value: true })
            localStorage.setItem('token', result.data.login.token)
            localStorage.setItem('refreshToken', result.data.login.refreshToken)
            await state.dispatch('getProfileInfo')
            state.commit('updateAuthField', { path: 'loadingLogin', value: false })
            resolve(result)
          })
          .catch(err => {
            state.commit('updateAuthField', { path: 'loadingLogin', value: false })
            errorHandling(err)
            reject(err)
          })
      })
    },

    /**
     * @method
     * @param {import('./definition/auth').IState} state
     * @param {any} payload
     * @returns {Promise<any>}
     */
    permissionsParser(state) {
      return new Promise((resolve, reject) => {
        state.commit('updateAuthField', { path: 'loadingProfile', value: true })
        apolloClient
          .query({
            query: getPermission,
            fetchPolicy: 'no-cache',
            variables: {
              workspace_id: state.rootGetters.getCurrentWorkspaceId,
            },
          }).then(value => {
            apolloClient.query({
              query: myRole,
              variables: {
                workspace_id: state.rootGetters.getCurrentWorkspaceId,
              },
            }).then(({ data }) => {
              const parsedData = acStoreParser(state.state.permissionList, value.data.getPermissions)
              parsedData.push({ action: 'read', subject: 'Navbar' })
              parsedData.push({ action: 'read', subject: 'All' })
              // console.log(value.data.getPermissions)
              ability.update(parsedData)
              state.commit('updateAuthField', { path: 'ability', value: parsedData })
              state.commit('updateAuthField', { path: 'role', value: data.myRole })
              state.commit('updateAuthField', { path: 'loadingProfile', value: false })
              resolve(parsedData)
            })
          }).catch(err => {
            errorHandling(err)
            reject(err)
          })
      })
    },

    /**
     * @method
     * @param {import('./definition/auth').IState} state
     * @param {any} payload
     * @returns {Promise<any>}
     */
    getProfileInfo(state) {
      return new Promise((resolve, reject) => {
        state.commit('updateAuthField', { path: 'globalLoading', value: true })
        apolloClient
          .query({
            query: permissionsList,
            fetchPolicy: 'no-cache',
          }).then(value => {
            state.commit('updateAuthField', { path: 'permissionList', value: value.data.permissionsList })
          }).catch(err => {
            reject(err)
          })
        apolloClient
          .query({
            query: profileInfo,
            fetchPolicy: 'no-cache',
          })
          .then(result => {
            state.commit('updateAuthField', { path: 'globalLoading', value: false })
            state.commit('updateAuthField', {
              path: 'userData',
              value: {
                ...result.data.profileInfo,
                subscription_ends_at: result.data.profileInfo.subscription_ends_at ? +result.data.profileInfo.subscription_ends_at : null,
              },
            })
            state.commit('updateAuthField', { path: 'isAuthenticated', value: true })

            apolloClient
              .query({
                query: workspaceList,
                fetchPolicy: 'no-cache',
              }).then(resultWorkspace => {
                const workspaces = resultWorkspace.data.workspaceList
                if (workspaces && workspaces.length) {
                  state.dispatch('updateWorkspaceData', workspaces.filter(el => el.workspace.identifier_id !== ''))
                }
                resolve(result)
              })
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    /**
       * @method
       * @param {import('./definition/auth').IState} state
       * @returns {void}
     */
    checkExpiredToken(state) {
      const token = jwtDecode(localStorage.getItem('token'))
      const now = new Date()
      const exp = new Date(token.exp * 1000 - 1000000)

      if (now > exp) {
        state.dispatch('refreshToken')
      }
    },
    refreshToken(state) {
      apolloClient
        .mutate({
          mutation: gql`
            mutation ($token: String!) {
              refreshToken(token: $token) {
                status
                token
                refreshToken
              }
            }
          `,
          variables: {
            token: localStorage.getItem('refreshToken'),
          },
        })
        .then(result => {
          localStorage.setItem('token', result.data.refreshToken.token)
          localStorage.setItem('refreshToken', result.data.refreshToken.refreshToken)
          router.go()
        })
        .catch(() => {
          state.commit('updateAuthField', { path: 'globalLoading', value: false })
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('userAbility')
          state.commit('updateAuthField', { path: 'userData', value: null })
          state.commit('updateAuthField', { path: 'isAuthenticated', value: false })

          // Reset ability
          ability.update(initialAbility)
          router.replace('/login')
        })
    },

    /**
     * @method
     * @param {import('./definition/auth').IState} state
     * @returns {void}
     */
    logout(state) {
      Vue.$dialog({
        title: 'Ingin keluar?',
        body: 'Yakin ingin keluar OriensCRM?',
      }).then(result => {
        if (result) {
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('userAbility')
          state.commit('updateAuthField', { path: 'userData', value: null })
          state.commit('updateAuthField', { path: 'isAuthenticated', value: false })

          // Reset ability
          ability.update(initialAbility)

          router.push('/login')
          Vue.notify({
            title: 'Berhasil logout!',
            text: 'Berhasil keluar dari OriensCRM.',
          })
          setTimeout(() => {
            window.location.reload()
          }, 300)
        }
      })
    },
  },
}
