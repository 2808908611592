import gql from 'graphql-tag'

const roleList = gql`
  query roleList($workspace_id: Float!) {
    roleList(workspace_id:$workspace_id) {
      id
      name
    }
  }
`

export default roleList
