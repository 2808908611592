import gql from 'graphql-tag'

const users = gql`
  query($workspace_id: Float!) {
    users(workspace_id: $workspace_id) {
      id
      phone
      email
      first_name
      last_name
      photo
      user_status {
        id
        name
      }
      notify_task_assignment
      notify_comment
      notify_job_assignment
      availability
      last_seen_at
      created_at
      updated_at
    }
  }
`

export default users
