import gql from 'graphql-tag'

const archiveProspect = gql`
mutation archiveProspect(
  $is_archive: Boolean!
  $id: Float!
  $workspace_id: Float!
){
  archiveProspect(
    is_archive: $is_archive
    id: $id
    workspace_id: $workspace_id
  ){
    status 
    msg
    data
  }
}
`
export default archiveProspect
