<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <v-btn
      icon
      small
      class="app-bar-search-toggler"
      @click="shallShowFullSearchLocal = true"
    >
      <v-icon>
        {{ icons.mdiMagnify }}
      </v-icon>
    </v-btn>

    <!-- This is clever hack to hide scrolling 😉 -->
    <v-dialog
      v-model="shallShowFullSearchLocal"
      hide-overlay
      transition="dialog-top-transition"
      max-width="1200px"
      :content-class="'global-search-dialog'"
    >
      <v-card
        rounded
        class="global-search-card"
      >
        <v-navigation-drawer
          v-model="isRightSidebarOpen"
          width="300px"
          touchless
          mobile-breakpoint="sm"
          :temporary="$vuetify.breakpoint.xsOnly"
          absolute
          class="left-sidebar rounded"
          right
        >
          <div class="my-3 mx-3 d-flex align-center justify-space-between">
            <v-icon
              v-show="isRightSidebarOpen"
              size="28"
              @click="isRightSidebarOpen = false"
            >
              {{ icons.mdiChevronDoubleRight }}
            </v-icon>
            <h3 class="pe-5">
              Filter
            </h3>
            <div />
          </div>
          <v-divider />
          <div class="mx-3 py-3">
            <v-menu
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  block
                  v-bind="attrs"
                  v-on="on"
                >
                  Location Filter
                </v-btn>
              </template>
              <v-card
                class="px-4 pb-4"
                flat
                outlined
              >
                <v-checkbox
                  v-for="workspace in workspaceList"
                  :key="workspace.id"
                  v-model="state.selectedWorkspaces.value"
                  :label="workspace.name"
                  :value="workspace.id"
                  :disabled="currentWorkspace === workspace.id"
                  hide-details
                  @click="search()"
                />
              </v-card>
            </v-menu>
          </div>
          <div class="mx-3 py-3">
            <span class="mb-2 text-caption d-block">Quick Filters</span>
            <div class="d-flex flex-column flex-wrap">
              <v-chip
                v-for="status in quickFilterOptions"
                :key="status.value"
                :value="status"
                label
                large
                class="mb-2"
                :class="state.filterStatus.value.includes(status) ? 'primary--text v-chip--active' : null"
                @click="changeFilterStatus(status)"
              >
                {{ status.text }}
              </v-chip>
            </div>
          </div>
          <div class="mx-3 py-3">
            <span class="mb-2 text-caption d-block">Filter Status</span>
            <div class="d-flex flex-column flex-wrap">
              <v-chip
                v-for="status in statusOptions"
                :key="status.value"
                :value="status"
                label
                large
                class="mb-2"
                :class="state.filterStatus.value.includes(status) ? 'primary--text v-chip--active' : null"
                @click="changeFilterStatus(status)"
              >
                {{ status.text }}
              </v-chip>
            </div>
          </div>
        </v-navigation-drawer>
        <div
          class="app-config pe-1 pb-1"
        >
          <div
            class="d-flex align-center"
            :style="{ 'margin-right': isRightSidebarOpen && $vuetify.breakpoint.mdAndUp ? '295px' : '0px' }"
          >
            <v-text-field
              v-model="querySearch"
              class="global-search-text-field"
              hide-details="auto"
              placeholder="Cari sesuatu..."
              @input="search()"
            >
              <template v-slot:prepend-inner>
                <v-icon
                  class="me-2"
                  size="26"
                >
                  {{ icons.mdiMagnify }}
                </v-icon>
              </template>
            </v-text-field>
            <v-divider vertical />
            <v-icon
              v-show="!isRightSidebarOpen"
              size="30"
              class="me-2 ms-3"
              @click="isRightSidebarOpen = true"
            >
              {{ icons.mdiFilterOutline }}
            </v-icon>
          </div>

          <v-divider />

          <v-progress-linear
            :indeterminate="loadingGlobalSearch"
            :active="loadingGlobalSearch"
            height="3"
            absolute
            bottom
          />
          <v-chip-group
            v-model="state.selectedObject.value"
            :style="{ 'margin-right': isRightSidebarOpen && $vuetify.breakpoint.mdAndUp ? '295px' : '0px' }"
            active-class="primary--text"
            mandatory
            mobile-breakpoint="md"
            column
          >
            <v-chip
              v-for="item in state.objects.value"
              :key="item.name"
              class="ma-1"
              small
              @click="search()"
            >
              {{ item.name }}
            </v-chip>
          </v-chip-group>
          <v-divider />
          <div
            class="d-flex justify-space-between align-center mb-1"
            :style="{ 'margin-right': isRightSidebarOpen && $vuetify.breakpoint.mdAndUp ? '295px' : '0px' }"
          >
            <div class="mx-2">
              <p class="text-lg font-weight-bold my-0">
                Result
              </p>
            </div>
            <div
              :style="{
                'width': '100px',
              }"
            >
              <v-select
                v-model="state.selectedSort.value"
                :items="state.sortOptions.value"
                item-text="label"
                class="my-1 me-1"
                height="25"
                hide-details
                dense
                append-icon=""
                return-object
                @change="search()"
              >
                <template v-slot:prepend-inner>
                  <v-icon
                    class="mt-1"
                    size="18"
                  >
                    {{ icons.mdiUnfoldMoreHorizontal }}
                  </v-icon>
                </template>
              </v-select>
            </div>
          </div>
          <v-divider />
          <div
            class="result-list"
            :style="{
              'margin-right': isRightSidebarOpen && $vuetify.breakpoint.mdAndUp ? '300px' : '0px',
              'height': '700px',
              'overflow-y': 'scroll'
            }"
          >
            <v-card
              v-for="object in objectList"
              :key="object.id"
              flat
              outlined
              class="mt-2 mb-1 me-1 ms-1"
            >
              <div
                class="d-flex align-center justify-content-left cursor-pointer"
                @click="goToObject(object)"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="my-4 ms-4"
                      size="46"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ resolveObjectIcon(object.type) }}
                    </v-icon>
                  </template>
                  <span class="text-md-body-1">{{ resolveObjectName(object.type) }}</span>
                </v-tooltip>
                <div>
                  <v-card-title v-html="resolveObjectTitle(object)" />
                  <v-card-subtitle>{{ object.description }}</v-card-subtitle>
                </div>
              </div>
            </v-card>
            <div
              v-intersect="onIntersect()"
              class="mt-2 mb-1 me-1 ms-1"
            >
              Loading more items ...
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiMagnify, mdiClose, mdiUnfoldMoreHorizontal,
  mdiSortAscending, mdiSortDescending, mdiCalendarText,
  mdiTicketAccount, mdiTagHeart, mdiHeart, mdiPackage,
  mdiCommentTextMultiple, mdiTextBoxMultiple, mdiChevronDoubleRight,
  mdiFilterOutline,
} from '@mdi/js'
import {
  useVModel, useMagicKeys, whenever, useDebounceFn,
} from '@vueuse/core'
import {
  computed,
  onMounted,
  ref, watch, watchEffect,
} from '@vue/composition-api'
import { getVuetify, useRouter } from '@core/utils'
import store from '@/store'
import { createFieldMapper } from 'vuex-use-fields'
import useGlobalSearch from '@/composables/useGlobalSearch'

const useFieldGlobalSearch = createFieldMapper({ getter: 'globalSearch/getField', setter: 'globalSearch/setField' })

export default {
  props: {
    shallShowFullSearch: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
    },
    filter: {
      type: Function,
      default: null,
    },
    searchQuery: {
      type: [String, null],
      default: '',
    },
  },
  setup(props, { emit }) {
    const shallShowFullSearchLocal = useVModel(props, 'shallShowFullSearch', emit)
    const searchQueryLocal = useVModel(props, 'searchQuery', emit)
    const $vuetify = getVuetify()
    const objectList = ref([])
    const isRightSidebarOpen = ref(true)
    const workspaceList = computed(() => store.state.workspace.workspaceList.map(el => el.workspace))
    const querySearch = ref('')
    const currentWorkspace = store.getters.getCurrentWorkspaceId
    const state = {
      ...useFieldGlobalSearch(['selectedSort', 'filterStatus', 'selectedObject', 'selectedWorkspaces', 'objects', 'sortOptions', 'pagination']),
    }
    const closeSearch = () => {
      shallShowFullSearchLocal.value = false
    }
    const resolveObjectIcon = type => {
      if (type === 'task') return mdiCalendarText
      if (type === 'job') return mdiTicketAccount
      if (type === 'customer') return mdiHeart
      if (type === 'product') return mdiPackage
      if (type === 'comment') return mdiCommentTextMultiple
      if (type === 'comment_file') return mdiTextBoxMultiple

      return null
    }

    const { router } = useRouter()

    const resolveObjectTitle = obj => {
      if (obj.type === 'comment') {
        const parsed = JSON.parse(obj.name)

        const renderToHTML = parsed.map(el => {
          if (typeof el === 'object') {
            let route = { name: 'user-detail', params: { id: el.id } }

            if (el.type === 'url') {
              return `<a href=${el.url} target="_blank"><div class="mention">${el.label}</div></a>`
            }

            if (el.type === 'email') {
              return `<a href='mailto:${el.label}' target="_blank"><div class="mention">${el.label}</div></a>`
            }

            if (el.type === 'phone') {
              return `<a href='tel:${el.phone}' target="_blank"><div class="mention">${el.label}</div></a>`
            }

            if (el.type === 'team') {
              route = { name: 'teams' }
            }

            if (el.type === 'job') {
              route = { name: 'job-detail', params: { id: el.id } }
            }

            const hrefURL = router.resolve(route).href

            return `<a href=${hrefURL} target="_blank"><div class="mention ${el.type}">@${el.label}</div></a>`
          }

          return el
        })

        return renderToHTML.join('')
      }

      return obj.name
    }

    const resolveObjectName = type => {
      if (type === 'task') return 'Task'
      if (type === 'job') return 'Job'
      if (type === 'customer') return 'Pelanggan'
      if (type === 'product') return 'Produk'
      if (type === 'comment') return 'Komentar'
      if (type === 'comment_file') return 'File Komentar'

      return null
    }

    const { fetchGlobalSearch, loadingGlobalSearch } = useGlobalSearch()

    watchEffect(() => {
      if ($vuetify.breakpoint.mdAndUp) isRightSidebarOpen.value = true
      else isRightSidebarOpen.value = false
    })

    const valueSelected = value => {
      if (value.to) {
        router.push(value.to).catch(() => {})
      }
      shallShowFullSearchLocal.value = false
      searchQueryLocal.value = ''
    }

    // Hotkey
    // eslint-disable-next-line camelcase
    const { ctrl_shift_slash } = useMagicKeys()
    whenever(ctrl_shift_slash, () => {
      shallShowFullSearchLocal.value = true
    })

    // Result type
    const getSearchResultType = item => {
      if (item.to !== undefined) return 'pages'
      if (item.size !== undefined) return 'files'
      if (item.email !== undefined) return 'contacts'

      return null
    }

    const search = useDebounceFn((fetchMore = false) => {
      const param = {
        search: querySearch.value,
        object: state.objects.value[state.selectedObject.value].name,
        workspace_ids: state.selectedWorkspaces.value,
        sort: state.selectedSort.value.field,
        sort_type: state.selectedSort.value.type,
        archive_status: state.filterStatus.value.map(el => el.value),
        created_by: store.getters.getUserData.id,
        assigned_to: store.getters.getUserData.id,
      }
      state.pagination.value.offset = fetchMore ? state.pagination.value.offset += state.pagination.value.limit : state.pagination.value.offset = 0
      const pagination = state.pagination.value
      fetchGlobalSearch(param, pagination).then(value => {
        console.log('fetchMore: ', fetchMore)
        if (fetchMore) {
          console.log('fetch more')
          objectList.value = [...objectList.value, ...value]
        } else {
          objectList.value = value
        }
      })
    }, 1000)

    const onIntersect = useDebounceFn(() => {
      console.log(state.pagination.value.limit)
      state.pagination.value.limit += 10
      search()
    }, 1000)

    watch(shallShowFullSearchLocal, value => {
      if (!value) searchQueryLocal.value = ''
      store.commit('app/TOGGLE_CONTENT_OVERLAY', value)
      if (value) {
        search()
      }
    })

    onMounted(async () => {
      state.selectedWorkspaces.value = await [...state.selectedWorkspaces.value, currentWorkspace]
      console.log('Currenct Workspaces')
      console.log(state.selectedWorkspaces.value)
    })

    const changeFilterStatus = status => {
      if (state.filterStatus.value.includes(status)) state.filterStatus.value = state.filterStatus.value.filter(el => el.id !== status.id)
      else state.filterStatus.value.push(status)
      search()
    }

    const resolveRouteName = type => {
      if (type === 'task') return 'activity-detail'
      if (type === 'job') return 'job-detail'
      if (type === 'product') return 'product-detail'
      if (type === 'customer') return 'customer-detail'
      // if (type === 'comment') return 'comment-detail'
      // if (type === 'comment_file') return 'comment-detail'

      return null
    }

    const goToObject = obj => {
      const routeName = resolveRouteName(obj.type)
      console.log(obj.workspace)
      store.dispatch('setSelectedWorkspace', obj.workspace)
      router.replace({ name: routeName, params: { id: obj.id, workspace: obj.workspace.identifier_id } }).catch(() => {})
      closeSearch()
    }

    const statusOptions = [
      {
        id: 0,
        text: 'Active',
        field: 'is_archive',
        value: 0,
      },
      {
        id: 1,
        text: 'Archived',
        field: 'is_archive',
        value: 1,
      },
    ]

    const quickFilterOptions = [
      {
        id: 0,
        text: 'Assigned By Me',
        field: 'is_archive',
        value: 0,
      },
      {
        id: 1,
        text: 'Created By Me',
        field: 'createdById',
        value: 1,
      },
    ]

    return {
      searchQueryLocal,
      shallShowFullSearchLocal,
      valueSelected,
      getSearchResultType,
      isRightSidebarOpen,

      icons: {
        mdiMagnify,
        mdiClose,
        mdiUnfoldMoreHorizontal,
        mdiSortAscending,
        mdiSortDescending,
        mdiCalendarText,
        mdiTicketAccount,
        mdiTagHeart,
        mdiHeart,
        mdiPackage,
        mdiCommentTextMultiple,
        mdiTextBoxMultiple,
        mdiChevronDoubleRight,
        mdiFilterOutline,
      },
      state,
      search,
      querySearch,
      objectList,
      resolveObjectIcon,
      loadingGlobalSearch,
      workspaceList,
      resolveObjectName,
      currentWorkspace,
      quickFilterOptions,
      statusOptions,
      changeFilterStatus,
      closeSearch,
      goToObject,
      onIntersect,
      resolveObjectTitle,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
.global-search-dialog {
  overflow: hidden
}

.result-list {
  @include style-scroll-bar();
}

@include theme(app-bar-autocomplete-box) using ($material) {
  div[role='combobox'] {
    background-color: map-deep-get($material, 'cards');
  }
}
.global-search-text-field >>> .v-input__slot::before {
  border-style: none !important;
}

.global-search-text-field {
  margin: 0 0px !important;
  padding: 10px 10px !important;
  font-size: medium;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}

.v-input__control::before {
  border-style: none !important;
}

// ————————————————————————————————————
//* ——— Horizontal Nav
// ————————————————————————————————————

.content-layout.horizontal-nav {
  .app-system-bar {
    // Assigning 7 z-index so that search result can be seen on top of navigation menu
    z-index: 7;

    .v-text-field {
      margin-top: 0;
      padding-top: 0;
    }

    // ? In Full content contet have padding of 1.5rem
    &:not(.app-system-bar-boxed) {
      .app-bar-autocomplete-box {
        max-width: calc(100% - 1.5rem * 2);
        @include ltr() {
          margin-left: 1.5rem;
        }
        @include rtl() {
          margin-right: 1.5rem;
        }
      }
    }
  }
}
</style>
