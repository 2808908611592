import gql from 'graphql-tag'

const getWorkflowDetailAdmin = gql`
  query getWorkflowDetailAdmin(
    $workflow_id: Float!
  ) {
    workflowDetailAdmin(
      workflow_id: $workflow_id
    ) {
      trello_webhook {
        id
        board_id
      }
      drive_webhook {
        id
        file_id
        parent_id
      }
      calendar_webhook {
        id
        calendar_id
      }
      gmail_webhook {
        id
        email
      }
      created_by {
        id
        name
      }
      created_at
      id
      name
      action {
        id
        name
        name_view
        object {
          id
          name
          platform {
            id
            name
          }
        }
        trigger_category {
          id
        }
        component_name
      }
      token {
        id
        connection_name
        refresh_token
        access_token
      }
      filters {
        id
        value
        trigger_property {
          id
          name
          identifier
          data_type {
            id
            name
            name_view
            category_name
          }
          options {
            id
            name
            value
          }
        }
        operator {
          id
          value
          description
          data_type {
            id
            name
          }
        }
      }
      executors {
        id
        action {
          id
          name
          name_view
          object {
            id
            name
            platform {
              id
              name
            }
          }
          component_name
        }
        property_values {
          id
          parent {
            id
          }
          property {
            id
          }
          value
          sort
          identifier
        }
        token {
          id
        }
      }
    }
  }
`

export default getWorkflowDetailAdmin
