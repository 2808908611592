import gql from 'graphql-tag'

const removeUserHierarchy = gql`
mutation removeUserHierarchy(
  $workspace_id: Float!
  $hierarchy_id: Float!
  $user_id: [Int!]!
){
  removeUserHierarchy(
    workspace_id: $workspace_id
    hierarchy_id: $hierarchy_id
    user_id: $user_id
  ){
    msg
    data
    status
  }
}

`
export default removeUserHierarchy
