import gql from 'graphql-tag'

const createTeam = gql`
  mutation (
    $name: String!
    $description: String!
    $is_public: Boolean!
    $channel_permission: Boolean!
    $user_id: [Int!]!
    $workspace_id: Float!
  ) {
    createTeam(
      name: $name
      description: $description
      is_public: $is_public
      channel_permission: $channel_permission
      user_id: $user_id
      workspace_id: $workspace_id
    ) {
      data
      msg
      status
    }
  }
`

export default createTeam
