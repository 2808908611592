import gql from 'graphql-tag'

const getUserWorkspaceList = gql`
  query getUserWorkspaceList(
    $user_id: Float!
  ) {
    getUserWorkspaceList(
      user_id: $user_id
    ) {
      count
      workspaces {
        id
        name
        photo
        created_at
        member_count
      }
    }
  }
`

export default getUserWorkspaceList
