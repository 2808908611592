import gql from 'graphql-tag'

const addJobPriority = gql`
  mutation(
    $workspace_id: Float!
    $job_type: Float!
    $job_priority: [paramAddJobPriority!]!
  ) {
    addJobPriority(
      workspace_id: $workspace_id
      job_type: $job_type
      job_priority: $job_priority
    ) {
      status
      msg
      data
    }
  }
`

export default addJobPriority
