import gql from 'graphql-tag'

const getUserDetail = gql`
  query getUserDetail($user_id: Float!) {
    getUserDetail(user_id: $user_id) {
      id
      phone
      email
      name
      first_name
      last_name
      photo
      user_status {
        id
        name
      }
      kuota_automasi
      subscription_ends_at
      is_auto_subscribe
      renew_subscription_type
      subscription {
        id
        name
      }
      created_at
      workspaces {
        id
        name
        identifier_id
        photo
      }
      triggers {
        id
        name
        is_disabled
        created_at
      }
    }
  }
`

export default getUserDetail
