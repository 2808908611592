import gql from 'graphql-tag'

const archiveChannel = gql`
  mutation (
    $channel_id: Float!
  ) {
    archiveChannel (
      channel_id: $channel_id
    ) {
      status
      msg
      data
    }
  }
`

export default archiveChannel
