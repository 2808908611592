<template>
  <date-range-picker
    ref="picker"
    :key="keyInput"
    v-model="datedata"
    :class="`w-100 ${$vuetify.theme.dark && 'dark-layout'} ${wrap && 'wrap-calendar'}`"
    :locale-data="locale"
    :ranges="customRange ? customRange : range"
    v-bind="$attrs"
    placeholder="Tanggal"
    :opens="opens"
    :min-date="minDate"
    :max-date="maxDate"
    :time-picker="timePicker"
    append-to-body
    :rules="rules"
    @startSelection="$emit('startSelection', $event)"
    @finishSelection="$emit('finishSelection', $event)"
    @update="$emit('update', setTime($event))"
    @select="$emit('select', setTime($event))"
  >
    <template
      #input="picker"
      style="min-width: 350px;"
    >
      <span v-if="picker.startDate">
        {{ dateFormat(picker.startDate.toISOString(), timePicker? 3 : 4) }} - {{ dateFormat(picker.endDate.toISOString(), timePicker? 3 : 4) }}
      </span>
    </template>
    <template
      v-if="withTime"
      #footer="data"
    >
      <div
        class="footer-slot"
      >
        <v-col
          cols="2"
        >
          <v-checkbox
            v-model="timePicker"
            small
            color="primary"
            hide-details
            label="Waktu"
            dense
            @click="reset()"
          />
        </v-col>
        <v-col
          cols="10"
          class="footer-group"
        >
          <span>
            <small>{{ data.rangeText }}</small>
          </span>
          <v-btn
            small
            dark
            style="background-color: #8A8D93; color: white;"
            depressed
            @click="data.clickCancel()"
          >
            Tutup
          </v-btn>
          <v-btn
            small
            dark
            style="background-color: #6285F6; color: white;"
            depressed
            @click="data.clickApply()"
          >
            Simpan
          </v-btn>
        </v-col>
      </div>
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import '@/styles/vue-daterange-picker.scss'
import dateFormat from '@/utils/dateFormat'

export default {
  components: {
    DateRangePicker,
  },
  props: {
    value: {
      type: Object,
      default: () => ({ startDate: null, endDate: null }),
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    opens: {
      type: String,
      default: 'center',
    },
    customRange: {
      type: Object,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    withTime: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      locale: {
        format: 'dd/mm/yyyy',
        applyLabel: 'Simpan',
        cancelLabel: 'Tutup',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      },
      keyInput: '',
      timePicker: this.checkTime(this.value.startDate, this.value.endDate),
    }
  },
  computed: {
    datedata: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        // re render input
        setTimeout(() => {
          if (val > this.value) {
            this.keyInput = Math.random()
          }
        }, 500)
      },
    },
    range() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999)
      const yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0)
      const yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(11, 59, 59, 999)
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
      const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999)

      /* eslint-disable quote-props */
      return {
        'Hari ini': [today, todayEnd],
        'Kemarin': [yesterdayStart, yesterdayEnd],
        'Bulan ini': [thisMonthStart, thisMonthEnd],
        'Tahun ini': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)],
        'Bulan lalu': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)],
      }
    },
  },
  watch: {
    timePicker(val) {
      const start = new Date(this.value.startDate)
      const end = new Date(this.value.endDate)

      if (!val) {
        start.setSeconds(1)
        end.setSeconds(59)
      } else {
        start.setHours(12, 0, 0)
        end.setHours(12, 0, 0)
      }

      this.$emit('update', { startDate: start, endDate: end })
    },
  },
  methods: {
    dateFormat,
    reset() {
      this.datedata = { startDate: null, endDate: null }
    },
    setTime(event) {
      const { startDate, endDate } = event

      if (!this.timePicker) {
        startDate.setHours(0, 0, 1)
        endDate.setHours(23, 59, 59)
      } else {
        startDate.setSeconds(0)
        endDate.setSeconds(0)
      }

      return { startDate, endDate }
    },
    checkTime(startDate, endDate) {
      if (startDate && endDate) {
        return !(new Date(startDate).getSeconds() === 1 && new Date(endDate).getSeconds() === 59)
      }

      return false
    },
  },
}
</script>

<style lang="scss" scoped>
  .wrap-calendar {
    .daterangepicker .calendars-container[data-v-4391f606] {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
    }

    .daterangepicker .drp-selected {
      display: block;
      font-size: 12px;
      padding-right: 0px;
      margin-bottom: 8px;
    }
  }

  .footer-slot {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
</style>
